var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable @typescript-eslint/naming-convention */
import { layer } from "azure-maps-control";
import { SizeConstants, textOptions, zoomThreshold } from "./SpacePinsLayer.Constants";
import { removeAllIcons, removeAllLabels, restoreAllIcons, restoreAllLabels } from "../../MapUtilities/AzureMapUtilities";
import { findSpaceLocation } from "./SpacePinsLayer.Helper";
var SpacePinsLayer = /** @class */ (function () {
    function SpacePinsLayer(map, datasource) {
        var _this = this;
        this.map = map;
        this.datasource = datasource;
        //properties
        this.clickShouldHighlightPin = true;
        //state
        this.isVisible = true;
        this._spaceIdsDisplayed = [];
        this._floorId = "";
        this._selectedSpaceId = null;
        this.mouseoverCursor = function () {
            _this.map.getCanvasContainer().style.cursor = "pointer";
        };
        this.mouseoutCursor = function () {
            _this.map.getCanvasContainer().style.cursor = "grab";
        };
        this.onPinClick = function (event) {
            var _a, _b, _c;
            if (_this.clickShouldHighlightPin) {
                var shapes = event.shapes;
                if (shapes.length) {
                    var shape = shapes[0];
                    var originalId = shape.getProperties().id;
                    if (_this.selectedSpaceId !== originalId) {
                        _this.selectedSpaceId = originalId;
                        _this.panMapCameraToSpace(originalId);
                        (_a = _this.selectPinCallback) === null || _a === void 0 ? void 0 : _a.call(_this, originalId);
                    }
                    else {
                        _this.selectedSpaceId = null;
                        (_b = _this.unselectPinCallback) === null || _b === void 0 ? void 0 : _b.call(_this);
                    }
                }
            }
            else {
                _this.renderPins([]);
                (_c = _this.dismissPinCallback) === null || _c === void 0 ? void 0 : _c.call(_this);
            }
        };
        this.pinTopLayer = new layer.SymbolLayer(this.datasource, "pinTopLayer", {
            iconOptions: {
                image: ["concat", "PinTop/", ["get", "category"]],
                allowOverlap: true,
                ignorePlacement: true,
                offset: [0, -14],
                size: 0.5
            },
            minZoom: zoomThreshold.min,
            maxZoom: zoomThreshold.max
        });
        this.pinTailLayer = new layer.SymbolLayer(this.datasource, "pinTailLayer", {
            iconOptions: {
                image: "PinTail",
                allowOverlap: true,
                ignorePlacement: true,
                size: 0.5
            },
            textOptions: textOptions,
            minZoom: zoomThreshold.min,
            maxZoom: zoomThreshold.max
        });
        this.setLayerFilter();
        this.map.events.add("click", this.pinTopLayer, this.onPinClick);
        this.map.events.add("mouseover", this.pinTopLayer, this.mouseoverCursor);
        this.map.events.add("mouseout", this.pinTopLayer, this.mouseoutCursor);
        this.map.layers.add([this.pinTailLayer, this.pinTopLayer]);
    }
    Object.defineProperty(SpacePinsLayer.prototype, "spaceIdsDisplayed", {
        get: function () {
            return this._spaceIdsDisplayed;
        },
        set: function (value) {
            this._spaceIdsDisplayed = value;
            this.selectedSpaceId = null;
            this.setLayerFilter();
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SpacePinsLayer.prototype, "floorId", {
        get: function () {
            return this._floorId;
        },
        set: function (value) {
            this._floorId = value;
            this.setLayerFilter();
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SpacePinsLayer.prototype, "selectedSpaceId", {
        get: function () {
            return this._selectedSpaceId;
        },
        set: function (value) {
            this._selectedSpaceId = value;
            this.setPinImageExpression();
        },
        enumerable: false,
        configurable: true
    });
    /**
     * Action to perform when a pin is highlighted
     * @param callback callback function with a spaceId parameter
     */
    SpacePinsLayer.prototype.setSelectPinCallback = function (callback) {
        this.selectPinCallback = callback;
    };
    /**
     * Action to perform when a pin is unselected and no longer highlighted
     * @param callback callback function
     */
    SpacePinsLayer.prototype.setUnselectPinCallback = function (callback) {
        this.unselectPinCallback = callback;
    };
    /**
     * Action to perform when a pin is dismissed and no longer displayed on the map
     * @param callback callback function
     */
    SpacePinsLayer.prototype.setDismissPinCallback = function (callback) {
        this.dismissPinCallback = callback;
    };
    /**
     * Show one or more pins on the map. Clicking on a pin displayed using this function will highlight the pin.
     * @param spaceIds array of spaceIds
     */
    SpacePinsLayer.prototype.renderPins = function (spaceIds) {
        this.clickShouldHighlightPin = true;
        if (spaceIds.length && this.isVisible) {
            // TODO WONTFIX: remove/restore icons and labels consolidated into 1 function in the new Azure Maps Component
            removeAllIcons(this.map);
            removeAllLabels(this.map);
        }
        else {
            restoreAllIcons(this.map);
            restoreAllLabels(this.map);
        }
        this.spaceIdsDisplayed = spaceIds;
    };
    /**
     * Show a single pin on the map. Clicking on the pin will dismiss the pin.
     * @param spaceId spaceId
     */
    SpacePinsLayer.prototype.renderSinglePin = function (spaceId) {
        this.clickShouldHighlightPin = false;
        this.panMapCameraToSpace(spaceId);
        this.spaceIdsDisplayed = [spaceId];
    };
    /**
     * Sets the floor of the pins to be displayed. This should not be used outside IndoorAzureMap
     * @param floorId levelId of the floor displayed
     */
    SpacePinsLayer.prototype.setFloorId = function (floorId) {
        this.floorId = floorId;
    };
    /**
     * Turns on the visibility of the pins on the map. The current state of the layer will be preserved.
     */
    SpacePinsLayer.prototype.show = function () {
        this.pinTopLayer.setOptions(__assign(__assign({}, this.pinTopLayer.getOptions()), { visible: true }));
        this.pinTailLayer.setOptions(__assign(__assign({}, this.pinTailLayer.getOptions()), { visible: true }));
        this.isVisible = true;
        if (this.spaceIdsDisplayed.length) {
            removeAllIcons(this.map);
            removeAllLabels(this.map);
        }
    };
    /**
     * Turns off the visibility of the pins on the map. Operations on the layer are still valid while pins are hidden.
     */
    SpacePinsLayer.prototype.hide = function () {
        this.pinTopLayer.setOptions(__assign(__assign({}, this.pinTopLayer.getOptions()), { visible: false }));
        this.pinTailLayer.setOptions(__assign(__assign({}, this.pinTailLayer.getOptions()), { visible: false }));
        this.isVisible = false;
        restoreAllIcons(this.map);
        restoreAllLabels(this.map);
    };
    /**
     * Overrides the maxZoom of the pin layers. The default maxZoom is 20.15.
     * @param maxZoom zoom value to set the maxZoom of the pin layers. Must be between 17.75 and 24
     * @default 24
     */
    SpacePinsLayer.prototype.overrideMaxZoom = function (maxZoom) {
        if (maxZoom === void 0) { maxZoom = 24; }
        if (maxZoom <= 24 && maxZoom >= zoomThreshold.min) {
            this.pinTopLayer.setOptions({ maxZoom: maxZoom });
            this.pinTailLayer.setOptions({ maxZoom: maxZoom });
        }
    };
    SpacePinsLayer.prototype.setLayerFilter = function () {
        var filter = [
            "all",
            ["has", "category"],
            ["==", ["get", "levelId"], ["literal", this.floorId]],
            ["in", ["get", "id"], ["literal", this.spaceIdsDisplayed]]
        ];
        this.pinTopLayer.setOptions({
            filter: filter
        });
        this.pinTailLayer.setOptions({
            filter: filter
        });
    };
    SpacePinsLayer.prototype.setPinImageExpression = function () {
        var pinTopIconOptions = this.pinTopLayer.getOptions().iconOptions;
        var pinTailIconOptions = this.pinTailLayer.getOptions().iconOptions;
        var sizeExpression = [
            "case",
            ["==", ["get", "id"], this.selectedSpaceId],
            SizeConstants.SELECTED,
            SizeConstants.DEFAULT
        ];
        this.pinTopLayer.setOptions({ iconOptions: __assign(__assign({}, pinTopIconOptions), { size: sizeExpression }) });
        this.pinTailLayer.setOptions({ iconOptions: __assign(__assign({}, pinTailIconOptions), { size: sizeExpression }) });
    };
    SpacePinsLayer.prototype.panMapCameraToSpace = function (spaceId) {
        var spaceLocation = findSpaceLocation(spaceId, this.datasource);
        if (spaceLocation && this.floorId === spaceLocation.levelId && this.isVisible) {
            this.map.setCamera({
                center: spaceLocation.coordinate,
                duration: 200,
                type: "ease"
            });
        }
    };
    return SpacePinsLayer;
}());
export { SpacePinsLayer };
