import { IAction, IBaseAction } from "./IAction";
import { IDeviceConfigStore } from "../Types";

export enum DeviceConfigRetrieveActions {
    STORE_CONFIG_DATA = "STORE_CONFIG_DATA"
}

export enum DeviceConfigSelectedActions {
    CLEAR_CONFIG_DATA = "CLEAR_CONFIG_DATA",
    LOAD_CONFIG_DATA = " LOAD_CONFIG_DATA"
}

export type ConfigSpaceDataRetrieved = IAction<
    { configData: IDeviceConfigStore },
    DeviceConfigRetrieveActions.STORE_CONFIG_DATA
>;
export type ClearDeviceConfigAction = IBaseAction<DeviceConfigSelectedActions.CLEAR_CONFIG_DATA>;
export type LoadDeviceConfigAction = IBaseAction<DeviceConfigSelectedActions.LOAD_CONFIG_DATA>;

type DeviceConfigViewAction = ConfigSpaceDataRetrieved | ClearDeviceConfigAction | LoadDeviceConfigAction;

export type DeviceConfigAction = DeviceConfigViewAction;
