import { fillStringTemplate, groupBy } from "@smartbuilding/utilities";
var PeopleService = /** @class */ (function () {
    function PeopleService(peopleRepo, localblobservice, options) {
        this.peopleRepo = peopleRepo;
        this.localblobservice = localblobservice;
        this.options = options;
        this.upnStringReplacementInTemplate = "{upn}";
        this.sizeStringReplacement = "{desiredSize}";
    }
    PeopleService.prototype.getAliasForPerson = function (person) {
        return person.userPrincipalName.split("@")[0];
    };
    PeopleService.prototype.getPeopleInBuildingGroupedBySpace = function (buildingId, dipPersonData) {
        return this.peopleRepo.getPeopleInBuilding(buildingId, dipPersonData).then(function (people) {
            if (people) {
                return groupBy(people, "spaceId");
            }
            return null;
        });
    };
    PeopleService.prototype.getPeopleInSpace = function (spaceId, dipSpaceData) {
        return this.peopleRepo.getPeopleInSpace(spaceId, dipSpaceData);
    };
    PeopleService.prototype.getPersonByUpn = function (upn, dipPersonData) {
        return this.peopleRepo.getPersonByUpn(upn, dipPersonData);
    };
    PeopleService.prototype.getPersonPhotoBlobUrl = function (upn, desiredSize) {
        var _this = this;
        var templateReplacementStrings = {};
        templateReplacementStrings[this.sizeStringReplacement] = desiredSize;
        templateReplacementStrings[this.upnStringReplacementInTemplate] = upn;
        var getGraphPhotoRelativeUrl = fillStringTemplate(this.options.graphApiGetPhotoOfSizeRelativeUrl, templateReplacementStrings);
        return this.localblobservice
            .getLocalBlobUrlForImage(this.options.graphApi + getGraphPhotoRelativeUrl)
            .then(function (url) {
            if (!url) {
                getGraphPhotoRelativeUrl = _this.options.graphApiGetPhotoRelativeUrl.replace(_this.upnStringReplacementInTemplate, upn);
                return _this.localblobservice.getLocalBlobUrlForImage(_this.options.graphApi + getGraphPhotoRelativeUrl);
            }
            else {
                return url;
            }
        });
    };
    PeopleService.prototype.releasePersonPhotoBlobUrl = function (url) {
        this.localblobservice.revokeLocalBlobUrl(url);
    };
    return PeopleService;
}());
export { PeopleService };
