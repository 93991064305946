import {
    IReserveRoomResponse,
    ISmartBuildingApiRoomHttp,
    ISpaceCategory
} from "@smartbuilding/smartbuilding-api-service";
import { IInstantBookingConfirmationInfo } from "../DetailsPanelProps";
import { ILogger } from "@smartbuilding/log-provider";
import { Provider } from "../../../serviceContainer/ServiceIdentifiers";
import { RoomSubTypes } from "@smartbuilding/adt-v2-types";

export function combineCategorySpaceId(categoryMap: Record<string, ISpaceCategory>, category: string): Array<string> {
    const categoriesToAggregate: string[] = [category];
    const spaces: string[] = [];
    const processedSpaces = new Set<string>();
    while (categoriesToAggregate.length > 0) {
        const categoryKey = categoriesToAggregate.shift();
        if (!categoryKey) {
            continue;
        }
        const category = categoryMap[categoryKey];
        if (!category) {
            continue;
        }
        categoriesToAggregate.push(...category.childCategories);

        category.spaces.forEach((space) => {
            if (!processedSpaces.has(space)) {
                processedSpaces.add(space);
                spaces.push(space);
            }
        });
    }

    return spaces;
}

export function getAvailableRooms(
    spaceHttpProvider: Provider<ISmartBuildingApiRoomHttp>,
    spaceAliasIdMap: Record<string, string>,
    targetStartTime: Date,
    targetEndTime: Date,
    duration: number,
    handleDispatchInstantBookingListViewCard: (
        duration: number,
        listOfAvailableIds: string[],
        targetStartTime: Date,
        targetEndTime: Date
    ) => void,
    setInstantBookingLoadingMins: React.Dispatch<React.SetStateAction<number | undefined>>,
    logger: ILogger,
    loggerMsgTitle: string
): Promise<string[] | void> {
    return spaceHttpProvider()
        .then(async (spaceHttp) => {
            const request = spaceHttp.getAvailableRooms(
                Object.keys(spaceAliasIdMap),
                targetStartTime.getTime(),
                targetEndTime.getTime()
            );
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const timeout = new Promise((_, reject) => {
                setTimeout(() => {
                    reject(new Error("Request timed out"));
                }, 15000);
            });
            const response = await Promise.race([request, timeout]);
            return response as string[];
        })
        .then((listOfAvailableAlias) => {
            const listOfAvailableIds = listOfAvailableAlias.map((alias: string) => spaceAliasIdMap[alias]);
            handleDispatchInstantBookingListViewCard(duration, listOfAvailableIds, targetStartTime, targetEndTime);
            setInstantBookingLoadingMins(undefined);
            return listOfAvailableIds;
        })
        .catch((error) => {
            setInstantBookingLoadingMins(undefined);
            const errorMsg = `[${loggerMsgTitle}] Having issues while getting available rooms for : ${JSON.stringify(
                spaceAliasIdMap
            )} at the targetStartTimeInMs: ${targetStartTime.getTime()}, targetEndTimeInMs: ${targetEndTime.getTime()}.`;
            if (error instanceof Error) {
                error.message = errorMsg + error.message;
                logger.logError(error);
            } else {
                logger.logError(new Error(errorMsg));
            }
        });
}

export function instantBookRoom(
    spaceHttpProvider: Provider<ISmartBuildingApiRoomHttp>,
    buildingName: string,
    roomId: string,
    spaceName: string,
    spaceType: RoomSubTypes,
    confAlias: string,
    startTime: Date,
    endTime: Date,
    userEmail: string,
    subject: string,
    bodyText: string,
    logger: ILogger,
    loggerMsgTitle: string,
    setInstantBookingConfirmingSpaceId: React.Dispatch<React.SetStateAction<string | undefined>>,
    handleDispatchSetInstantBookingRoom: (
        roomId: string,
        instantBookingConfirmationInfo: IInstantBookingConfirmationInfo
    ) => void
): Promise<IReserveRoomResponse | void> {
    return spaceHttpProvider()
        .then(
            async (spaceHttp) =>
                await spaceHttp.reserveRoom(
                    buildingName,
                    spaceName,
                    confAlias,
                    startTime.getTime(),
                    endTime.getTime(),
                    userEmail.substring(0, userEmail.indexOf("@")),
                    subject,
                    bodyText,
                    []
                )
        )
        .then((success: IReserveRoomResponse) => {
            if (success.isBooked) {
                logger.logEvent(`[${loggerMsgTitle}] Space booked successfully by Smart Building Service.`, {
                    roomAlias: confAlias,
                    isElectron: false,
                    spaceId: roomId,
                    spaceName: spaceName,
                    buildingName: buildingName,
                    spaceType: spaceType
                });
                const instantBookingConfirmationInfo: IInstantBookingConfirmationInfo = {
                    reservationEventId: success.eventId,
                    reservationMsg: `This room has been successfully booked for you from ${getInstantBookingTimeString(
                        startTime
                    )} to ${getInstantBookingTimeString(endTime)}.`
                };
                handleDispatchSetInstantBookingRoom(roomId, instantBookingConfirmationInfo);
            } else {
                throw new Error();
            }
            return success;
        })
        .catch((error) => {
            setInstantBookingConfirmingSpaceId(undefined);
            const errorMsg = `[${loggerMsgTitle}] Having issues while booking the room: ${confAlias}.`;
            if (error instanceof Error) {
                error.message = errorMsg + error.message;
                logger.logError(error);
            } else {
                logger.logError(new Error(errorMsg));
            }
        });
}

export const getInstantBookingTimeString = (date: Date): string => {
    const amPm = date.getHours() < 12 ? "AM" : "PM";
    const hours = date.getHours() % 12 || 12;
    const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : `${date.getMinutes()}`;
    return `${hours}:${minutes} ${amPm}`;
};
