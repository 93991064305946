export const themePrimary = "#0078D4";
export const themeLighterAlt = "#F3F9FD";
export const themeLighter = "#D0E7F8";
export const themeLight = "#A9D3F2";
export const themeTertiary = "#5CA9E5";
export const themeSecondary = "#1A86D9";
export const themeDarkAlt = "#006CBE";
export const themeDark = "#005BA1";
export const themeDarker = "#004377";
export const neutralLighterAlt = "#F0F0F0";
export const neutralLighter = "#EDEDED";
export const neutralLight = "#E8E8E8";
export const neutralQuaternaryAlt = "#E1E1E1";
export const neutralQuaternary = "#D2D2D2";
export const neutralTertiaryAlt = "#C8C8C8";
export const neutralTertiary = "#A1A1A1";
export const neutralSecondary = "#484644";
export const neutralPrimaryAlt = "#8D8B8A";
export const neutralPrimary = "#323130";
export const neutralDark = "#605E5D";
export const black = "#000000";
export const white = "#FFFFFF";

// Warnings and errors
export const errorText = "#990000";
export const errorBackground = "#FDE7E9";
export const blockingBackground = "#FDE7E9";
export const warningBackground = "#FFF4CE";
export const warningHighlight = "#FFB900";
export const successBackground = "#DFF6DD";

// Extended colors

export const severeWarningBackground = "#FEE6DD";
export const severeWarningIcon = "#C53601";
export const dashboardBackdrop = "#DADADA";
export const overlayBackground = "rgba(255,255,255,.4)";
export const nonOverlayBackground = "rgba(255,255,255,.4)";
export const navBackground = "#E9E9E9";

export const criticalStatus = "#610000";
export const highStatus = "#990000";
export const mediumStatus = "#E60000";
export const lowStatus = "#F56A00";
export const readyStatus = "#00AA00";
export const goodStatus = "#107C10";
export const unkownStatus = "#949494";

export const needHelpBackground = "#00838C";
export const needHelpBackgroundPressed = "#006B73";

export const contextualAlertBackground = "#5522E0";

export const whiteTranslucent40 = "rgba(255,255,255,.4)";

//fonts
export const segoeUI = "Segoe-UI";

// spaces colors
export const lightIvoryColor = "rgba(255, 255, 240)";
