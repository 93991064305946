export enum SessionStorageKeys {
    AccessedFrom = "AccessedFrom",
    PPIAuthType = "PPIAuthType",
    DeviceType = "DeviceType",
    Ashwid = "Aswhid",
    AadAppId = "AadAppId",
    Environment = "Environment",
    APPINSIGHTS_INSTRUMENTATIONKEY = "APPINSIGHTS_INSTRUMENTATIONKEY",
    ComponentId = "ComponentId",
    ComponentName = "ComponentName",
    Service = "Service",
    ServiceLine = "ServiceLine",
    ServiceOffering = "ServiceOffering"
}
