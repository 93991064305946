var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/**Known issues and future updates
 * LastUpdated only keeps track of when ANY item in the cache was last updated, not each individual item. Items can persist past expiration threshhold
 * People from graph VS people from management api have different data. They are cached separately now, but could be merged into the same cache
 * ****People from graph have more information, and it doesn't completely match the information from management for the same fields
 */
var PeopleRepo = /** @class */ (function () {
    function PeopleRepo(userRepo, spaceRepo) {
        this.userRepo = userRepo;
        this.spaceRepo = spaceRepo;
        this.peopleCache = {
            // People cache from managemnt API keyed by upn
            upnMap: {},
            // People cache from any API keyed by space ID
            assignedSpaceMap: {},
            lastUpdated: 0,
            // Currently running promises to retrieve data
            retrieving: {
                person: {},
                peopleInSpace: {},
                peopleInBuilding: {}
            }
        };
        this.invalidationPeriod = 7200000;
    }
    /*------------------public methods-----------------*/
    PeopleRepo.prototype.getPersonByUpn = function (upn, dipPersonData, forceRefresh) {
        var _this = this;
        if (forceRefresh === void 0) { forceRefresh = false; }
        var existingUpnCall = this.peopleCache.retrieving.person[upn];
        if (existingUpnCall) {
            return existingUpnCall;
        }
        this.checkIfCacheExpired(forceRefresh);
        if (this.peopleCache.upnMap[upn]) {
            return Promise.resolve(this.peopleCache.upnMap[upn]);
        }
        var retrievingPromise = this.getPerson(dipPersonData).then(function (person) {
            if (person) {
                _this.mergePersonIntoUpnCache(person);
            }
            _this.peopleCache.retrieving.person[upn] = undefined;
            _this.peopleCache.lastUpdated = Date.now();
            return person;
        });
        this.peopleCache.retrieving.person[upn] = retrievingPromise;
        return retrievingPromise;
    };
    PeopleRepo.prototype.getPeopleInSpace = function (spaceId, dipSpaceData, forceRefresh) {
        var _this = this;
        if (forceRefresh === void 0) { forceRefresh = false; }
        var existingSpaceCall = this.peopleCache.retrieving.peopleInSpace[spaceId];
        if (existingSpaceCall) {
            return existingSpaceCall;
        }
        this.checkIfCacheExpired(forceRefresh);
        if (this.peopleCache.assignedSpaceMap[spaceId]) {
            return Promise.resolve(this.peopleCache.assignedSpaceMap[spaceId]);
        }
        var retrievingPromise = this.getPeopleBySpace(dipSpaceData).then(function (people) {
            _this.mergePeopleIntoCache(people, spaceId);
            _this.peopleCache.retrieving.peopleInSpace[spaceId] = undefined;
            _this.peopleCache.lastUpdated = Date.now();
            return _this.peopleCache.assignedSpaceMap[spaceId];
        });
        this.peopleCache.retrieving.peopleInSpace[spaceId] = retrievingPromise;
        return retrievingPromise;
    };
    PeopleRepo.prototype.getPeopleInBuilding = function (buildingId, dipPersonData, forceRefresh) {
        var _this = this;
        if (forceRefresh === void 0) { forceRefresh = false; }
        var existingSpaceCall = this.peopleCache.retrieving.peopleInBuilding[buildingId];
        if (existingSpaceCall) {
            return existingSpaceCall;
        }
        this.checkIfCacheExpired(forceRefresh);
        if (this.peopleCache.assignedSpaceMap[buildingId]) {
            return Promise.resolve(this.peopleCache.assignedSpaceMap[buildingId]);
        }
        var retrievingPromise = this.getPeopleByBuilding(dipPersonData).then(function (people) {
            _this.mergePeopleIntoCache(people, buildingId);
            _this.peopleCache.retrieving.peopleInBuilding[buildingId] = undefined;
            _this.peopleCache.lastUpdated = Date.now();
            return _this.peopleCache.assignedSpaceMap[buildingId];
        });
        this.peopleCache.retrieving.peopleInBuilding[buildingId] = retrievingPromise;
        return retrievingPromise;
    };
    /*------------------Private methods----------------*/
    PeopleRepo.prototype.getPeopleBySpace = function (dipSpaceData) {
        return Promise.resolve(dipSpaceData).then(function (space) {
            if (space && space.people) {
                return space.people.map(function (person) {
                    return {
                        displayName: person.displayName,
                        dtId: person.$dtId,
                        givenName: person.givenName,
                        floorId: person.floorId,
                        jobTitle: person.jobTitle,
                        userPrincipalName: person.userPrincipalName,
                        surName: person.surName,
                        buildingId: person.buildingId,
                        spaceId: space.dtId,
                        featureId: space.featureId ? space.featureId : ""
                    };
                });
            }
            return [];
        });
    };
    PeopleRepo.prototype.getPerson = function (dipPersonData) {
        var _this = this;
        return Promise.resolve(dipPersonData).then(function (user) {
            return _this.getPersonDetails(user);
        });
    };
    PeopleRepo.prototype.getPeopleByBuilding = function (dipPersonData) {
        var _this = this;
        return Promise.resolve(dipPersonData).then(function (users) {
            return users.map(function (user) {
                return _this.getPersonDetails(user);
            });
        });
    };
    PeopleRepo.prototype.hasDataExpired = function (lastUpdateTime) {
        var adjustedTime = Date.now() - this.invalidationPeriod;
        return adjustedTime > lastUpdateTime;
    };
    PeopleRepo.prototype.checkIfCacheExpired = function (forceRefresh) {
        if (this.hasDataExpired(this.peopleCache.lastUpdated) || forceRefresh) {
            this.peopleCache.upnMap = {};
            this.peopleCache.assignedSpaceMap = {};
            this.peopleCache.lastUpdated = 0;
            this.peopleCache.retrieving = { person: {}, peopleInSpace: {}, peopleInBuilding: {} };
        }
    };
    PeopleRepo.prototype.mergePersonIntoUpnCache = function (person) {
        this.peopleCache.upnMap[person.userPrincipalName] = person;
    };
    PeopleRepo.prototype.mergePeopleIntoCache = function (newPeopleToAdd, parentSpaceId) {
        var _this = this;
        newPeopleToAdd.forEach(function (person) {
            _this.mergePersonIntoUpnCache(person);
            _this.addPersonToSpaceMapping(person, person.spaceId);
            if (parentSpaceId) {
                _this.addPersonToSpaceMapping(person, parentSpaceId);
            }
        });
    };
    PeopleRepo.prototype.addPersonToSpaceMapping = function (person, spaceId) {
        if (!this.peopleCache.assignedSpaceMap[spaceId]) {
            this.peopleCache.assignedSpaceMap[spaceId] = [];
        }
        var personInMap = this.peopleCache.assignedSpaceMap[spaceId].find(function (p) { return p.spaceId === spaceId && p.userPrincipalName === person.userPrincipalName; });
        if (!personInMap) {
            this.peopleCache.assignedSpaceMap[spaceId].push(person);
        }
    };
    PeopleRepo.prototype.getPersonDetails = function (user) {
        var space = user.space;
        return __assign(__assign({}, user.properties), { dtId: user.dtId, spaceId: space ? space.dtId : "", givenName: user.givenName, surName: user.surName, regionId: user.regionId ? user.regionId : "", floorId: user.floorId ? user.floorId : "", buildingId: user.buildingId ? user.buildingId : "", featureId: space && space.featureId ? space.featureId : "" });
    };
    return PeopleRepo;
}());
export { PeopleRepo };
