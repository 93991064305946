import { makeStyles } from "@fluentui/react-make-styles";

export const useFallbackStyles = makeStyles({
    container: {
        position: "absolute",
        height: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center"
    },
    headerText: {
        marginTop: "36px",
        fontSize: "26px",
        lineHeight: "32px",
        fontWeight: 700
    },
    text: {
        width: "380px",
        height: "32px",
        marginTop: "36px",
        lineHeight: "20px",
        fontWeight: 600,
        fontSize: "14px"
    },
    guid: {
        marginTop: "36px"
    }
});
