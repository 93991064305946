import { DetailsPanel } from "../../DetailsPanel/DetailsPanel";
import { FooterButtons } from "../../FooterButtons/FooterButtons";
import { IMapStateControllerBaseProps } from "./MapStateController.Types";
import { MapFeaturesOverlay } from "../MapFeaturesOverlay/MapFeaturesOverlay";
import React from "react";

export function MapStateControllerBase(props: IMapStateControllerBaseProps): JSX.Element {
    return (
        <>
            {/* Commenting below option for toggle theme temporarily based on UX feedback.     
            <DefaultButton
                toggle
                checked={isDarkTheme}
                text={isDarkTheme ? "Light" : "Dark"}
                style={{ position: "absolute", top: "0px" }}
                onClick={toggleTheme}
                allowDisabledFocus
            /> */}
            <MapFeaturesOverlay />
            <DetailsPanel />
            <FooterButtons />
        </>
    );
}
