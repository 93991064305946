import { makeStyles } from "@fluentui/react-make-styles";

export const useSpaceCardStyles = makeStyles({
    peopleTitle: (theme) => ({
        margin: "0",
        marginBottom: "12px",
        marginTop: "8px",
        fontSize: theme.fontSizeBase300,
        lineHeight: theme.lineHeightBase300,
        color: "#FEFEFE",
        fontFamily: theme.fontFamilyBase,
        fontWeight: theme.fontWeightRegular
    }),
    peopleText: (theme) => ({
        margin: "0",
        marginBottom: "12px",
        marginTop: "8px",
        fontSize: theme.fontSizeBase300,
        lineHeight: theme.lineHeightBase300,
        color: theme.colorNeutralForeground2,
        fontFamily: theme.fontFamilyBase,
        fontWeight: theme.fontWeightRegular
    }),
    img: () => ({
        height: "150px",
        width: "100%",
        maxWidth: "106%",
        objectFit: "cover",
        marginBottom: "13px",
        marginTop: "12px",
        borderRadius: "8px"
    })
});
