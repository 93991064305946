import React, { useEffect, useRef, useState } from "react";
import { addActivityID, removeActivityID } from "./redux/Actions/AppMetricsActions";
import {
    clearDetailsPanelStack,
    clearPersonSelection,
    clearPoiLayer,
    clearSpacePinsLayer,
    resetApp,
    resetApplicationState,
    setCategory,
    setMenuPanelCategory,
    setResetInProgress,
    setRoom
} from "./redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import { AriaHintsList } from "./components/AriaHintsList/AriaHintsList";
import { ILogger } from "@smartbuilding/log-provider";
import IdleTimer from "react-idle-timer";
import { MapStateController } from "./components/maps/MapStateController/MapStateController";
import { RootCategoryLabel } from "./redux/Types";
import { electronService } from "@smartbuilding/electron-service";
import { getAppLoggedMetrics } from "./redux/Selectors/AppMetricsSelectors";
import { getResetStatus } from "./redux/Selectors/ResetSelector";
import { initializeCentro } from "@smartbuilding/smartbuilding-ocv-feedback";
import { serviceIdentifiers } from "./serviceContainer/ServiceIdentifiers";
import { useErrorCheck } from "./hooks/useErrorCheck";
import { useFeatureFlightingValues } from "./hooks/useFeatureFlightingValues";
import { useHandleZoomPanelDisplay } from "./hooks/useHandleZoomPanelDisplay";
import { useInjection } from "./serviceContainer/ServiceContainerProvider";

export function App(): JSX.Element {
    const dispatch = useDispatch();
    const shouldReset = useSelector(getResetStatus);
    const { activityId } = useSelector(getAppLoggedMetrics);
    const timeOut = 45000;
    const isIdle = useRef(true);
    const isTimedOut = useRef(true);
    const hasAppStarted = useRef(false);
    const [isActivityStart, setIsActivityStart] = useState(false);
    const logger = useInjection<ILogger>(serviceIdentifiers.logger);
    useFeatureFlightingValues(logger);

    useHandleZoomPanelDisplay();
    useErrorCheck();

    useEffect(() => {
        initializeCentro(logger);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (hasAppStarted.current) {
            if (isActivityStart) {
                dispatch(addActivityID());
            } else {
                logger.logEvent("Activity End", { activityId: activityId });
                dispatch(removeActivityID());
            }
        }
    }, [isActivityStart]);

    useEffect(() => {
        if (hasAppStarted.current && isActivityStart) {
            logger.logEvent("Activity Start", { activityId: activityId });
        }
    }, [activityId]);

    useEffect(() => {
        if (shouldReset) {
            dispatch(resetApplicationState(false));
        }
    }, [dispatch, shouldReset]);

    const isKiosk = (): boolean => {
        return electronService.isElectron();
    };

    const resetApplication = (): void => {
        // Backup in case buildingId or floorId is not populated in DeviceConfig.json.
        // investigation is required, please validate device config to identify why buildingid or floorid is undefined
        /*if (!deviceConfig.buildingId || !deviceConfig.floorId) {
            logger.logEvent("[AppRefresh] Window refresh", {
                buildingId: deviceConfig.buildingId,
                floorId: deviceConfig.floorId
            });
            window.location.reload();
            return;
        }*/

        // setBuilding within resetApp Saga calls clearPeopleLayer()
        dispatch(setResetInProgress(true));
        dispatch(resetApp());

        // Flag for individual components to reset their internal state.
        dispatch(resetApplicationState(true));

        dispatch(clearPersonSelection());
        dispatch(clearPoiLayer());
        dispatch(clearSpacePinsLayer());

        // Setting room to undefined calls dismissPanel() which calls clearPersonSelection() and clearPoiLayer().
        dispatch(setRoom(undefined));
        dispatch(setCategory(undefined));
        dispatch(setMenuPanelCategory(RootCategoryLabel));
        dispatch(clearDetailsPanelStack());
    };

    const handleOnIdle = (): void => {
        if (!isTimedOut.current && !isIdle.current) {
            resetApplication();
            setIsActivityStart(false);
        }
    };

    const handleOnAction = (): void => {
        isIdle.current = false;
        isTimedOut.current = false;
        if (!hasAppStarted.current) {
            hasAppStarted.current = true;
        } else {
            setIsActivityStart(true);
        }
    };

    return (
        <>
            {isKiosk() && <IdleTimer onIdle={handleOnIdle} onAction={handleOnAction} timeout={timeOut} />}
            <AriaHintsList />
            <MapStateController />
        </>
    );
}
