import { FluentProvider, smartBuildingDarkTheme, smartBuildingLightTheme } from "@smartbuilding/ui-components-theme";
import React, { useState } from "react";
import { Customizer } from "@fluentui/react";
import { GetSBClientTheme } from "./ThemeSwitcher";
import { ISBClientTheme } from "./Theme.Types";

interface IThemeProps {
    children?: JSX.Element;
}

interface IThemeContext {
    theme: ISBClientTheme;
    changeTheme: (isDarkTheme: boolean) => void;
}

export const ThemeContext = React.createContext<IThemeContext>({} as IThemeContext);

export function ThemeProvider(props: IThemeProps): JSX.Element {
    const [isDarkTheme, setIsDarkTheme] = useState(true);

    const changeTheme = (selectedTheme: boolean): void => {
        setIsDarkTheme(selectedTheme);
    };

    return (
        <Customizer settings={{ theme: GetSBClientTheme(isDarkTheme) }}>
            <FluentProvider theme={isDarkTheme ? smartBuildingDarkTheme : smartBuildingLightTheme}>
                <ThemeContext.Provider value={{ theme: GetSBClientTheme(isDarkTheme), changeTheme }}>
                    {props.children}
                </ThemeContext.Provider>
            </FluentProvider>
        </Customizer>
    );
}
