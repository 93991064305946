import { FeatureExperimentsNamespaces, FeatureFlagNames } from "@smartbuilding/feature-flighting-service";
import { useEffect, useState } from "react";
import { useFeatureFlightingContext } from "@smartbuilding/feature-flighting-service-react";

export function useBadgeScanFeatureFlag(): boolean {
    const featureFlightingContext = useFeatureFlightingContext();
    const [enable, setEnable] = useState(false);

    useEffect(() => {
        featureFlightingContext.initialize().then(() => {
            const value = featureFlightingContext.getFeatureValue(
                FeatureExperimentsNamespaces.SmartBuildingClient,
                FeatureFlagNames.BadgeScanEnabled
            );
            if (typeof value === "boolean" && value) {
                setEnable(value);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return enable;
}
