var SmartBuildingApiHttp = /** @class */ (function () {
    function SmartBuildingApiHttp(httpService, baseApiUrl) {
        this.httpService = httpService;
        this.endpointUrl = baseApiUrl + "/api/v1.0/spaces";
    }
    SmartBuildingApiHttp.prototype.getAzureMapsData = function (datasetId) {
        return this.httpService
            .get(this.endpointUrl + "/mapData/" + datasetId)
            .then(function (val) { return val.data; })
            .catch(function () {
            return undefined;
        });
    };
    SmartBuildingApiHttp.prototype.getSpacePinIcon = function (spacetype, filled) {
        return this.httpService
            .get(this.endpointUrl + "/pinIcon/" + spacetype + "/" + filled)
            .then(function (val) { return val.data; })
            .catch(function () {
            return undefined;
        });
    };
    SmartBuildingApiHttp.prototype.getSpacesInBuilding = function (buildingId) {
        return this.httpService
            .get(this.endpointUrl + "/" + buildingId)
            .then(function (val) { return val.data; });
    };
    SmartBuildingApiHttp.prototype.getSpacesInBuildingByCategory = function (buildingId, category) {
        return this.httpService
            .get(this.endpointUrl + "/" + buildingId + "/" + category)
            .then(function (val) { return val.data; });
    };
    SmartBuildingApiHttp.prototype.getSpacesInFloor = function (buildingId, floorId) {
        return this.httpService
            .get(this.endpointUrl + "/" + buildingId + "/" + floorId)
            .then(function (val) { return val.data; });
    };
    SmartBuildingApiHttp.prototype.getCategoriesInBuilding = function (buildingId) {
        return this.httpService
            .get(this.endpointUrl + "/categories/" + buildingId)
            .then(function (val) { return val.data; });
    };
    SmartBuildingApiHttp.prototype.getUserRing = function (upn) {
        /**
         * @todo Replace with actual call to SmartBuildingAPI to get kiosk ring
         */
        return this.httpService.get(this.endpointUrl + "/user/" + upn).then(function (val) { return val.data; });
    };
    SmartBuildingApiHttp.prototype.getKioskRing = function (kioskId) {
        /**
         * @todo Replace with actual call to SmartBuildingAPI to get kiosk ring
         */
        return this.httpService.get(this.endpointUrl + "/kiosk/" + kioskId).then(function (val) { return val.data; });
    };
    SmartBuildingApiHttp.prototype.getSpaceBusynessRuleSet = function () {
        return this.httpService.get(this.endpointUrl + "/spaceBusynessRuleSet").then(function (val) { return val.data; });
    };
    SmartBuildingApiHttp.prototype.getRoomTags = function (buildingId) {
        return this.httpService
            .get(this.endpointUrl + "/tags/" + buildingId)
            .then(function (val) { return val.data; })
            .catch(function (error) {
            throw error;
        });
    };
    return SmartBuildingApiHttp;
}());
export { SmartBuildingApiHttp };
