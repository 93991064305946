interface IAriaHint {
    id: string;
    hint: string;
}

export const ariaHints: Record<string, Record<string, IAriaHint>> = {
    button: {
        generic: {
            id: "generic-button",
            hint: "Press to select"
        }
    },
    backButton: {
        generic: {
            id: "generic-back-button",
            hint: "Press to go back"
        },
        booking: {
            id: "booking-back-button",
            hint: "Press to go back to room list"
        },
        confirm: {
            id: "confirm-back-button",
            hint: "Press to go back to space card"
        },
        directions: {
            id: "directions-back-button",
            hint: "Press to go back to space card"
        }
    },
    closeButton: {
        generic: {
            id: "generic-close-button",
            hint: "Press to close"
        },
        booking: {
            id: "booking-close-button",
            hint: "Press to go back to space categories"
        },
        confirm: {
            id: "confirm-close-button",
            hint: "Press to go back to space categories"
        },
        directions: {
            id: "directions-close-button",
            hint: "Press to go back to space categories"
        }
    },
    primaryButton: {
        booking: {
            id: "booking-primary-button",
            hint: "Press to reserve conference room"
        },
        confirm: {
            id: "confirm-primary-button",
            hint: "Press to get directions to space"
        },
        directions: {
            id: "directions-primary-button",
            hint: "Press to get directions"
        }
    },
    secondaryButton: {
        booking: {
            id: "booking-secondary-button",
            hint: "Press to reserve see directions to space"
        },
        confirm: {
            id: "confirm-secondary-button",
            hint: "Press to cancel booking"
        }
    },
    spaceCard: {
        selectButton: {
            id: "spacecard-select-button",
            hint: "Press to select and book"
        }
    },
    bookRoom: {
        bookButton: {
            id: "bookroom-book-button",
            hint: "Press to reserve room"
        },
        directionsButton: {
            id: "bookroom-directions-button",
            hint: "Press to see directions to space"
        }
    }
};
