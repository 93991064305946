import { IStyle, IStyleFunction } from "@fluentui/react";
import { ILogger } from "@smartbuilding/log-provider";
import { ISBClientTheme } from "../../../theme-provider/Theme.Types";
import { ISearchResultItemProps } from "@smartbuilding/ui-components-search";
import { RoomSubTypes } from "@smartbuilding/adt-v2-types";

// Types used within the Menu Search Base for use of the menu search logger
export type MenuSearchClickCallback = (id: string, type: MenuSearchType) => void;
export type MenuSearchType = RoomSubTypes | "Person" | "Building";

export interface IMenuSearchBaseProps {
    logger: ILogger;
    theme: ISBClientTheme;
    styles?: IStyleFunction<{}, IMenuSearchStyles>;
}

export interface IMenuSearchStyleProps {
    theme: ISBClientTheme;
}

export interface IMenuSearchStyles {
    root: IStyle;
    text: IStyle;
    searchBox: IStyle;
    bodyDropdown: IStyle;
}

export enum MenuResultsShown {
    Min = 3,
    Mid = 4,
    Max = 9,
    MinResultsCategoryCount = 3,
    MidResultsCategoryCount = 2,
    MaxResultsCategoryCount = 1
}

export type SearchFilter = RoomSubTypes | NonRoomSearchFilter;

export enum NonRoomSearchFilter {
    People = "People",
    Buildings = "Buildings",
    Default = "Default"
}

export enum NavigationIds {
    People = "people",
    Buildings = "buildings",
    Default = "default"
}

export enum NoResultsText {
    NoResultsId = "noResults",
    NoResults = "No Results"
}

export interface ISearchResultItem {
    sortBy: string;
    filterBy: string[];
    itemData: ISearchResultItemProps;
}
