import { ErrorAction, ErrorTypes } from "../Actions/ErrorActions";

export interface IError {
    message: string;
    stack?: string;
}

export interface IErrorState {
    mapError: IError | null;
    sagaError: IError | null;
    httpError: IError | null;
    bootstrapperError: IError | null;
}

export const initialState: IErrorState = {
    mapError: null,
    sagaError: null,
    httpError: null,
    bootstrapperError: null
};

export function errorReducer(state = initialState, action: ErrorAction): IErrorState {
    switch (action.type) {
        case ErrorTypes.MAP_ERROR:
            return {
                ...state,
                mapError: action.payload
            };
        case ErrorTypes.SAGA_ERROR:
            return {
                ...state,
                sagaError: action.payload
            };
        case ErrorTypes.HTTP_ERROR:
            return {
                ...state,
                httpError: action.payload
            };
        case ErrorTypes.BOOTSTRAPPER_ERROR:
            return {
                ...state,
                bootstrapperError: action.payload
            };
        default:
            return state;
    }
}
