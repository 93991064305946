import { IMenuSearchBaseProps, IMenuSearchStyleProps, IMenuSearchStyles } from "./MenuSearch.Types";
import React, { useContext } from "react";
import { ILogger } from "@smartbuilding/log-provider";
import { MenuSearchBase } from "./MenuSearch.Base";
import { ThemeContext } from "../../../theme-provider/ThemeProvider";
import { getMenuSearchStyles } from "./MenuSearch.Styles";
import { serviceIdentifiers } from "../../../serviceContainer/ServiceIdentifiers";
import { styled } from "@fluentui/react";
import { useInjection } from "../../../serviceContainer/ServiceContainerProvider";

// eslint-disable-next-line @typescript-eslint/naming-convention
const StyledMenuSearch = styled<IMenuSearchBaseProps, IMenuSearchStyleProps, IMenuSearchStyles>(
    MenuSearchBase,
    getMenuSearchStyles
);

export function MenuSearch(): JSX.Element {
    const theme = useContext(ThemeContext).theme;
    const logger = useInjection<ILogger>(serviceIdentifiers.logger);
    return <StyledMenuSearch logger={logger} theme={theme} />;
}
