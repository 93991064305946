import { IBuilding, IRoomInfo, ISpaceInfo, PoiFloorMapping } from "../Types";
import { RoomSubTypes } from "@smartbuilding/adt-v2-types";
import { RootState } from "../Reducers/RootReducer";
import { createSelector } from "reselect";
import { getSensorData } from "@smartbuilding/signalr-redux";

export const getBuildingMap = (store: RootState): Record<string, IBuilding> => store.space.buildings;
export const getBuildings = (store: RootState): IBuilding[] => Object.values(store.space.buildings);
export const getBuildingId = (store: RootState): string => store.space.buildingId;
export const getRoomId = (store: RootState): string | undefined => store.space.selectedRoomId;
export const getRoomMap = (store: RootState): Record<string, IRoomInfo> => store.space.rooms;
export const getFloorId = (store: RootState): string => store.space.selectedFloorId;
export const getRegions = (store: RootState): ISpaceInfo[] => Object.values(store.space.regions);
export const getRegionMap = (store: RootState): Record<string, ISpaceInfo> => store.space.regions;
export const getSpaceBusynessRuleSet = (store: RootState): Array<RoomSubTypes> => store.space.spaceBusynessRuleSet;
export const getDeepLinkFloorId = (store: RootState): string | undefined => store.space.deepLinkFloorId;
export const getDeepLinkRoomId = (store: RootState): string | undefined => store.space.deepLinkRoomId;
export const getBuilding = createSelector([getBuildingMap, getBuildingId], (buildingMap, buildingId):
    | IBuilding
    | undefined => {
    return buildingMap[buildingId];
});
export const getBuildingName = createSelector([getBuilding], (building) => building?.name);
export const getLocation = createSelector([getBuilding], (building) => building?.location);
export const getBuildingMapData = createSelector([getBuilding], (building) => building?.mapData);
export const getRegion = createSelector([getBuilding], (building) => building?.regionId);
export const getFloor = createSelector([getBuilding, getFloorId], (building, floorId) =>
    building?.floors?.find((f) => f.id === floorId)
);
export const getFloors = createSelector([getBuilding], (building): ISpaceInfo[] =>
    building?.floors ? building?.floors : []
);

export const getRoom = createSelector([getRoomMap, getRoomId], (rooms, roomId) => (roomId ? rooms[roomId] : undefined));
export const getListOfRoomsInBuilding = createSelector([getBuilding], (building): string[] => building?.rooms ?? []);
export const getRooms = createSelector([getBuilding, getRoomMap], (building, rooms): IRoomInfo[] =>
    building && building.rooms ? building.rooms.map((roomId) => rooms[roomId]) : []
);
export const getPointsOfInterest = createSelector(
    [getBuilding],
    (building): PoiFloorMapping => (building?.pointsOfInterest ? building?.pointsOfInterest : {})
);
export const getConferenceRooms = createSelector([getRooms], (rooms) =>
    rooms.filter((r) => r.type === RoomSubTypes.ConferenceRoom)
);
export const getConferenceDetailsList = createSelector([getConferenceRooms], (rooms) =>
    rooms.map((room) => room.cardAttributes)
);
export const getFocusRooms = createSelector([getRooms], (rooms) =>
    rooms.filter((r) => r.type === RoomSubTypes.FocusRoom)
);
export const getProjectRooms = createSelector([getRooms], (rooms) =>
    rooms.filter((r) => r.type === RoomSubTypes.ProjectRoom)
);
export const getOtherFacilities = createSelector([getRooms], (rooms) =>
    rooms.filter((r) => r.type === RoomSubTypes.Desk || r.type === RoomSubTypes.Office)
);

export const getSpaceBusynessRooms = createSelector([getRooms, getSpaceBusynessRuleSet], (rooms, spaceBusynessTypes) =>
    rooms.filter((r) => spaceBusynessTypes.includes(r.type))
);

export const getHotDeskingRooms = createSelector([getRooms], (rooms) =>
    rooms.filter((r) => r.type === RoomSubTypes.HotDeskingRoom)
);

export const getHotDeskingRoomsForCurrentFloor = createSelector([getHotDeskingRooms, getFloorId], (rooms, floorId) =>
    rooms.filter((r) => r.cardAttributes.floorId === floorId)
);

export const getDiningRooms = createSelector([getRooms], (rooms) =>
    rooms.filter(
        (r) =>
            (r.cardAttributes.isPeopleDensityEnabled && r.type === RoomSubTypes.DiningSeating) ||
            r.type === RoomSubTypes.DiningServery
    )
);

export const getDiningRoomsForCurrentFloor = createSelector([getDiningRooms, getFloorId], (rooms, floorId) =>
    rooms.filter((r) => r.cardAttributes.floorId === floorId)
);

export const getRoomsByType = createSelector(
    getRooms,
    (store: RootState, roomType: RoomSubTypes) => roomType,
    (rooms, roomSubtype) => rooms.filter((room) => room.type === roomSubtype)
);

export const getCategory = (state: RootState): string | undefined => state.space.category;
export const getBuildingCategories = createSelector([getBuilding], (building) =>
    building && building.categories ? building.categories : {}
);

export const getWebSensorLayerEnabledArray = createSelector([getBuildingCategories], (categoryMap) =>
    Object.entries(categoryMap)
        .filter(([key, value]) => value.webSensorLayerEnabled)
        .map(([key, value]) => key as RoomSubTypes)
);

export const getSpaceCategory = createSelector([getCategory, getBuildingCategories], (category, categories) =>
    category && categories ? categories[category] : undefined
);
export const getCategorySpaceIds = (state: RootState): Array<string> => state.space.categorySpaces;
export const getCategorySpaces = createSelector([getRoomMap, getCategorySpaceIds], (mapping, spaceIds) =>
    spaceIds.map((id) => mapping[id])
);
export const getMenuPanelCategory = (state: RootState): string => state.space.menuPanelCategory;
export const getMenuPanelSpaceCategory = createSelector(
    [getMenuPanelCategory, getBuildingCategories],
    (menuPanelCategory, categories) =>
        menuPanelCategory && categories && categories[menuPanelCategory] ? categories[menuPanelCategory] : undefined
);

export const getCurrentFloorHotDeskingRoomsWithSensorsMap = createSelector(
    [getSensorData, getHotDeskingRoomsForCurrentFloor],
    (sensorMap, hotdeskingRooms) =>
        Object.fromEntries(
            Object.entries(sensorMap).filter(([key, value]) => hotdeskingRooms.map((h) => h.id).includes(key))
        )
);

export const getSpaceBusynessRoomsWithSensorsMap = createSelector(
    [getSensorData, getSpaceBusynessRooms],
    (sensorMap, spaceBusynessRooms) =>
        Object.fromEntries(
            Object.entries(sensorMap).filter(([key, value]) => spaceBusynessRooms.map((s) => s.id).includes(key))
        )
);
