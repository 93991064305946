var SensorSubscriptionManager = /** @class */ (function () {
    function SensorSubscriptionManager() {
    }
    SensorSubscriptionManager.prototype.subscribeToSensorValueSource = function (sensorReader, sensorValueWriters, spaceDataTypePairs) {
        var spaceDataTypePairsPromise;
        if (Array.isArray(spaceDataTypePairs)) {
            spaceDataTypePairsPromise = Promise.resolve(spaceDataTypePairs);
        }
        else {
            spaceDataTypePairsPromise = spaceDataTypePairs;
        }
        var subscriptionPromise = spaceDataTypePairsPromise.then(function (subscriptionPairs) {
            for (var _i = 0, sensorValueWriters_1 = sensorValueWriters; _i < sensorValueWriters_1.length; _i++) {
                var sensorWriter = sensorValueWriters_1[_i];
                for (var _a = 0, subscriptionPairs_1 = subscriptionPairs; _a < subscriptionPairs_1.length; _a++) {
                    var subscriptionPair = subscriptionPairs_1[_a];
                    sensorWriter.subscribeToSensorValue(subscriptionPair.spaceId, subscriptionPair.sensorDataType, sensorReader);
                }
            }
            return function () {
                for (var _i = 0, sensorValueWriters_2 = sensorValueWriters; _i < sensorValueWriters_2.length; _i++) {
                    var sensorWriter = sensorValueWriters_2[_i];
                    for (var _a = 0, subscriptionPairs_2 = subscriptionPairs; _a < subscriptionPairs_2.length; _a++) {
                        var subscriptionPair = subscriptionPairs_2[_a];
                        sensorWriter.unsubscribeFromSensorValue(subscriptionPair.spaceId, subscriptionPair.sensorDataType, sensorReader);
                    }
                }
            };
        });
        return function () {
            return subscriptionPromise.then(function (deRegister) { return deRegister(); });
        };
    };
    return SensorSubscriptionManager;
}());
export { SensorSubscriptionManager };
