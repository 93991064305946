import { Environment } from "../Enums/Environment";
var AzureMapsTokenHttp = /** @class */ (function () {
    function AzureMapsTokenHttp(httpService, logger, environment) {
        this.httpService = httpService;
        this.logger = logger;
        var env = Environment.DevelopmentUrl;
        if (environment === Environment.Production) {
            env = Environment.ProductionUrl;
        }
        // Endpoint is here to facilitate a simple to use service in the Service Container
        this.endpointUrl = "https://sbs-" + env + "-smartbuildingapi-westus.azurewebsites.net/api/v1.0/azureMapsToken";
    }
    AzureMapsTokenHttp.prototype.getAzureMapsToken = function () {
        var _this = this;
        return this.httpService
            .get(this.endpointUrl)
            .then(function (response) {
            if (response.status === 200)
                return response.data;
            var error = new Error("An invalid response has been received from the azure maps token endpoint");
            _this.logger.logError(error, {
                status: response.status
            });
            return Promise.reject(error);
        })
            .catch(function (error) {
            _this.logger.logError(new Error("An error occurred when obtaining an azure maps token"), {
                errorName: error.name,
                errorMessage: error.message
            });
            return Promise.reject(error);
        });
    };
    return AzureMapsTokenHttp;
}());
export { AzureMapsTokenHttp };
