/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/display-name */

import React from "react";
import { ariaHints } from "./AriaHintsList.Types";

export const AriaHintsList = React.memo(
    (): JSX.Element => {
        const generateHintElements = (): JSX.Element[][] => {
            return Object.values(ariaHints).map((component) => {
                return Object.values(component).map((element) => {
                    return (
                        <span id={element.id} key={element.id + element.hint}>
                            {element.hint}
                        </span>
                    );
                });
            });
        };

        return <div style={{ display: "none" }}>{generateHintElements()}</div>;
    }
);
