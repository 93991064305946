var MyHubUserHttpService = /** @class */ (function () {
    function MyHubUserHttpService(apiUrl, logger, httpService) {
        this.logger = logger;
        this.httpService = httpService;
        this.serviceTag = "[MyHub.Http.Service]";
        this.baseURL = "[MyHub.Http.Service]";
        this.httpService = httpService;
        this.baseURL = apiUrl + "/deepLinkData";
    }
    MyHubUserHttpService.prototype.saveDeepLinkData = function (handOffData) {
        var _this = this;
        var data = {
            data: {
                sourceId: handOffData.sourceSpaceId,
                destinationId: handOffData.destinationSpaceId,
                traversal: handOffData.traversalSpaceSubType,
                sourceCoordinate: handOffData.sourceCoordinate,
                destinationCoordinate: handOffData.destinationCoordinate
            }
        };
        return this.httpService
            .post(this.baseURL, data)
            .then(function (response) {
            if (response.status === 200) {
                var data_1 = response.data;
                _this.logger.logEvent(_this.serviceTag + " " + data_1.id);
                return data_1.Id;
            }
            var error = new Error(_this.serviceTag + " An invalid response has been received from myhub endpoint");
            _this.logger.logError(error, {
                status: response.status
            });
            return Promise.reject(error);
        })
            .catch(function (error) {
            _this.logger.logError(error, {
                message: _this.serviceTag + ": " + error.message
            });
            return Promise.reject(error);
        });
    };
    MyHubUserHttpService.prototype.convertCoordinate = function (coordinate) {
        if (coordinate) {
            return coordinate.Lat + "," + coordinate.Lng;
        }
        return undefined;
    };
    return MyHubUserHttpService;
}());
export { MyHubUserHttpService };
