var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from "react";
import { FeatureFlightingContext } from "./FeatureFlightingContext";
/**
 * HOC that updates the prop value of the wrapped component with the value coming from feature experimentation treatment assignement
 * @param WrappedComponent Component wrapped by HOC to modify prop with feature experimentation value
 * @param configuration Feature experimentation configuration
 * @returns React Component
 */
export function withPropExperimentation(
// eslint-disable-next-line @typescript-eslint/naming-convention
WrappedComponent, configuration) {
    var _a;
    return _a = /** @class */ (function (_super) {
            __extends(HOCComponent, _super);
            function HOCComponent(props) {
                var _this = _super.call(this, props) || this;
                _this.state = { featureExperimentValue: undefined };
                return _this;
            }
            HOCComponent.prototype.componentDidMount = function () {
                var _this = this;
                var context = this.context;
                var namespace = configuration.namespace, flag = configuration.flag;
                context.initialize().then(function () {
                    var val = context.getFeatureValue(namespace, flag);
                    _this.setState({ featureExperimentValue: val });
                });
            };
            HOCComponent.prototype.render = function () {
                var _a;
                if (this.state.featureExperimentValue !== undefined) {
                    var props = __assign(__assign({}, this.props), (_a = {}, _a[configuration.experimentPropKey] = this.state.featureExperimentValue, _a));
                    return React.createElement(WrappedComponent, __assign({}, props));
                }
                else {
                    return React.createElement(WrappedComponent, __assign({}, this.props));
                }
            };
            return HOCComponent;
        }(Component)),
        _a.contextType = FeatureFlightingContext,
        _a;
}
