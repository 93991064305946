export var CentroEnvType;
(function (CentroEnvType) {
    CentroEnvType[CentroEnvType["Prod"] = 0] = "Prod";
    CentroEnvType[CentroEnvType["CI"] = 1] = "CI";
    CentroEnvType[CentroEnvType["Local"] = 2] = "Local";
})(CentroEnvType || (CentroEnvType = {}));
// eslint-disable-next-line @typescript-eslint/naming-convention
export var CentroUrl = {
    Prod: "https://admin.microsoft.com",
    CI: "https://admin-ignite.microsoft.com"
};
export function getCentroURl(env) {
    switch (env) {
        case CentroEnvType.CI: {
            return CentroUrl.CI;
        }
        case CentroEnvType.Prod:
        default: {
            return CentroUrl.Prod;
        }
    }
}
