import { ICheckableListItem } from "@smartbuilding/ui-components-list";
import { ISlotsInformation } from "@smartbuilding/smartbuilding-api-service";

export type TimeSlot = ISlotsInformation & { key: string; isChecked: boolean };

export const createReservationTimeSlot = (
    startingTimeSlot: ISlotsInformation,
    endingTimeSlot: ISlotsInformation
): ISlotsInformation => {
    const durationInMs = endingTimeSlot.endTimeUtc - startingTimeSlot.startTimeUtc;
    const durationInMinutes = durationInMs / 1000 / 60;

    return {
        slotStartTime: startingTimeSlot.slotStartTime,
        slotEndTime: endingTimeSlot.slotEndTime,
        slotDuration: Math.floor(durationInMinutes).toString(),
        isBookable: true,
        startTimeUtc: startingTimeSlot.startTimeUtc,
        endTimeUtc: endingTimeSlot.endTimeUtc
    };
};

export const findLastSelectedTimeSlot = (timeSlots: TimeSlot[]): TimeSlot | undefined => {
    if (!timeSlots.length) return undefined;

    for (let i = timeSlots.length - 1; i >= 0; i--) {
        if (timeSlots[i].isChecked) {
            return timeSlots[i];
        }
    }

    return undefined;
};

export const handleNonContiguousSelection = (
    checkedItems: Record<string, boolean>,
    timeSlots: TimeSlot[],
    firstSelectedTimeSlot: TimeSlot,
    lastSelectedTimeSlot: TimeSlot,
    selectedItem: ICheckableListItem
): Record<string, TimeSlot> => {
    if (!timeSlots.length) return {};

    const isFirstOrLastTimeSlot =
        firstSelectedTimeSlot.key === selectedItem.key || lastSelectedTimeSlot.key === selectedItem.key;

    // User selected a non-consecutive time slot at the beginning or end of the list. Unselect all previous selections.
    if (isFirstOrLastTimeSlot) {
        const selectedTimeSlot = timeSlots.find((slot) => slot.key === selectedItem.key);

        for (const slot of Object.keys(checkedItems)) {
            checkedItems[slot] = false;
        }

        checkedItems[selectedItem.key] = true;

        return {
            startingSlot: selectedTimeSlot as TimeSlot,
            endingSlot: selectedTimeSlot as TimeSlot
        };
    } else {
        // User unselected a time slot in the center of the list. Unselect all that comes after the selected category.
        const selectedItemIndex = timeSlots.findIndex((timeSlot) => timeSlot.key === selectedItem.key);

        for (let i = selectedItemIndex; i < timeSlots.length; i++) {
            checkedItems[timeSlots[i].key] = false;
        }

        return {
            startingSlot: firstSelectedTimeSlot,
            endingSlot: timeSlots[selectedItemIndex - 1]
        };
    }
};

export const generateTimeSlotsList = (
    checkedItems: Record<string, boolean>,
    timeSlotMap: Record<string, ISlotsInformation>
): TimeSlot[] => {
    const timeSlots: TimeSlot[] = [];

    Object.entries(checkedItems).forEach(([slot, isChecked]) => {
        timeSlots.push({
            ...timeSlotMap[slot],
            key: slot,
            isChecked
        });
    });

    timeSlots.sort((a, b) => a.startTimeUtc - b.startTimeUtc);

    return timeSlots;
};

export const getFirstPrecheckedSlot = (
    timeSlotMap: Record<string, ISlotsInformation>,
    availableTimes: Array<ICheckableListItem>
): ISlotsInformation | undefined => {
    for (const slotInfo of availableTimes) {
        if (slotInfo.prechecked) {
            return timeSlotMap[slotInfo.key];
        }
    }

    return undefined;
};
