var _a;
import { RoomSubTypes } from "@smartbuilding/adt-v2-types";
export var RoomCapabilityNames;
(function (RoomCapabilityNames) {
    RoomCapabilityNames["AudioCapable"] = "AudioCapable";
    RoomCapabilityNames["Projector"] = "Projector";
    RoomCapabilityNames["Screen"] = "Screen";
    RoomCapabilityNames["SkypeCapable"] = "SkypeCapable";
    RoomCapabilityNames["VideoCapable"] = "VideoCapable";
    RoomCapabilityNames["Pilot"] = "Pilot";
    RoomCapabilityNames["HotDesking"] = "HotDesking";
    RoomCapabilityNames["PeopleDensity"] = "PeopleDensity";
})(RoomCapabilityNames || (RoomCapabilityNames = {}));
export var capabilityIconFolder = "/Assets/Icons/RoomCardIcons/";
export var capabilityMapping = (_a = {},
    _a[RoomCapabilityNames.AudioCapable] = {
        text: "an audio system",
        title: "Audio",
        icon: capabilityIconFolder + "AudioCapable.svg"
    },
    _a[RoomCapabilityNames.Projector] = {
        text: "a projector",
        title: "Projector",
        icon: capabilityIconFolder + "Projector.svg"
    },
    _a[RoomCapabilityNames.Pilot] = {
        text: "a whiteboard",
        title: "Whiteboard",
        icon: capabilityIconFolder + "Pilot.svg"
    },
    _a[RoomCapabilityNames.Screen] = {
        text: "a large screen",
        title: "Large Screen",
        icon: capabilityIconFolder + "Screen.svg"
    },
    _a[RoomCapabilityNames.SkypeCapable] = {
        text: "an enhanced meeting experience such as Teams Room Systems or Surface Hub",
        title: "Teams",
        icon: capabilityIconFolder + "SkypeCapable.svg"
    },
    _a[RoomCapabilityNames.VideoCapable] = {
        text: "video capabilities",
        title: "Video",
        icon: capabilityIconFolder + "VideoCapable.svg"
    },
    _a);
export var sensorToRoom = {
    AvailableCapacity: RoomSubTypes.TeamOffice,
    PeopleCount: RoomSubTypes.Cafeteria
};
export var OccupancyStatusEnumValues;
(function (OccupancyStatusEnumValues) {
    OccupancyStatusEnumValues[OccupancyStatusEnumValues["Error"] = 0] = "Error";
    OccupancyStatusEnumValues[OccupancyStatusEnumValues["Occupied"] = 1] = "Occupied";
    OccupancyStatusEnumValues[OccupancyStatusEnumValues["ReservedUnoccupied"] = 2] = "ReservedUnoccupied";
    OccupancyStatusEnumValues[OccupancyStatusEnumValues["Unoccupied"] = 3] = "Unoccupied";
})(OccupancyStatusEnumValues || (OccupancyStatusEnumValues = {}));
export var OccupancyStatusDataValue;
(function (OccupancyStatusDataValue) {
    OccupancyStatusDataValue["Error"] = "Error";
    OccupancyStatusDataValue["Occupied"] = "Occupied";
    OccupancyStatusDataValue["ReservedUnoccupied"] = "ReservedUnoccupied";
    OccupancyStatusDataValue["Unoccupied"] = "Unoccupied";
    OccupancyStatusDataValue["Undefined"] = "Undefined";
})(OccupancyStatusDataValue || (OccupancyStatusDataValue = {}));
