import { DeviceConfigAction, DeviceConfigRetrieveActions, DeviceConfigSelectedActions } from "../Actions";
import { IDeviceConfigStore } from "../Types";

export const initialState: IDeviceConfigStore = {
    buildingId: undefined,
    spaceId: undefined,
    location: undefined,
    floorId: undefined
};

export function deviceConfigReducer(state = initialState, action: DeviceConfigAction): IDeviceConfigStore {
    switch (action.type) {
        case DeviceConfigRetrieveActions.STORE_CONFIG_DATA:
            return {
                ...state,
                ...action.payload.configData
            };
        case DeviceConfigSelectedActions.CLEAR_CONFIG_DATA:
            return initialState;

        default:
            return state;
    }
}
