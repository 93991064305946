import { IAzureDigitalTwinLocationRetrieve, RoomSubTypes } from "@smartbuilding/adt-v2-types";
import { IPoiCoordinate, PoiType } from "@smartbuilding/poi-service";
import { IRoomCardAttributes } from "@smartbuilding/room-card-service";
import { ISpaceCategory } from "@smartbuilding/smartbuilding-api-service";

export type PoiFloorMapping = Record<string, Partial<Record<PoiType, IPoiCoordinate[]>>>;
export type RegionBuildingsMapping = Record<string, ISpaceInfo[]>;

export interface ISpaceStore {
    /**
     * The id currently selected building
     */
    buildingId: string;

    /**
     * Mapping of buildings
     */
    buildings: Record<string, IBuilding>;

    /**
     * Mapping of spaces
     */
    rooms: Record<string, IRoomInfo>;

    /**
     * List of ids of the spaces that are a part of the current category.
     * Merges spaces of all child categories, such that if the currently selected category has two child categories
     * this list will contain the list of spaces of both of the children.
     */
    categorySpaces: Array<string>;

    /**
     * The currently selected space category
     */
    category?: string;

    /**
     * The currently selected space category for menu panel
     */
    menuPanelCategory: string;

    /**
     * The currently selected floor
     */
    selectedFloorId: string;

    /**
     * The currently selected space
     */
    selectedRoomId?: string;

    /**
     * Mapping of the regions
     */
    regions: Record<string, ISpaceInfo>;

    /**
     * Room types that should show space busyness info based on the rule set of table storage
     */

    spaceBusynessRuleSet: Array<RoomSubTypes>;

    /**
     * Floor name from the URL DeepLinking
     */
    deepLinkFloorId?: string;

    /**
     * Room name from the URL DeepLinking
     */
    deepLinkRoomId?: string;
}

export interface ISpaceInfo {
    id: string;
    name: string;
    logicalOrder?: number;
}

export interface IRoomInfo extends ISpaceInfo {
    type: RoomSubTypes;
    tags?: string[];
    cardAttributes: IRoomCardAttributes;
}

export interface IBuilding extends ISpaceInfo {
    regionId?: string;
    mapData?: IBuildingMapData;
    rooms?: string[];
    floors?: ISpaceInfo[];
    pointsOfInterest?: PoiFloorMapping;
    location?: IAzureDigitalTwinLocationRetrieve;
    categories: Record<string, ISpaceCategory>;
}

export interface IBuildingMapData {
    datasetId: string;
    tilesetId: string;
    statesets?: Partial<Record<MapStatesetIdentifiers, string>>;
}

export enum MapStatesetIdentifiers {
    OccupancyStatus = "occupancystatus",
    Motion = "motion",
    ConferenceStatus = "conferencestatus"
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const RootCategoryLabel = "root";
