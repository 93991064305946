export const themePrimary = "#0078D4";
export const themeLighterAlt = "#0C2031";
export const themeLighter = "#D0E7F8";
export const themeLight = "#043862";
export const themeTertiary = "#004C87";
export const themeSecondary = "#1A86D9"; // Required by Fabric palette, only used in ShimmerWave
export const themeDarkAlt = "#1890F1";
export const themeDark = "#3AA0F3";
export const themeDarker = "#6CB8F6";
export const neutralLighterAlt = "#202020";
export const neutralLighter = "#252525";
export const neutralLight = "#292929";
export const neutralQuaternaryAlt = "#323232";
export const neutralQuaternary = "#3B3B3B";
export const neutralTertiaryAlt = "#484848";
export const neutralTertiary = "#797979";
export const neutralSecondary = "#B3B3B3";
export const neutralPrimaryAlt = "#E1E1E1";
export const neutralPrimary = "#EDEDED";
export const neutralDark = "#F3F3F3";
export const black = "#FFFFFF"; // Note white and black are inverted in this theme
export const white = "#141414";

// Warnings and Errors
export const errorText = "#FF3C00";
export const errorBackground = "#3B1E1E";
export const blockingBackground = "#3B1E1E";
export const warningBackground = "#30250A";
export const warningHighlight = "#FFB900";
export const successBackground = "#24290F";

// Extended colors
export const severeWarningBackground = "#3B200C"; // Actually an extension color

export const dashboardBackdrop = "#303030";
export const panelBackground = "#292929";
export const overlayBackground = "rgba(0,0,0,.4)";
export const nonOverlayBackground = "rgba(0,0,0,.6)";
export const navBackground = "#212121";
export const menuBackground = "#292929";

export const criticalStatus = "#FF0505";
export const highStatus = "#FF3C00";
export const mediumStatus = "#FFAA00";
export const lowStatus = "#F2F200";
export const readyStatus = "#6CD900";
export const goodStatus = "#009E00";
export const unkownStatus = "#6B6B6B";

export const needHelpBackground = "#00838C";
export const needHelpBackgroundPressed = "#009BA6";

export const contextualAlertBackground = "#C0A9FF";

export const whiteTranslucent40 = "rgba(20,20,20,.4)";

//fonts
export const segoeUI = "Segoe-UI";

// spaces colors
export const lightGrayColor = "rgb(196, 196, 196)";
export const darkGrayColor = "rgb(63, 69, 77)";
export const blueGrayColor = "rgb(40, 59, 76)";
