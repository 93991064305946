import { IAction, IBaseAction } from "./IAction";
import { IBuilding, IRoomInfo, ISpaceInfo, PoiFloorMapping } from "../Types";
import { IInstantBookingConfirmationInfo } from "../../components/DetailsPanel/DetailsPanelProps";
import { ISpaceCategory } from "@smartbuilding/smartbuilding-api-service";
import { RoomSubTypes } from "@smartbuilding/adt-v2-types";

export enum SpaceCategoryActions {
    SPACE_CATEGORY_SELECTED = "SPACE_CATEGORY_SELECTED",
    SPACE_CATEGORY_CLEARED = "SPACE_CATEGORY_CLEARED",
    SPACE_CATEGORY_SELECTED_COMPLETE = "SPACE_CATEGORY_SELECTED_COMPLETE", // Dispatched by the Saga
    SPACE_MENU_PANEL_CATEGORY_SELECTED = "SPACE_MENU_PANEL_CATEGORY_SELECTED"
}

export enum SpaceSelectedActions {
    BUILDING_SELECTED = "BUILDING_SELECTED", // Handled by Space Saga and Space reducer
    FLOOR_SELECTED = "FLOOR_SELECTED", // Handled by Space Saga
    FLOOR_SELECTION_COMPLETE = "FLOOR_SELECTION_COMPLETE", // Dispatched by Space Saga
    ROOM_SELECTED = "ROOM_SELECTED", // Handled by Space Saga,
    INSTANT_BOOKING_ROOM_SELECTED = "INSTANT_BOOKING_ROOM_SELECTED", // Handled by Space Saga
    ROOM_SELECTION_COMPLETE = "ROOM_SELECTION_COMPLETE", // Dispatched by Space Saga
    INSTANT_BOOKING_ROOM_SELECTION_COMPLETE = "INSTANT_BOOKING_ROOM_SELECTION_COMPLETE", // Dispatched by Space Saga
    ROOM_SELECTION_CLEARED = "ROOM_SELECTION_CLEARED",
    DEFAULT_SELECTED_BUILDING = " DEFAULT_SELECTED_BUILDING"
}

export enum SpaceRetrieveActions {
    BUILDING_DATA_RETRIEVED = "BUILDING_DATA_RETRIEVED", // Dispatched by Saga
    BUILDINGS_WITH_MAP_DATA_RETRIEVED = "BUILDINGS_WITH_MAP_DATA_RETRIEVED", // Dispatched by Saga
    POINTS_OF_INTEREST_RETRIEVED = "POINTS_OF_INTEREST_RETRIEVED", // Dispatched by Space Saga
    ROOMS_RETRIEVED = "ROOMS_RETRIEVED", // Dispatched by Saga
    ROOM_IMAGE_RETRIEVED = "ROOM_IMAGE_RETRIEVED", // Dispatched by Saga
    REGIONS_DATA_RETRIEVED = "REGION_DATA_RETRIEVED",
    CATEGORIES_RETRIEVED = "CATEGORIES_RETREIVED", // Dispatched by Saga
    SPACE_BUSYNESS_RULE_SET_RETRIEVED = "SPACE_BUSYNESS_RULE_SET_RETRIEVED" // Dispatched by Saga
}

export enum DeepLinkSpaceSelectActions {
    DEEP_LINK_FLOOR_SELECT = "DEEP_LINK_FLOOR_SELECT",
    DEEP_LINK_ROOM_SELECT = "DEEP_LINK_ROOM_SELECT"
}

export type BuildingSelectedAction = IAction<string, SpaceSelectedActions.BUILDING_SELECTED>;
export type DefaultBuildingSelectedAction = IAction<
    {
        upn: string | undefined;
        buildingName: string | undefined;
        floorName: string | undefined | null;
        roomName: string | undefined | null;
    },
    SpaceSelectedActions.DEFAULT_SELECTED_BUILDING
>;
export type FloorSelectedAction = IAction<string, SpaceSelectedActions.FLOOR_SELECTED>;
export type FloorSelectionCompleteAction = IAction<string, SpaceSelectedActions.FLOOR_SELECTION_COMPLETE>;
export type RoomSelectedAction = IAction<string, SpaceSelectedActions.ROOM_SELECTED>;
export type InstantBookingRoomSelectedAction = IAction<
    { roomId: string; instantBookingConfirmationInfo: IInstantBookingConfirmationInfo },
    SpaceSelectedActions.INSTANT_BOOKING_ROOM_SELECTED
>;
export type RoomSelectionCompleteAction = IAction<string, SpaceSelectedActions.ROOM_SELECTION_COMPLETE>;
export type InstantBookingRoomSelectionCompleteAction = IAction<
    { roomId: string; instantBookingConfirmationInfo: IInstantBookingConfirmationInfo },
    SpaceSelectedActions.INSTANT_BOOKING_ROOM_SELECTION_COMPLETE
>;
export type RoomSelectionClearedAction = IBaseAction<SpaceSelectedActions.ROOM_SELECTION_CLEARED>;
export type BuildingsWithMapDataRetrievedAction = IAction<
    IBuilding[],
    SpaceRetrieveActions.BUILDINGS_WITH_MAP_DATA_RETRIEVED
>;
export type RegionsDataRetrievedAction = IAction<ISpaceInfo[], SpaceRetrieveActions.REGIONS_DATA_RETRIEVED>;
export type BuildingDataRetrievedAction = IAction<IBuilding, SpaceRetrieveActions.BUILDING_DATA_RETRIEVED>;
export type RoomsRetrievedAction = IAction<
    { buildingId: string; data: IRoomInfo[] },
    SpaceRetrieveActions.ROOMS_RETRIEVED
>;
export type PointsOfInterestRetrievedAction = IAction<
    { buildingId: string; data: PoiFloorMapping },
    SpaceRetrieveActions.POINTS_OF_INTEREST_RETRIEVED
>;
export type RoomImageRetrievedAction = IAction<
    { id: string; image: string },
    SpaceRetrieveActions.ROOM_IMAGE_RETRIEVED
>;
export type CategoriesRetrievedAction = IAction<
    { categories: Record<string, ISpaceCategory>; buildingId: string },
    SpaceRetrieveActions.CATEGORIES_RETRIEVED
>;
export type SpaceBusynessRuleSetRetrieveAction = IAction<
    RoomSubTypes[],
    SpaceRetrieveActions.SPACE_BUSYNESS_RULE_SET_RETRIEVED
>;

export type SpaceCategorySelectedAction = IAction<string, SpaceCategoryActions.SPACE_CATEGORY_SELECTED>;
export type SpaceCategoryClearedAction = IBaseAction<SpaceCategoryActions.SPACE_CATEGORY_CLEARED>;
export type SpaceCategorySelectionCompleteAction = IAction<
    Array<string>,
    SpaceCategoryActions.SPACE_CATEGORY_SELECTED_COMPLETE
>;
export type SpaceMenuPanelCategorySelectedAction = IAction<
    string,
    SpaceCategoryActions.SPACE_MENU_PANEL_CATEGORY_SELECTED
>;

export type DeepLinkFloorSelectAction = IAction<string | undefined, DeepLinkSpaceSelectActions.DEEP_LINK_FLOOR_SELECT>;
export type DeepLinkRoomSelectAction = IAction<string | undefined, DeepLinkSpaceSelectActions.DEEP_LINK_ROOM_SELECT>;

type SpaceSelectedAction =
    | BuildingSelectedAction
    | FloorSelectionCompleteAction
    | RoomSelectionCompleteAction
    | RoomSelectedAction
    | RoomSelectionClearedAction
    | DefaultBuildingSelectedAction
    | InstantBookingRoomSelectedAction
    | InstantBookingRoomSelectionCompleteAction;
type SpaceRetrieveAction =
    | BuildingDataRetrievedAction
    | BuildingsWithMapDataRetrievedAction
    | PointsOfInterestRetrievedAction
    | RoomsRetrievedAction
    | RoomImageRetrievedAction
    | RegionsDataRetrievedAction
    | CategoriesRetrievedAction;
type SpaceCategoryAction =
    | SpaceCategorySelectedAction
    | SpaceCategoryClearedAction
    | SpaceCategorySelectionCompleteAction
    | SpaceMenuPanelCategorySelectedAction
    | SpaceBusynessRuleSetRetrieveAction;
type DeepLinkSpaceAction = DeepLinkFloorSelectAction | DeepLinkRoomSelectAction;

export type SpaceAction = SpaceSelectedAction | SpaceRetrieveAction | SpaceCategoryAction | DeepLinkSpaceAction;
