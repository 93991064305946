import { IMapFeaturesOverlayStyleProps, IMapFeaturesOverlayStyles } from "./MapFeaturesOverlay.Types";
import { IStyle } from "office-ui-fabric-react";
import { deviceScreenRatio } from "../../../DeviceWindowRatio";
import { electronService } from "@smartbuilding/electron-service";

export const getMapFeaturesOverlayStyles = (props: IMapFeaturesOverlayStyleProps): IMapFeaturesOverlayStyles => {
    const root: IStyle = {
        display: "flex",
        flexDirection: "row",
        position: "absolute",
        top: props.isZoomed ? 0 : "5vh",
        left: props.isZoomed ? 0 : "2vw",
        pointerEvents: "auto",
        zIndex: "100",
        zoom: electronService.isElectron() ? `${deviceScreenRatio}` : "normal"
    };

    const zoomStyles = {
        height: "100%"
    };

    return {
        root,
        zoomStyles
    };
};
