var ConfigurationService = /** @class */ (function () {
    function ConfigurationService(configBaseUrl, httpService, logger) {
        this.configBaseUrl = configBaseUrl;
        this.httpService = httpService;
        this.logger = logger;
        this.configuration = undefined;
        this.retrieving = null;
    }
    ConfigurationService.prototype.getSetting = function (key) {
        var _this = this;
        if (this.configuration) {
            return Promise.resolve(this.configuration[key]);
        }
        return this.retrieveConfig().then(function (config) {
            _this.configuration = config;
            return _this.configuration[key];
        });
    };
    ConfigurationService.prototype.retrieveConfig = function () {
        var _this = this;
        if (this.retrieving) {
            return this.retrieving;
        }
        this.retrieving = this.httpService
            .get(this.configBaseUrl)
            .then(function (res) {
            _this.retrieving = null;
            if (typeof res.data == "string") {
                return JSON.parse(res.data);
            }
            return res.data;
        })
            .catch(function (e) {
            _this.retrieving = null;
            var msg = "Error fetching app setting configuration: ";
            if (e instanceof Error) {
                e.message = msg + e.message;
                _this.logger.logError(e);
            }
            else {
                _this.logger.logError(new Error(msg));
            }
            throw e;
        });
        return this.retrieving;
    };
    return ConfigurationService;
}());
export { ConfigurationService };
