import { ObjectHasProperties } from "@smartbuilding/utilities";
var SpaceSensorDataItemMap = /** @class */ (function () {
    function SpaceSensorDataItemMap() {
        this.spaceSensorDataMap = {};
    }
    SpaceSensorDataItemMap.prototype.add = function (spaceId, sensorDataType, item) {
        spaceId = spaceId.toLowerCase();
        var dataType = sensorDataType.toLowerCase();
        var sensorDataItemMap = this.spaceSensorDataMap[spaceId];
        if (!sensorDataItemMap) {
            sensorDataItemMap = {};
            this.spaceSensorDataMap[spaceId] = sensorDataItemMap;
        }
        var sensorDataItems = sensorDataItemMap[dataType];
        if (!sensorDataItems) {
            sensorDataItems = [];
            sensorDataItemMap[dataType] = sensorDataItems;
        }
        sensorDataItems.push(item);
    };
    /**
     * Removes an item from tne SpaceSensorDataItem map. Returns true if the item removed was the last item of that dataType
     *
     * @param {string} spaceId - Id of a space
     * @param {SensorDataType} sensorDataType - a sensor data type where you want to store the item under.
     * @param {T} item - The item to store under the space id and sensor data type. They are stored in an array collection.
     * @return {boolean} Returns true if the item removed from the map was the last item under the specified datatype
     */
    SpaceSensorDataItemMap.prototype.remove = function (spaceId, sensorDataType, item) {
        spaceId = spaceId.toLowerCase();
        var dataType = sensorDataType.toLowerCase();
        var wasLastItem = false;
        var sensorDataItemMap = this.spaceSensorDataMap[spaceId];
        if (!sensorDataItemMap) {
            return wasLastItem;
        }
        var sensorDataItems = sensorDataItemMap[dataType];
        if (!sensorDataItems) {
            return wasLastItem;
        }
        sensorDataItems = sensorDataItems.filter(function (itemInList) { return itemInList !== item; });
        sensorDataItemMap[dataType] = sensorDataItems;
        if (sensorDataItems.length === 0) {
            delete sensorDataItemMap[dataType];
            wasLastItem = true;
        }
        if (!ObjectHasProperties(sensorDataItemMap)) {
            delete this.spaceSensorDataMap[spaceId];
        }
        return wasLastItem;
    };
    SpaceSensorDataItemMap.prototype.get = function (spaceId, sensorDataType) {
        spaceId = spaceId.toLowerCase();
        var sensorDataItemMap = this.spaceSensorDataMap[spaceId];
        if (!sensorDataItemMap) {
            return [];
        }
        var sensorDataItems = sensorDataItemMap[sensorDataType.toLowerCase()];
        return sensorDataItems ? sensorDataItems : [];
    };
    SpaceSensorDataItemMap.prototype.hasSensorDataTypeItems = function (spaceId, sensorDataType) {
        spaceId = spaceId.toLowerCase();
        var sensorDataItemMap = this.spaceSensorDataMap[spaceId];
        if (!sensorDataItemMap) {
            return false;
        }
        var sensorDataItems = sensorDataItemMap[sensorDataType.toLowerCase()];
        return sensorDataItems ? true : false;
    };
    SpaceSensorDataItemMap.prototype.hasSensorDataTypes = function (spaceId) {
        spaceId = spaceId.toLowerCase();
        var sensorDataItemMap = this.spaceSensorDataMap[spaceId];
        return sensorDataItemMap ? true : false;
    };
    SpaceSensorDataItemMap.prototype.getAllSpacesAndDataTypesWithItems = function () {
        var _this = this;
        var spaceIds = Object.keys(this.spaceSensorDataMap);
        return spaceIds.map(function (spaceId) {
            return {
                spaceId: spaceId,
                sensorDataTypes: Object.keys(_this.spaceSensorDataMap[spaceId])
            };
        });
    };
    return SpaceSensorDataItemMap;
}());
export { SpaceSensorDataItemMap };
