import { defaultLabelBackgroundColor, defaultLabelColor, dropoffStopLabelBackgroundColor, dropoffStopLabelColor, labelBorderRadius, labelPadding, pickupStopLabelBackgroundColor, pickupStopLabelColor, vehicleLabelBackgroundColor, vehicleMarkerColor } from "./Constants";
export function getLabelStyles(location) {
    var isPickup = location.isPickup, isDropoff = location.isDropoff;
    if (isPickup) {
        return {
            backgroundColor: pickupStopLabelBackgroundColor,
            textColor: pickupStopLabelColor,
            padding: labelPadding,
            borderRadius: labelBorderRadius
        };
    }
    else if (isDropoff) {
        return {
            backgroundColor: dropoffStopLabelBackgroundColor,
            textColor: dropoffStopLabelColor,
            padding: labelPadding,
            borderRadius: labelBorderRadius
        };
    }
    else {
        return {
            backgroundColor: defaultLabelBackgroundColor,
            textColor: defaultLabelColor,
            padding: labelPadding,
            borderRadius: labelBorderRadius
        };
    }
}
export function getVehicleLabelStyles() {
    return {
        backgroundColor: vehicleLabelBackgroundColor,
        textColor: vehicleMarkerColor,
        padding: labelPadding,
        borderRadius: labelBorderRadius
    };
}
