import { CustomProperties } from "./CustomProperties";
var SpaceSubscriptionHttpService = /** @class */ (function () {
    function SpaceSubscriptionHttpService(notificationApiUrl, logger, httpService) {
        this.notificationApiUrl = notificationApiUrl;
        this.logger = logger;
        this.httpService = httpService;
        this.serviceTag = "[Space.Subscription.Http.Service]";
        this.groupSubscribeEndpoint = "api/groupsubscribe";
        this.groupUnsubscribeEndpoint = "api/groupunsubscribe";
    }
    SpaceSubscriptionHttpService.prototype.groupSubscribeToSpaces = function (spaceGroups) {
        var _this = this;
        if (!spaceGroups) {
            return this.logAndReject("" + this.serviceTag);
        }
        var requestUrl = "" + this.notificationApiUrl + this.groupSubscribeEndpoint;
        return this.httpService
            .post(requestUrl, [spaceGroups])
            .then(function (res) {
            return res.data;
        })
            .catch(function (error) {
            return _this.logAndReject(CustomProperties.GroupSubscribeToSpaces + ":" + error.message, error, requestUrl);
        });
    };
    SpaceSubscriptionHttpService.prototype.groupUnsubscribeToSpaces = function (spaceGroups) {
        var _this = this;
        var requestUrl = "" + this.notificationApiUrl + this.groupUnsubscribeEndpoint;
        return this.httpService
            .post(requestUrl, [spaceGroups])
            .then(function (res) {
            return res.data;
        })
            .catch(function (error) {
            return _this.logAndReject(CustomProperties.GroupUnsubscribeToSpaces + ":" + error.message, error, requestUrl);
        });
    };
    SpaceSubscriptionHttpService.prototype.logAndReject = function (message, error, url) {
        this.logger.logError(new Error("" + this.serviceTag + message));
        return Promise.reject(error);
    };
    return SpaceSubscriptionHttpService;
}());
export { SpaceSubscriptionHttpService };
