export function getRouteLegId(routeLeg) {
    return "RL_" + routeLeg.fromToPair[0].id + "_" + routeLeg.fromToPair[1].id;
}
export function getRouteLegLineLayerId(routeLeg) {
    return "LL_" + routeLeg.fromToPair[0].id + "_" + routeLeg.fromToPair[1].id;
}
export function getRouteLegDataSourceId(routeLeg) {
    return "DS_" + routeLeg.fromToPair[0].id + "_" + routeLeg.fromToPair[1].id;
}
export function getRouteLegSymbolLayerId(routeLeg) {
    return "SL_" + routeLeg.fromToPair[0].id + "_" + routeLeg.fromToPair[1].id;
}
export function getVehicleMarkerId(id) {
    return "MR_" + id;
}
export function getStopLabelId(stopId) {
    return "LABEL_" + stopId;
}
