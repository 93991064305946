var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import "../../Styles/HotDeskLayer.css";
import { OccupancyStatusDataValue } from "@smartbuilding/adt-v2-types";
import { markerIconHotDesk } from "../../Assets/HotDeskMarker/MarkerIconHotDesk";
var StatusBarColor;
(function (StatusBarColor) {
    StatusBarColor["RED"] = "#D74755";
    StatusBarColor["GREEN"] = "#4E8205";
    StatusBarColor["BLACK"] = "#292929";
    StatusBarColor["LIGHT_GREEN"] = "#92C353";
})(StatusBarColor || (StatusBarColor = {}));
var HotDeskOverlayMapLayer = /** @class */ (function () {
    function HotDeskOverlayMapLayer(spacePopupLayer) {
        this.spacePopupLayer = spacePopupLayer;
        this.spaceIds = new Set();
    }
    HotDeskOverlayMapLayer.prototype.removePopups = function () {
        var _this = this;
        this.spaceIds.forEach(function (spaceId) { return _this.spacePopupLayer.removePopup(spaceId); });
    };
    HotDeskOverlayMapLayer.prototype.dispose = function () {
        this.removePopups();
        this.spaceIds.clear();
    };
    HotDeskOverlayMapLayer.prototype.createOrUpdateHotdeskPopups = function (hotdeskData) {
        return __awaiter(this, void 0, void 0, function () {
            var output, _i, hotdeskData_1, data, content, _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        output = {};
                        _i = 0, hotdeskData_1 = hotdeskData;
                        _c.label = 1;
                    case 1:
                        if (!(_i < hotdeskData_1.length)) return [3 /*break*/, 4];
                        data = hotdeskData_1[_i];
                        content = this.getSpacePopupContent(data.availableCapacity, data.totalCapacity, data.occupancyStatus);
                        _a = output;
                        _b = data.spaceId;
                        return [4 /*yield*/, this.spacePopupLayer.createOrUpdatePopup(data.spaceId, content)];
                    case 2:
                        _a[_b] = _c.sent();
                        this.spaceIds.add(data.spaceId);
                        _c.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/, output];
                }
            });
        });
    };
    HotDeskOverlayMapLayer.prototype.getSpacePopupContent = function (availableCapacity, totalCapacity, occupancyStatus) {
        var isUnknown = occupancyStatus === OccupancyStatusDataValue.Error;
        var isUndefined = availableCapacity === undefined;
        var hotDeskStatusColor = availableCapacity === 0 ? StatusBarColor.RED : StatusBarColor.GREEN;
        var capacityDisplay = isUnknown || isUndefined ? "" : availableCapacity + "/" + totalCapacity;
        return {
            Small: {
                content: "<div class=\"hotDesk-popup-container small-popup\">" + markerIconHotDesk + "</div>",
                fillColor: isUnknown || isUndefined ? StatusBarColor.BLACK : StatusBarColor.LIGHT_GREEN
            },
            Medium: {
                content: "<div class=\"hotDesk-popup-container medium-popup\"><div><span style=\"background:" + (isUnknown || isUndefined ? StatusBarColor.BLACK : hotDeskStatusColor) + "\">" + capacityDisplay + "</span></div>" + markerIconHotDesk + "</div>",
                fillColor: StatusBarColor.BLACK
            },
            Large: {
                content: "<div class=\"hotDesk-popup-container large-popup\"><div><span style=\"background:" + (isUnknown || isUndefined ? StatusBarColor.BLACK : StatusBarColor.GREEN) + "\">" + capacityDisplay + "</div><div><span>" + (isUnknown || isUndefined ? "Occupancy unknown" : "hotdesks available") + "</span></div>" + markerIconHotDesk + "</div>",
                fillColor: StatusBarColor.BLACK
            }
        };
    };
    return HotDeskOverlayMapLayer;
}());
export { HotDeskOverlayMapLayer };
