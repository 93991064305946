import React from "react";
import { createSvgIcon } from "@fluentui/react-icons-mdl2";

// eslint-disable-next-line @typescript-eslint/naming-convention
export const PodiumOutlineIcon = createSvgIcon({
    // eslint-disable-next-line react/display-name
    svg: (args) => {
        return (
            <svg viewBox="0 0 24 24" fill="none" className={args.classes.svg} xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M20.2449 12.9966C20.9537 12.9966 21.267 13.889 20.7137 14.3321L15.9958 18.1098V19.7541C15.9958 20.9968 14.9884 22.0041 13.7458 22.0041H10.2504C9.0078 22.0041 8.00044 20.9968 8.00044 19.7541V18.1099L3.28085 14.3322C2.72744 13.8892 3.04067 12.9966 3.74953 12.9966H20.2449ZM18.1083 14.4966H5.8867L9.21912 17.164C9.39693 17.3063 9.50044 17.5218 9.50044 17.7495V19.7541C9.50044 20.1683 9.83622 20.5041 10.2504 20.5041H13.7458C14.16 20.5041 14.4958 20.1683 14.4958 19.7541V17.7495C14.4958 17.5218 14.5993 17.3064 14.777 17.1641L18.1083 14.4966ZM8.75044 8.99954H15.2451C16.1633 8.99954 16.9163 9.70665 16.9893 10.606L16.9951 10.7495V11.9995H15.4951V10.7495C15.4951 10.6312 15.4129 10.5321 15.3025 10.5061L15.2451 10.4995H8.75044C8.63209 10.4995 8.53295 10.5818 8.50704 10.6922L8.50044 10.7495V11.9995H7.00044V10.7495C7.00044 9.83137 7.70755 9.07835 8.60691 9.00534L8.75044 8.99954H15.2451H8.75044ZM12.0004 2C13.6573 2 15.0004 3.34315 15.0004 5C15.0004 6.65685 13.6573 8 12.0004 8C10.3436 8 9.00044 6.65685 9.00044 5C9.00044 3.34315 10.3436 2 12.0004 2ZM12.0004 3.5C11.172 3.5 10.5004 4.17157 10.5004 5C10.5004 5.82843 11.172 6.5 12.0004 6.5C12.8289 6.5 13.5004 5.82843 13.5004 5C13.5004 4.17157 12.8289 3.5 12.0004 3.5Z"
                    fill="currentColor"
                />
            </svg>
        );
    },
    displayName: "PodiumOutlineIcon"
});

// eslint-disable-next-line @typescript-eslint/naming-convention
export const PodiumFilledIcon = createSvgIcon({
    // eslint-disable-next-line react/display-name
    svg: (args) => {
        return (
            <svg viewBox="0 0 24 24" fill="none" className={args.classes.svg} xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M20.2449 12.9966C20.9537 12.9966 21.267 13.889 20.7137 14.3321L15.9958 18.1098V19.7541C15.9958 20.9968 14.9884 22.0041 13.7458 22.0041H10.2504C9.0078 22.0041 8.00044 20.9968 8.00044 19.7541V18.1099L3.28085 14.3322C2.72744 13.8892 3.04067 12.9966 3.74953 12.9966H20.2449ZM8.75044 8.99954H15.2451C16.1633 8.99954 16.9163 9.70665 16.9893 10.606L16.9951 10.7495V11.9995H7.00044V10.7495C7.00044 9.83137 7.70755 9.07835 8.60691 9.00534L8.75044 8.99954H15.2451H8.75044ZM12.0004 2C13.6573 2 15.0004 3.34315 15.0004 5C15.0004 6.65685 13.6573 8 12.0004 8C10.3436 8 9.00044 6.65685 9.00044 5C9.00044 3.34315 10.3436 2 12.0004 2Z"
                    fill="currentColor"
                />
            </svg>
        );
    },
    displayName: "PodiumFilledIcon"
});
