export var SpaceHasIncludes;
(function (SpaceHasIncludes) {
    SpaceHasIncludes["None"] = "None";
    SpaceHasIncludes["HasChildren"] = "HasChildren";
    SpaceHasIncludes["HasParent"] = "HasParent";
    SpaceHasIncludes["HasSensors"] = "HasSensors";
    SpaceHasIncludes["HasDevices"] = "HasDevices";
    SpaceHasIncludes["HasValues"] = "HasValues";
    SpaceHasIncludes["HasCalendar"] = "HasCalendar";
    SpaceHasIncludes["HasPeople"] = "HasPeople";
    SpaceHasIncludes["HasLocation"] = "HasLocation";
    SpaceHasIncludes["HasRequests"] = "HasRequests";
    SpaceHasIncludes["HasBuildingPhone"] = "HasBuildingPhone";
    SpaceHasIncludes["HasParking"] = "HasParking";
    SpaceHasIncludes["HasBuildingAdministration"] = "HasBuildingAdministration";
    SpaceHasIncludes["IsOfBuildingType"] = "IsOfBuildingType";
    SpaceHasIncludes["HasITSiteFunction"] = "HasITSiteFunction";
    SpaceHasIncludes["HasITSiteType"] = "HasITSiteType";
    SpaceHasIncludes["HasSalesGeo"] = "HasSalesGeo";
    SpaceHasIncludes["HasMapData"] = "HasMapData";
    SpaceHasIncludes["HasOwner"] = "HasOwner";
    SpaceHasIncludes["HasOwnershipType"] = "HasOwnershipType";
    SpaceHasIncludes["HasOperationalSupport"] = "HasOperationalSupport";
    SpaceHasIncludes["HasMECAttributes"] = "HasMECAttributes";
    SpaceHasIncludes["HasAmenities"] = "HasAmenities";
    SpaceHasIncludes["HasSurroundingEnvironment"] = "HasSurroundingEnvironment";
    SpaceHasIncludes["IsInTimezone"] = "IsInTimezone";
    SpaceHasIncludes["HasHSTSupportType"] = "HasHSTSupportType";
    SpaceHasIncludes["HasAddress"] = "HasAddress";
    SpaceHasIncludes["HasBuildingContact"] = "HasBuildingContact";
    SpaceHasIncludes["HasPOIContact"] = "HasPOIContact";
})(SpaceHasIncludes || (SpaceHasIncludes = {}));
