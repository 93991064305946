import { has } from "lodash";
export function ObjectHasProperties(object) {
    for (var prop in object) {
        if (Object.prototype.hasOwnProperty.call(object, prop)) {
            return true;
        }
    }
    return false;
}
//Transform TO Lowercase Keys does take keys to any value.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function TransformToLowercaseKeys(obj) {
    var keys = Object.keys(obj);
    var newObj = {};
    for (var _i = 0, keys_1 = keys; _i < keys_1.length; _i++) {
        var key = keys_1[_i];
        newObj[key.toLowerCase()] = obj[key];
    }
    return newObj;
}
export function ObjectHasAllKeys(obj, propertyKeys) {
    for (var _i = 0, propertyKeys_1 = propertyKeys; _i < propertyKeys_1.length; _i++) {
        var propertyKey = propertyKeys_1[_i];
        if (!has(obj, propertyKey)) {
            return false;
        }
    }
    return true;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function groupBy(collection, property) {
    return collection.reduce(function (rv, x) {
        (rv[x[property]] = rv[x[property]] || []).push(x);
        return rv;
    }, {});
}
