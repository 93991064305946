import { IStyle, IStyleFunction } from "@fluentui/react";
import { ISBClientTheme } from "../../../theme-provider/Theme.Types";

export interface IPrivacyNoticeBaseProps {
    styles?: IStyleFunction<{}, IPrivacyNoticeStyles>;
    theme: ISBClientTheme;
}

export interface IPrivacyNoticeStyles {
    rootContainer: IStyle;
    modalContainer: IStyle;
    cancelIconButton: IStyle;
    noticeStyle: IStyle;
    urlStyle: IStyle;
}

export const dataPrivacyNotice = "https://go.microsoft.com/fwlink/?LinkId=518021";

export const noticeMessageKiosk =
    "Scan the QR code or navigate to the url below to review Microsoft's Data Privacy notice.";

export const noticeMessageWeb =
    "Scan the QR code or click on the link below to review Microsoft's Data Privacy notice.";

export const privacyNoticeButtonText = "Privacy";
