import { layer } from "azure-maps-control";
var iconSizeExpression = ["interpolate", ["linear"], ["zoom"], 17.5, 0.7, 21, 1.1];
var textSizeExpression = [
    "interpolate",
    ["linear"],
    ["zoom"],
    18,
    5,
    18.5,
    6.5,
    19,
    8,
    19.5,
    9.5,
    20,
    11
];
var textFieldExpression = [
    "format",
    ["get", "featureText"],
    "\n",
    {},
    ["get", "busynessStatus"],
    {
        "text-color": ["get", "busynessStatusColor"]
    }
];
var BusynessMapLayer = /** @class */ (function () {
    function BusynessMapLayer(map, datasource, logger) {
        var _this = this;
        this.map = map;
        this.datasource = datasource;
        this.logger = logger;
        this.iconBusynessLayer = new layer.SymbolLayer(this.datasource, "iconBusynessLayer", {
            filter: ["all", ["has", "busynessStatus"], ["has", "mapIcon"], ["==", ["get", "levelId"], ""]],
            minZoom: 17.5,
            iconOptions: {
                image: ["get", "mapIcon"],
                anchor: "bottom",
                size: iconSizeExpression
            },
            textOptions: {
                textField: textFieldExpression,
                anchor: "top",
                offset: [0, -0.1],
                font: ["SegoeFrutigerHelveticaMYingHei-Medium"],
                haloBlur: 0.5,
                haloWidth: 1,
                haloColor: "rgb(0, 0, 0)",
                size: textSizeExpression,
                color: "white"
            }
        });
        this.labelBusynessLayer = new layer.SymbolLayer(this.datasource, "labelBusynessLayer", {
            filter: ["all", ["has", "busynessStatus"], ["!", ["has", "mapIcon"]], ["==", ["get", "levelId"], ""]],
            minZoom: 17.5,
            iconOptions: { image: "none" },
            textOptions: {
                textField: textFieldExpression,
                anchor: "center",
                font: ["SegoeUi-SemiBold"],
                haloBlur: 0.5,
                haloWidth: 1,
                haloColor: "rgb(0, 0, 0)",
                size: textSizeExpression,
                color: "white"
            }
        });
        this.map.events.add("ready", function () {
            // adding the text letter spacing property not exposed by azure maps
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            _this.map._getMap().setLayoutProperty("iconBusynessLayer", "text-letter-spacing", 0.1);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            _this.map._getMap().setLayoutProperty("labelBusynessLayer", "text-letter-spacing", 0.1);
        });
        this.map.events.add("sourceadded", function (e) {
            if (e.getId() === "MapFeatures") {
                _this.logger.logEvent("[MapFeaturesDataSource] Datasource added to map");
            }
        });
        this.map.events.add("layeradded", function (e) {
            // TODO: swap menuPinTailLayer for pinTailLayer once implemention is complete.
            if (e.getId() === "menuPinTailLayer" && !_this.map.layers.getLayerById("iconBusynessLayer")) {
                _this.map.layers.add([_this.iconBusynessLayer, _this.labelBusynessLayer], "menuPinTailLayer");
                _this.logger.logEvent("[BusynessMapLayer] Busyness layer added to map");
            }
        });
    }
    BusynessMapLayer.prototype.setBusynessLayerFloor = function (levelId) {
        var _this = this;
        var filter = [
            "all",
            ["has", "busynessStatus"],
            ["has", "mapIcon"],
            ["==", ["get", "levelId"], levelId]
        ];
        var labelFilter = [
            "all",
            ["has", "busynessStatus"],
            ["!", ["has", "mapIcon"]],
            ["==", ["get", "levelId"], levelId]
        ];
        this.iconBusynessLayer.setOptions({
            filter: filter
        });
        this.labelBusynessLayer.setOptions({
            filter: labelFilter
        });
        this.map.events.addOnce("ready", function () {
            _this.map.layers.remove([_this.iconBusynessLayer, _this.labelBusynessLayer]);
            _this.map.layers.add([_this.iconBusynessLayer, _this.labelBusynessLayer], "menuPinTailLayer");
        });
    };
    BusynessMapLayer.prototype.hideBaseMapBusynessLayer = function () {
        this.iconBusynessLayer.setOptions({
            visible: false
        });
        this.labelBusynessLayer.setOptions({
            visible: false
        });
    };
    BusynessMapLayer.prototype.showBaseMapBusynessLayer = function () {
        this.iconBusynessLayer.setOptions({
            visible: true
        });
        this.labelBusynessLayer.setOptions({
            visible: true
        });
    };
    return BusynessMapLayer;
}());
export { BusynessMapLayer };
