import { IMenuPoiBaseProps, IMenuPoiStyleProps, IMenuPoiStyles } from "./MenuPoi.Types";
import React, { useContext } from "react";
import { getBuildingId, getFloorId, getMapLayers, getPointsOfInterest } from "../../../redux/Selectors";
import { ILogger } from "@smartbuilding/log-provider";
import { MenuPoiBase } from "./MenuPoi.Base";
import { ThemeContext } from "../../../theme-provider/ThemeProvider";
import { getMenuPoiStyles } from "./MenuPoi.Styles";
import { serviceIdentifiers } from "../../../serviceContainer/ServiceIdentifiers";
import { styled } from "@fluentui/react";
import { useInjection } from "../../../serviceContainer/ServiceContainerProvider";
import { useSelector } from "react-redux";

// eslint-disable-next-line @typescript-eslint/naming-convention
const StyledMenuPoi = styled<IMenuPoiBaseProps, IMenuPoiStyleProps, IMenuPoiStyles>(MenuPoiBase, getMenuPoiStyles);

export function MenuPoi(): JSX.Element {
    const logger = useInjection<ILogger>(serviceIdentifiers.logger);
    const theme = useContext(ThemeContext).theme;
    const mapLayers = useSelector(getMapLayers);
    const floorId = useSelector(getFloorId);
    const buildingId = useSelector(getBuildingId);
    const pointsOfInterest = useSelector(getPointsOfInterest);

    return (
        <StyledMenuPoi
            mapLayers={mapLayers}
            pointsOfInterest={pointsOfInterest}
            floorId={floorId}
            buildingId={buildingId}
            theme={theme}
            logger={logger}
        />
    );
}
