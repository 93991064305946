import { SpaceSubTypes } from "@smartbuilding/adt-v2-types";
var SpaceSearchService = /** @class */ (function () {
    function SpaceSearchService(logger, spaceRepo) {
        this.logger = logger;
        this.spaceRepo = spaceRepo;
    }
    SpaceSearchService.prototype.getAllBuildingsInARegion = function (regionId) {
        var _this = this;
        var filter = "regionId eq '" + regionId + "'";
        return this.spaceRepo
            .getSpaces(filter, undefined, SpaceSubTypes.Building)
            .then(function (response) {
            var buildingNames = [];
            response.forEach(function (building) {
                buildingNames.push(_this.appendBuildingKeyword(building.name));
            });
            return buildingNames;
        })
            .catch(function (err) {
            _this.logger.logError(new Error("[SearchService] Unable to get buildings for Search:" + err.message));
            new Error("Unable to get buildings");
            return Promise.reject(err);
        });
    };
    SpaceSearchService.prototype.appendBuildingKeyword = function (buildingName) {
        if (buildingName.match(/^\d/)) {
            return "Building " + buildingName;
        }
        else {
            return buildingName;
        }
    };
    return SpaceSearchService;
}());
export { SpaceSearchService };
