import { IMenuWeatherProps, weatherRegionTempMap } from "./MenuWeather.Types";
import React, { useEffect, useState } from "react";
import { WeatherClock, WeatherCondition } from "@smartbuilding/ui-components-weather-clock";
import { getRegion, getRegionMap } from "../../../redux/Selectors";
import { ICurrentWeatherConditionResponse } from "@smartbuilding/weather-service";
import { useSelector } from "react-redux";

export function MenuWeatherBase(props: IMenuWeatherProps): JSX.Element {
    const currentRegion = useSelector(getRegion);
    const regionsMap = useSelector(getRegionMap);
    const [currentWeatherCondition, setCurrentWeatherCondition] = useState<null | ICurrentWeatherConditionResponse>(
        null
    );
    const [intervalRef, setIntervalRef] = useState(0);

    const getCurrentWeatherCondition = React.useCallback((): Promise<ICurrentWeatherConditionResponse | null> => {
        return props.weatherServiceProvider().then((weatherService) => {
            return weatherService
                .getCurrentWeatherCondition(props.latitude, props.longitude)
                .then((weatherCondition: ICurrentWeatherConditionResponse | null) => {
                    return weatherCondition;
                })
                .catch(() => {
                    return null;
                });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.latitude, props.longitude]);

    useEffect(() => {
        if (props.latitude !== 0 && props.longitude !== 0) {
            getCurrentWeatherCondition().then((currentWeatherCondition) => {
                setCurrentWeatherCondition(currentWeatherCondition);
            });
            if (intervalRef) {
                clearInterval(intervalRef);
            }

            const newIntervalRef = window.setInterval(() => {
                getCurrentWeatherCondition().then((currentWeatherCondition) => {
                    setCurrentWeatherCondition(currentWeatherCondition);
                });
            }, 30 * 60000);

            setIntervalRef(newIntervalRef);
        }

        return () => {
            clearInterval(intervalRef);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getCurrentWeatherCondition, props.latitude, props.longitude]);

    const getWeatherCondition = (): WeatherCondition => {
        const weatherCondition =
            WeatherCondition[
                (currentWeatherCondition ? currentWeatherCondition.phrase : "No Weather") as WeatherCondition
            ];
        if (weatherCondition === undefined) {
            return WeatherCondition["No Weather"];
        } else {
            return weatherCondition;
        }
    };

    const getWeatherTemperature = (usingFahrenheit: boolean): number | undefined => {
        if (!currentWeatherCondition) return undefined;

        const isFahrenheitTemp = currentWeatherCondition.temperature.unitType === 18;

        if (isFahrenheitTemp && !usingFahrenheit) {
            const fTemp = currentWeatherCondition.temperature.value;
            const cTemp = ((fTemp - 32) * 5) / 9;
            return parseFloat(cTemp.toFixed(1));
        }

        if (!isFahrenheitTemp && usingFahrenheit) {
            const cTemp = currentWeatherCondition.temperature.value;
            const fTemp = (cTemp * 9) / 5 + 32;
            return parseFloat(fTemp.toFixed(1));
        }

        return currentWeatherCondition.temperature.value;
    };

    const usingFahrenheitOrNot = (): boolean => {
        if (currentRegion && regionsMap[currentRegion]?.name) {
            const regionName = regionsMap[currentRegion].name.toUpperCase();
            return weatherRegionTempMap[regionName];
        } else {
            return true;
        }
    };

    // use fahrenheit as default
    return (
        <WeatherClock
            weatherCondition={getWeatherCondition()}
            temperature={getWeatherTemperature(usingFahrenheitOrNot())}
            usingFahrenheit={usingFahrenheitOrNot()}
        />
    );
}
