import { ISearchResultItem, MenuSearchClickCallback } from "./MenuSearch.Types";
import { PeopleFilledIcon, PeopleOutlineIcon } from "@smartbuilding/ui-components-icons";
import { useEffect, useState } from "react";
import { getPeopleInBuilding } from "../../../redux/Selectors";
import { useSelector } from "react-redux";

export function usePeopleSearchResultOptions(onClickCallback: MenuSearchClickCallback): ISearchResultItem[] {
    const people = useSelector(getPeopleInBuilding);
    const [peopleList, setPeopleList] = useState<ISearchResultItem[]>([]);

    useEffect(() => {
        const list: ISearchResultItem[] = people.map((person) => {
            return {
                sortBy: person.displayName,
                filterBy: [
                    person.givenName ?? "",
                    person.surName ?? "",
                    person.displayName,
                    person.userPrincipalName.split("@")[0]
                ],
                itemData: {
                    key: person.dtId,
                    primaryText: person.displayName,
                    secondaryText: person.jobTitle,
                    icon: {
                        base: PeopleOutlineIcon,
                        hoverIcon: PeopleFilledIcon,
                        activeIcon: PeopleFilledIcon
                    },
                    onClick: () => {
                        onClickCallback(person.dtId, "Person");
                    }
                }
            };
        });

        setPeopleList(list);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [people]);

    return peopleList;
}
