import { FeatureCategory, VerticalPenetrationFeature } from "./FeatureCategories";
export function getFeatureWithSpaceId(map, spaceId) {
    var features = map.layers.getRenderedShapes(undefined, undefined, [
        "==",
        ["get", "originalId"],
        spaceId
    ]);
    return features.find(function (f) { var _a; return ((_a = f.properties) === null || _a === void 0 ? void 0 : _a.originalId) === spaceId; });
}
export function isVerticalPenetration(featureProperties) {
    return featureProperties.categoryName.startsWith(FeatureCategory.VerticalPenetration);
}
export function isStairVerticalPenetration(featureCategory) {
    return (featureCategory === VerticalPenetrationFeature.VerticalPenetrationStairs ||
        featureCategory === VerticalPenetrationFeature.VerticalPenetrationStairwell);
}
