import { AxiosRequestConfig, AxiosResponse } from "axios";
import { Container, inject, injectable } from "inversify";
import { IWebClientConfiguration, configurationBaseApiUrl } from "../constants";
import { Provider, serviceIdentifiers } from "../serviceContainer/ServiceIdentifiers";
import { Configuration } from "@azure/msal-browser";
import { IAuthClient } from "@smartbuilding/auth-client";
import { IConfigurationService } from "@smartbuilding/configuration-provider";
import { IHttpServiceInterceptors } from "@smartbuilding/smartbuilding-http-service";
import { IPointRAuthTokenService } from "@smartbuilding/directions-service";

@injectable()
export class HttpServiceInterceptor {
    private apiResourceMap: Record<string, string> | undefined = undefined;
    private dipUrl: string | undefined = undefined;
    private dipSubscriptionValue: string | undefined = undefined;
    private pointRAPIUrl: string | undefined = undefined;
    private readonly acceptedErrorStatusCodes = [404];

    public constructor(
        @inject(serviceIdentifiers.inversifyContainer) private container: Container,
        @inject(serviceIdentifiers.msalConfig) private msalConfig: Configuration,
        @inject(serviceIdentifiers.authClient) private authClient: IAuthClient,
        @inject(serviceIdentifiers.pointrAuthTokenService)
        private pointrAuthTokenServiceProvider: Provider<IPointRAuthTokenService>
    ) {}

    public getHttpServiceInterceptors(): IHttpServiceInterceptors {
        const serviceInterceptors: IHttpServiceInterceptors = {
            request: [
                {
                    onFulfilled: this.onFulfilledInterceptor.bind(this)
                }
            ],
            response: [
                {
                    onFulfilled: (res: AxiosResponse) => res,
                    onRejected: (err) => {
                        // Ignore error codes not critical to kiosk/client usage.
                        if (err.response?.status && this.acceptedErrorStatusCodes.includes(err.response.status)) {
                            return;
                        }

                        // Updating the store with an error will trigger the error boundary. Since some 400 and 500 status codes are acceptable,
                        // we will need to create a list of non-critical requests that can fail and just ignore them.
                        // store.dispatch(
                        //     setHttpError({
                        //         ...err,
                        //         type: getErrorType(err)
                        //     })
                        // );
                    }
                }
            ]
        };

        return serviceInterceptors;
    }

    private async onFulfilledInterceptor(config: AxiosRequestConfig): Promise<AxiosRequestConfig> {
        const configService = this.container.get<IConfigurationService<IWebClientConfiguration>>(
            serviceIdentifiers.configService
        );
        if (config.url) {
            if (!config.headers) {
                config.headers = {};
            }
            const token = await this.getToken(config.url);
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }

            if (config.url?.endsWith(configurationBaseApiUrl)) {
                return config;
            }

            if (!this.pointRAPIUrl) {
                const response = await configService.getSetting("PointRAPIUrl");
                this.pointRAPIUrl = response as string;
            }

            if (
                this.pointRAPIUrl?.trim().length &&
                config.url.includes(this.pointRAPIUrl) &&
                !config.url.endsWith("auth/token")
            ) {
                const pointrAuthTokenService = await this.pointrAuthTokenServiceProvider();
                const pointrToken = await pointrAuthTokenService.getPointrAuthTokenWithCache();
                config.headers.Authorization = `Bearer ${pointrToken}`;
                return config;
            }

            if (!this.dipUrl && !this.dipSubscriptionValue) {
                const response = await Promise.all([
                    configService.getSetting("DTDLUrl"),
                    configService.getSetting("DIPSubscriptionKey")
                ]);
                this.dipUrl = response[0] as string;
                this.dipSubscriptionValue = response[1] as string;
            }

            if (this.dipUrl && config.url?.includes("dip")) {
                if (this.dipSubscriptionValue) {
                    config.headers["Ocp-Apim-Subscription-key"] = this.dipSubscriptionValue;
                }
            }

            if (config.url?.includes("user/user")) {
                if (!this.dipSubscriptionValue) {
                    const response = await configService.getSetting("DIPSubscriptionKey");
                    this.dipSubscriptionValue = response as string;
                }
                config.headers["Dwp-Apim-Subscription-Key"] = this.dipSubscriptionValue;
            }
        }

        return config;
    }

    private getToken(url: string): Promise<string> {
        if (url.endsWith(configurationBaseApiUrl)) {
            return this.authClient.getToken(this.msalConfig.auth.clientId);
        } else {
            return this.getResourceMap().then((resourceMap) => {
                for (const endpoint of Object.keys(resourceMap)) {
                    if (url.startsWith(endpoint)) {
                        return this.authClient.getToken(resourceMap[endpoint]);
                    }
                }

                return "";
            });
        }
    }
    private getResourceMap(): Promise<Record<string, string>> {
        if (this.apiResourceMap) {
            Promise.resolve(this.apiResourceMap);
        }

        const configService = this.container.get<IConfigurationService<IWebClientConfiguration>>(
            serviceIdentifiers.configService
        );
        return configService.getSetting("AdalResourceMapping").then((resourceMap) => {
            const map = JSON.parse(resourceMap as string);
            this.apiResourceMap = map;
            return map;
        });
    }
}
