export enum BreakpointWidths {
    Wide = 960,
    Narrow = 480,
    Toggle = 800
}

export const isNarrowViewport = (): boolean => {
    const currentWidth = window.innerWidth;
    return currentWidth <= BreakpointWidths.Narrow;
};

export const isMediumViewport = (): boolean => {
    const currentWidth = window.innerWidth;
    return currentWidth <= BreakpointWidths.Wide && currentWidth > BreakpointWidths.Narrow;
};

export const isWideViewport = (): boolean => {
    const currentWidth = window.innerWidth;
    return currentWidth > BreakpointWidths.Wide;
};

export const isToggleViewport = (): boolean => {
    const currentWidth = window.innerWidth;
    return currentWidth < BreakpointWidths.Toggle;
};
