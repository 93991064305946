export type Provider<T> = () => Promise<T>;

export const appBootstrapperIdentifier = Symbol("AppBootstrapper");
export const mapObserverIdentifier = Symbol("MapErrorObserver");
export const appMetricsSagaIdentifier = Symbol("AppMetricsSaga");
export const authClientIdentifier = Symbol("AuthClient");
export const azureMapsTokenHttpIdentifier = Symbol("AzureMapsTokenHttp");
export const browserHistoryIdentifier = Symbol("BrowserHistory");
export const configServiceIdentifier = Symbol("ConfigurationService");
export const durableConnectionBuilderIdentifier = Symbol("DurableConnectionBuilder");
export const directionsServiceIdentifier = Symbol("DirectionsService");
export const directionsServiceHttpIdentifier = Symbol("DirectionsServiceHttp");
export const directionsSpaceRetrieverServiceIdentifier = Symbol("directionsSpaceRetrieverServiceIdentifier");
export const dipServiceIdentifier = Symbol("DIPServiceIdentifier");
export const dipToPointrMapperServiceIdentifier = Symbol("DipToPointRMapperServiceIdentifier");
export const featureFlightingServiceIdentifier = Symbol("FeatureFlightingService");
export const healthCheckIdentifier = Symbol("HealthCheckService");
export const hubConfigIdentifier = Symbol("HubConfig");
export const hubConnectionBuilderIdentifier = Symbol("HubConnectionBuilder");
export const httpServiceIdentifier = Symbol("HttpService");
export const httpServiceInterceptorIdentifier = Symbol("HttpServiceInterceptor");
export const loggerIdentifier = Symbol("Logger");
export const localBlobServiceIdentifier = Symbol("LocalBlobService");
export const spaceHttpIdentifier = Symbol("SpaceHttp");
export const spaceRepoIdentifier = Symbol("SpaceRepo");
export const sensorReaderIdentifier = Symbol("SensorReader");
export const sensorSubscriptionManager = Symbol("SensorSubscriptionManager");
export const spaceSubscriptionHttpIdentifier = Symbol("SpaceSubscriptionHttpService");
export const spaceSubscriptionServiceIdentifier = Symbol("SpaceSubscriptionService");
export const weatherServiceIdentifier = Symbol("WeatherService");
export const telemetryReactPluginIdentifier = Symbol("TelemetryReactPlugin");
export const timeoutServiceIdentifier = Symbol("TimeoutService");
export const roomCardServiceIdentifier = Symbol("RoomCardService");
export const roomFeatureServiceIdentifier = Symbol("RoomFeatureService");
export const poiDetailsService = Symbol("PoiDetailsService");
export const peopleServiceIdentifier = Symbol("PeopleService");
export const peopleRepoIdentifier = Symbol("PeopleRepo");
export const peopleSagaIdentifier = Symbol("PeopleSaga");
export const peopleHttpIdentifier = Symbol("PeopleHttp");
export const autoCompleteIdentifier = Symbol("AutoComplete");
export const pathFindingSagaIdentifier = Symbol("PathFindingSaga");
export const pointRAuthTokenHttpServiceIdentifier = Symbol("PointRAuthTokenHttp");
export const pointRAuthTokenServiceIdentifier = Symbol("PointRAuthTokenService");
export const pointRHttpWrapperServiceIdentifier = Symbol("PointRHttpWrapperServiceIdentifier");
export const pointRHttpServiceIdentifier = Symbol("PointRHttpServiceIdentifier");
export const inversifyContainerIdentfier = Symbol("Container");
export const rootSagaIdentifier = Symbol("RootSaga");
export const roomConverterServiceIdentifier = Symbol("RoomConverterServiceIdentifier");
export const mostSearchedService = Symbol("MostSearchService");
export const mapSagaIdentifier = Symbol("MapSaga");
export const myhubUserHttpIdentifier = Symbol("MyHubUserHttpService");
export const myhubUserServiceIdentifier = Symbol("MyHubUserService");
export const spaceSearchService = Symbol("SpaceSearchService");
export const spaceSaga = Symbol("SpaceSaga");
export const appSaga = Symbol("AppSaga");
export const smartBuildingApiRoomHttpServiceIdentifier = Symbol("SmartBuildingApiRoomHttpService");
export const smartBuildingApiHttpServiceIdentifier = Symbol("SmartBuildingApiHttpService");
export const badgeScanApiHttpServiceIdentifier = Symbol("BadgeScanApiHttpService");
export const spaceCategoryServiceIdnentifier = Symbol("SpaceCategoryServiceIdnentifier");
export const badgeScanServiceIdentifier = Symbol("BadgeScanServiceIdentifier");
export const deviceConfigSaga = Symbol("DeviceSaga");
export const userHttpServiceIdentifier = Symbol("UserHttpService");
export const userRepoIdentifier = Symbol("UserRepo");
export const userRingServiceIdentifier = Symbol("UserRingService");
export const localRoomImageServiceIdentifier = Symbol("LocalRoomImageService");
export const roomImageServiceIdentifier = Symbol("RoomImageService");
export const roomImageProviderIdentifier = Symbol("RoomImageProvider");
export const signalRSagaIdentifier = Symbol("SignalRSaga");
export const msalConfigIdentifier = Symbol("MsalConfig");
export const serviceIdentifiers = {
    appBootstrapper: appBootstrapperIdentifier,
    mapObserver: mapObserverIdentifier,
    msalConfig: msalConfigIdentifier,
    appMetricsSaga: appMetricsSagaIdentifier,
    authClient: authClientIdentifier,
    azureMapsTokenHttp: azureMapsTokenHttpIdentifier,
    browserHistory: browserHistoryIdentifier,
    configService: configServiceIdentifier,
    durableConnectionBuilder: durableConnectionBuilderIdentifier,
    directionsService: directionsServiceIdentifier,
    directionsServiceHttp: directionsServiceHttpIdentifier,
    directionsSpaceRetriever: directionsSpaceRetrieverServiceIdentifier,
    dipService: dipServiceIdentifier,
    dipToPointRMapper: dipToPointrMapperServiceIdentifier,
    featureFlightingService: featureFlightingServiceIdentifier,
    healthCheckService: healthCheckIdentifier,
    hubConfig: hubConfigIdentifier,
    hubConnectionBuilder: hubConnectionBuilderIdentifier,
    httpService: httpServiceIdentifier,
    httpServiceInterceptor: httpServiceInterceptorIdentifier,
    roomImageService: roomImageServiceIdentifier,
    roomImageProvider: roomImageProviderIdentifier,
    logger: loggerIdentifier,
    localBlobService: localBlobServiceIdentifier,
    spaceHttp: spaceHttpIdentifier,
    spaceRepo: spaceRepoIdentifier,
    sensorSubscription: sensorSubscriptionManager,
    sensorReader: sensorReaderIdentifier,
    spaceSubscriptionHttpService: spaceSubscriptionHttpIdentifier,
    spaceSubscriptionService: spaceSubscriptionServiceIdentifier,
    smartBuildingApiRoomHttpService: smartBuildingApiRoomHttpServiceIdentifier,
    smartBuildingApiHttp: smartBuildingApiHttpServiceIdentifier,
    badgeScanApiHttp: badgeScanApiHttpServiceIdentifier,
    peopleHttp: peopleHttpIdentifier,
    autoComplete: autoCompleteIdentifier,
    spaceCategoryService: spaceCategoryServiceIdnentifier,
    badgeScanServiceIdentifier: badgeScanServiceIdentifier,
    weatherService: weatherServiceIdentifier,
    timeoutService: timeoutServiceIdentifier,
    telemetryReactPlugin: telemetryReactPluginIdentifier,
    roomCardService: roomCardServiceIdentifier,
    routeConverter: roomConverterServiceIdentifier,
    inversifyContainer: inversifyContainerIdentfier,
    rootSaga: rootSagaIdentifier,
    mostSearchedService: mostSearchedService,
    mapSaga: mapSagaIdentifier,
    spaceSearchService: spaceSearchService,
    spaceSaga: spaceSaga,
    appSaga: appSaga,
    deviceSaga: deviceConfigSaga,
    poiDetailsService: poiDetailsService,
    peopleService: peopleServiceIdentifier,
    peopleRepo: peopleRepoIdentifier,
    peopleSaga: peopleSagaIdentifier,
    pointrAuthTokenHttp: pointRAuthTokenHttpServiceIdentifier,
    pointrAuthTokenService: pointRAuthTokenServiceIdentifier,
    pointrHttpWrapper: pointRHttpWrapperServiceIdentifier,
    pointrHttp: pointRHttpServiceIdentifier,
    pathFindingSaga: pathFindingSagaIdentifier,
    userHttpService: userHttpServiceIdentifier,
    userRepo: userRepoIdentifier,
    userRingService: userRingServiceIdentifier,
    roomFeatureService: roomFeatureServiceIdentifier,
    signalRSaga: signalRSagaIdentifier,
    myhubUserHttpService: myhubUserHttpIdentifier,
    myhubUserService: myhubUserServiceIdentifier
};
