var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var AuthClient = /** @class */ (function () {
    function AuthClient(client, logger) {
        this.client = client;
        this.logger = logger;
        this.serviceTag = "[AuthClient]";
    }
    AuthClient.prototype.initialize = function () {
        return __awaiter(this, void 0, void 0, function () {
            var error_1, e;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.client.initialize().then(function () {
                                try {
                                    _this.client.handleRedirectPromise().then(function (res) {
                                        if (res !== null) {
                                            _this.client.setActiveAccount(res.account);
                                        }
                                    });
                                }
                                catch (err) {
                                    var e = new Error(_this.serviceTag + " Failed to handleRedirectPromise due to: " + (err instanceof Error ? err.message : "unknown issue"));
                                    _this.logger.logError(e);
                                }
                            })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        e = new Error(this.serviceTag + " Failed to initialize due to: " + (error_1 instanceof Error ? error_1.message : "unknown issue"));
                        this.logger.logError(e);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AuthClient.prototype.loginUser = function () {
        return __awaiter(this, void 0, void 0, function () {
            var scopes, result, error_2, e, err_1, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        scopes = ["openid"];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 8]);
                        return [4 /*yield*/, this.client.ssoSilent({
                                account: this.client.getAllAccounts()[0],
                                scopes: scopes
                            })];
                    case 2:
                        result = _a.sent();
                        this.client.setActiveAccount(result.account);
                        this.client.loginRedirect({ scopes: scopes });
                        return [3 /*break*/, 8];
                    case 3:
                        error_2 = _a.sent();
                        e = new Error(this.serviceTag + " Failed to ssoSilent user due to: " + (error_2 instanceof Error ? error_2.message : "unknown issue"));
                        this.logger.logError(e);
                        _a.label = 4;
                    case 4:
                        _a.trys.push([4, 6, , 7]);
                        return [4 /*yield*/, this.client.loginRedirect({ scopes: scopes })];
                    case 5:
                        _a.sent();
                        return [3 /*break*/, 7];
                    case 6:
                        err_1 = _a.sent();
                        e_1 = new Error(this.serviceTag + " Failed to loginRedirect user due to: " + (err_1 instanceof Error ? err_1.message : "unknown issue"));
                        this.logger.logError(e_1);
                        return [3 /*break*/, 7];
                    case 7: return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    AuthClient.prototype.logoutUser = function () {
        var _this = this;
        return new Promise(function () {
            _this.client.logoutRedirect();
        });
    };
    AuthClient.prototype.getToken = function (resource) {
        return __awaiter(this, void 0, void 0, function () {
            var req;
            var _this = this;
            return __generator(this, function (_a) {
                req = [resource + "/.default"];
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        try {
                            var accounts = _this.client.getAllAccounts();
                            if (!accounts || accounts.length === 0)
                                _this.loginUser();
                            else {
                                _this.client
                                    .acquireTokenSilent({ scopes: req, account: accounts[0], prompt: "none" })
                                    .then(function (result) {
                                    if (result && result.accessToken) {
                                        resolve(result.accessToken);
                                    }
                                });
                            }
                        }
                        catch (error) {
                            var e = error instanceof Error
                                ? error
                                : new Error(_this.serviceTag + " Failed to acquire token silently; popup required to acquire token");
                            _this.logger.logError(e);
                            try {
                                _this.client.loginPopup({ scopes: req }).then(function (result) {
                                    if (result && result.accessToken) {
                                        resolve(result.accessToken);
                                    }
                                    else {
                                        reject(_this.serviceTag + " Failed to acquire token popup");
                                    }
                                });
                            }
                            catch (err) {
                                var e_2 = new Error(_this.serviceTag + " Failed to loginPop to acquire token due to: " + (err instanceof Error ? err.message : "unknown issue"));
                                _this.logger.logError(e_2);
                            }
                        }
                    })];
            });
        });
    };
    AuthClient.prototype.getUser = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var currentAccounts = _this.client.getAllAccounts();
            if (currentAccounts && currentAccounts.length >= 1) {
                resolve(currentAccounts[0].username);
            }
            else {
                reject(_this.serviceTag + " No active account found");
            }
        });
    };
    AuthClient.prototype.getName = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var currentAccounts = _this.client.getAllAccounts();
            if (currentAccounts && currentAccounts.length >= 1) {
                resolve(currentAccounts[0].name);
            }
            else {
                reject(_this.serviceTag + " No active account found");
            }
        });
    };
    return AuthClient;
}());
export { AuthClient };
