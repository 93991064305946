import { applyMiddleware, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { rootReducer } from "./Reducers/RootReducer";
import { setSagaError } from "./Actions/ErrorActionCreator";

export const sagaMiddleware = createSagaMiddleware({
    onError: (error: Error) => {
        store.dispatch(
            setSagaError({
                message: error.message,
                stack: error.stack
            })
        );
    }
});

export const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
