export var MapLabelLayerIds;
(function (MapLabelLayerIds) {
    MapLabelLayerIds["IndoorLabels"] = "microsoft.maps.indoor.labels_indoor.indoor_global_unit_label";
})(MapLabelLayerIds || (MapLabelLayerIds = {}));
// eslint-disable-next-line @typescript-eslint/naming-convention
export var MapIconLayerIds = {
    Atrium: "microsoft.maps.indoor.labels_indoor.indoor_unit_atrium_label",
    BathRoom: "microsoft.maps.indoor.labels_indoor.indoor_unit_restroom_label",
    CableRoom: "microsoft.maps.indoor.labels_indoor.indoor_unit_utility_label",
    CopyRoom: "microsoft.maps.indoor.labels_indoor.indoor_pointElement_copier_label",
    ConferenceRoom: "microsoft.maps.indoor.labels_indoor.indoor_unit_conference_label",
    Elevator: "microsoft.maps.indoor.labels_indoor.indoor_verticalPenetration_elevator_label",
    FocusRoom: "microsoft.maps.indoor.labels_indoor.indoor_unit_focus_label",
    GymFitnessCenter: "microsoft.maps.indoor.labels_indoor.indoor_unit_gym_label",
    Hallway: "microsoft.maps.indoor.labels_indoor.indoor_unit_circulation_label",
    JanitorCloset: "microsoft.maps.indoor.labels_indoor.indoor_unit_closet_label",
    Kitchen: "microsoft.maps.indoor.labels_indoor.indoor_unit_kitchen_label",
    KitchenetteVending: "microsoft.maps.indoor.labels_indoor.indoor_unit_vending_label",
    Lab: "microsoft.maps.indoor.labels_indoor.indoor_unit_lab_label",
    Library: "microsoft.maps.indoor.labels_indoor.indoor_unit_library_label",
    LoadingReceiving: "microsoft.maps.indoor.labels_indoor.indoor_unit_loading_label",
    LobbyReception: "microsoft.maps.indoor.labels_indoor.indoor_unit_lobby_label",
    Lounge: "microsoft.maps.indoor.labels_indoor.indoor_unit_lounge_label",
    MicrosoftStore: "microsoft.maps.indoor.labels_indoor.indoor_unit_retail_label",
    MothersRoom: "microsoft.maps.indoor.labels_indoor.indoor_unit_newMother_label",
    MultiPurposeRoom: "microsoft.maps.indoor.labels_indoor.indoor_unit_multiPurpose_label",
    RecreationGaming: "microsoft.maps.indoor.labels_indoor.indoor_unit_game_label",
    ShowerLockerRoom: "microsoft.maps.indoor.labels_indoor.indoor_unit_locker_label",
    Stairwell: "microsoft.maps.indoor.labels_indoor.indoor_verticalPenetration_stairs_label",
    TeamOffice: "microsoft.maps.indoor.labels_indoor.indoor_unit_office_label"
};
//These icons don't correspond to a room, if it comes up somewhere, add it to the record under the relevant room type
/*
"microsoft.maps.indoor.labels_indoor.indoor_unit_classroom_label",
"microsoft.maps.indoor.labels_indoor.indoor_unit_dataCenter_label",
"microsoft.maps.indoor.labels_indoor.indoor_unit_demo_label",
"microsoft.maps.indoor.labels_indoor.indoor_unit_documents_label",
"microsoft.maps.indoor.labels_indoor.indoor_unit_foyer_label",
"microsoft.maps.indoor.labels_indoor.indoor_unit_makerSpace_label",
"microsoft.maps.indoor.labels_indoor.indoor_unit_interview_label",
"microsoft.maps.indoor.labels_indoor.indoor_vertical_penetration_elevator_label",
"microsoft.maps.indoor.labels_indoor.indoor_vertical_penetration_stairs_label",
"microsoft.maps.indoor.labels_indoor.indoor_zone_wifi_label",
"microsoft.maps.indoor.labels_indoor.indoor_pointElement_extinguisher_label",
"microsoft.maps.indoor.labels_indoor.indoor_pointElement_wiFiAp_label",
"microsoft.maps.indoor.labels_indoor.indoor_pointElement_printer_label"
*/
