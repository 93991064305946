/**
 * Enumeration of feature experiments that are currently running
 */
export var FeatureFlagNames;
(function (FeatureFlagNames) {
    FeatureFlagNames["EnablePathfindingQRCode"] = "EnablePathfindingQRCode";
    FeatureFlagNames["PointREnabled"] = "PointREnabled";
    FeatureFlagNames["Theme"] = "Theme";
    FeatureFlagNames["ShowMap"] = "ShowMap";
    FeatureFlagNames["SensorLayer"] = "SensorLayer";
    FeatureFlagNames["BadgeScanEnabled"] = "BadgeScanEnabled";
    FeatureFlagNames["PinAnimations"] = "PinAnimations";
    FeatureFlagNames["PeopleFeatureDisabled"] = "PeopleFeatureDisabled";
    FeatureFlagNames["OCVEnabled"] = "OCVEnabled";
    FeatureFlagNames["PeopleDensityEnabled"] = "PeopleDensityEnabled";
    FeatureFlagNames["RoomTagEnabled"] = "RoomTagEnabled";
    FeatureFlagNames["InstantBookingEnabled"] = "InstantBookingEnabled";
})(FeatureFlagNames || (FeatureFlagNames = {}));
