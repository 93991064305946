export var SpaceQueryKey;
(function (SpaceQueryKey) {
    SpaceQueryKey["Ids"] = "ids";
    SpaceQueryKey["MinRelative"] = "minrelative";
    SpaceQueryKey["MaxRelative"] = "maxrelative";
    SpaceQueryKey["MinLevel"] = "minlevel";
    SpaceQueryKey["MaxLevel"] = "maxlevel";
    SpaceQueryKey["Traverse"] = "traverse";
    SpaceQueryKey["Types"] = "types";
    SpaceQueryKey["Subtypes"] = "subtypes";
    SpaceQueryKey["Includes"] = "includes";
    SpaceQueryKey["SpaceId"] = "spaceid";
    SpaceQueryKey["Statuses"] = "statuses";
    SpaceQueryKey["Depth"] = "depth";
})(SpaceQueryKey || (SpaceQueryKey = {}));
