import { IMobileHandOffData } from "@smartbuilding/myhub-api-service";
import { IRouteData } from "@smartbuilding/azure-maps";
import { TraversalSpaceSubtype } from "@smartbuilding/directions-service";

export interface IPathMetadata {
    routeData: IRouteData | null;
    error?: boolean;
    errorCode?: string;
    retrieving?: boolean;
    pathfindingLoggerID?: string;
    routeRenderStartTime?: number;
    qrCodeParams?: IMobileHandOffData;
}

export interface IPathRequest {
    destSpaceId: string;
    srcSpaceId?: string;
    traversalType: TraversalSpaceSubtype;
    pathfindingLoggerID?: string;
    routeRenderStartTime?: number;
}

export interface IPathFindingStore {
    // Mapping of <key, IRoute Data> --> key is generated through the helper function generatePathMetadataKey below
    pathMetadata: Record<string, IPathMetadata>;
    wayFindingSupportedStatus: Record<string, boolean>; // Mapping of <floorId, IsWayFindingSupportedStatus>
    selectedPath?: IPathRequest;
    mobileHandOffMap: Record<string, string>; //Mapping of <key, url>
}

export function generatePathMetadataKey(request: IPathRequest): string {
    const { destSpaceId, srcSpaceId, traversalType } = request;
    let key = `${traversalType}-${destSpaceId}`;

    if (srcSpaceId) {
        key += `-${srcSpaceId}`;
    } else {
        key += `-default-location`;
    }

    return key;
}

export function generateMyHubUrlMapKey(data: IMobileHandOffData): string {
    return `${data.traversalSpaceSubType}-${data.destinationSpaceId}-${data.sourceSpaceId}`;
}
