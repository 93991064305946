export function KeepPromise<T>(promiseFunc: () => Promise<T>): () => Promise<T> {
    let promiseCache: Promise<T> | undefined;
    return () => {
        if (promiseCache) {
            return promiseCache;
        } else {
            promiseCache = promiseFunc();
            return promiseCache;
        }
    };
}
