import * as SBClientLightColorPalette from "./LightThemeColorPalette";
import { ICustomizations, createTheme } from "office-ui-fabric-react";
import { ISBClientSemanticColors } from "./Theme.Types";

const p = SBClientLightColorPalette;

const extendedSemanticColors: Partial<ISBClientSemanticColors> = {
    ////////////////////////////////
    ///   Base mapping changes
    ////////////////////////////////
    bodyTextChecked: p.neutralDark,
    disabledSubtext: p.neutralQuaternary,
    disabledBodySubtext: p.neutralTertiaryAlt,
    primaryButtonTextDisabled: p.neutralTertiary,
    bodyStandoutBackground: p.neutralLighter,
    buttonBackground: p.neutralLight,
    buttonBackgroundHovered: p.neutralTertiaryAlt,
    buttonBackgroundPressed: p.neutralTertiary,
    buttonTextHovered: p.neutralPrimary,
    buttonTextChecked: p.neutralSecondary,
    buttonTextPressed: p.neutralPrimary,
    buttonTextDisabled: p.neutralTertiary,
    menuIcon: p.neutralPrimary,
    menuHeader: p.neutralPrimary,
    listItemBackgroundChecked: p.neutralQuaternaryAlt,
    listItemBackgroundCheckedHovered: p.neutralQuaternary,
    link: p.themeDarkAlt,

    // Errors and warnings
    errorText: p.errorText,
    warningHighlight: p.warningHighlight,
    warningText: p.neutralPrimary,

    // Message bar colors
    messageText: p.neutralPrimary,
    messageLink: p.themeDarkAlt,
    messageLinkHovered: p.themeDarker,

    infoIcon: p.neutralSecondary,
    errorIcon: p.highStatus,
    blockingIcon: p.highStatus,
    successIcon: p.goodStatus,
    warningIcon: p.neutralPrimary,
    severeWarningIcon: p.severeWarningIcon,

    infoBackground: p.neutralLighter,
    errorBackground: p.errorBackground,
    blockingBackground: p.blockingBackground,
    warningBackground: p.warningBackground,
    severeWarningBackground: p.severeWarningBackground,
    successBackground: p.successBackground,

    ////////////////////////////////
    // Extended colors
    ////////////////////////////////

    dashboardBackdrop: p.dashboardBackdrop,
    panelBackground: p.white, // same as bodyBackground in this theme
    overlayBackground: p.overlayBackground,
    nonOverlayBackground: p.nonOverlayBackground,

    // Divider lines
    sectionDividerLine: p.neutralPrimary,
    primaryDividerLine: p.neutralTertiaryAlt,
    secondaryDividerLine: p.neutralQuaternaryAlt,

    // Text
    headerText: p.black,

    // Nav
    navBackground: p.navBackground,
    navItemBackgroundHovered: p.neutralQuaternary,
    navItemBackgroundPressed: p.neutralTertiaryAlt,

    // Status
    criticalStatus: p.criticalStatus,
    highStatus: p.highStatus,
    mediumStatus: p.mediumStatus,
    lowStatus: p.lowStatus,
    readyStatus: p.readyStatus,
    goodStatus: p.goodStatus,
    unkownStatus: p.unkownStatus,

    // Steps
    stepCompleted: p.themePrimary,
    stepCurrent: p.themePrimary,
    stepNotStarted: p.unkownStatus,
    stepModifierBorder: p.themePrimary,
    stepHover: p.themeDark,
    stepPressed: p.themeDarker,
    stepError: p.highStatus,
    allStepsComplete: p.readyStatus,

    contextualAlertBackground: p.contextualAlertBackground,

    // Help + Feedback
    needHelpForeground: p.white,
    needHelpBackground: p.needHelpBackground,
    needHelpBackgroundPressed: p.needHelpBackgroundPressed,
    feedbackForeground: p.white,
    feedbackBackground: p.neutralDark,
    feedbackBackgroundPressed: p.neutralSecondary,

    // Because the Fabric controls bank on theme.palette.white/black inverting.
    alwaysWhite: p.white,
    alwaysBlack: p.black,

    // fonts
    segoeUI: p.segoeUI,

    // spaces color
    hallwayColor: p.lightIvoryColor,
    wallAndBordersColor: p.neutralDark,
    deskColor: p.neutralLighter
};

export const sbClientLightColorTheme: ICustomizations = {
    settings: {
        theme: createTheme({
            palette: {
                themePrimary: p.themePrimary,
                themeLighterAlt: p.themeLighterAlt,
                themeLighter: p.themeLighter,
                themeLight: p.themeLight,
                themeTertiary: p.themeTertiary,
                themeSecondary: p.themeSecondary,
                themeDarkAlt: p.themeDarkAlt,
                themeDark: p.themeDark,
                themeDarker: p.themeDarker,
                neutralLighterAlt: p.neutralLighterAlt,
                neutralLighter: p.neutralLighter,
                neutralLight: p.neutralLight,
                neutralQuaternaryAlt: p.neutralQuaternaryAlt,
                neutralQuaternary: p.neutralQuaternary,
                neutralTertiaryAlt: p.neutralTertiaryAlt,
                neutralTertiary: p.neutralTertiary,
                neutralSecondary: p.neutralSecondary,
                neutralPrimaryAlt: p.neutralPrimaryAlt,
                neutralPrimary: p.neutralPrimary,
                neutralDark: p.neutralDark,
                black: p.black,
                white: p.white,
                whiteTranslucent40: p.whiteTranslucent40
            },
            semanticColors: extendedSemanticColors,
            isInverted: true
        })
    },
    scopedSettings: {}
};

export const sbClientLightCustomizations: ICustomizations = sbClientLightColorTheme;
/*
 // Adding more specific styles, create a styles class called SBClientStyles and uncomment below lines

export const sbClientLightCustomizationsWithStyles: ICustomizations = {
  settings: sbClientLightColorTheme.settings,
  scopedSettings: SBClientStyles
};
*/
