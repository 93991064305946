import { data as atlasData, layer as atlasLayer, source as atlasSource } from "azure-maps-control";
import { SBSBuildingClusteringLayer, SBSCustomOutdoorMapIcons } from "../CustomMapLayer.Types";
import { getTextOptionColors } from "../../Utilities/MapStyles";
import { mapImageSpriteCreator } from "../../MapUtilities/MapImageSpriteManager";
import { markerClusterBuilding } from "../../Assets/Outdoor/MarkerClusterBuildings";
import { markerOutdoorBuilding } from "../../Assets/Outdoor/MarkerOutdoorBuilding";
import { markerOutdoorBuildingPlusCafe } from "../../Assets/Outdoor/MarkerOutdoorBuildingPlusCafe";
var BuildingOverlayMapLayer = /** @class */ (function () {
    function BuildingOverlayMapLayer(map, buildingData, onBuildingClick, telemetryCallback) {
        var _this = this;
        this.map = map;
        this.buildingData = buildingData;
        this.onBuildingClick = onBuildingClick;
        this.telemetryCallback = telemetryCallback;
        this.clusterClicked = function (e) {
            var _a;
            if (e && e.shapes && e.shapes.length > 0) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                var cluster = e.shapes[0];
                var clusterPosition_1 = cluster.geometry.coordinates;
                if (cluster && cluster.id) {
                    (_a = _this.datasource) === null || _a === void 0 ? void 0 : _a.getClusterExpansionZoom(cluster.id).then(function (zoom) {
                        _this.map.setCamera({
                            center: clusterPosition_1,
                            zoom: zoom,
                            type: "ease",
                            duration: 200
                        });
                    });
                }
            }
        };
        this.clickBuildingMarker = function (e) {
            if (e && e.shapes && e.shapes.length > 0) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                var clickedElem = e.shapes[0];
                var buildingName = clickedElem.data.properties.buildingName;
                if (buildingName) {
                    _this.onBuildingClick(buildingName);
                }
            }
        };
        if (buildingData.length <= 0) {
            return;
        }
        this.map.events.add("ready", function () {
            _this.CreateBuildingOverlayLayer();
        });
    }
    BuildingOverlayMapLayer.prototype.CreateBuildingOverlayLayer = function () {
        var _this = this;
        var startTime = performance.now();
        var colors = getTextOptionColors(this.map);
        var buildingFeature = [];
        if (this.buildingData) {
            for (var _i = 0, _a = this.buildingData; _i < _a.length; _i++) {
                var building = _a[_i];
                var position = new atlasData.Position(building.location.lng, building.location.lat);
                buildingFeature.push(new atlasData.Feature(new atlasData.Point(position), {
                    buildingName: building.buildingName,
                    hasCafe: building.hasCafe ? "true" : "false"
                }));
            }
        }
        // Storing datasource to then clean up in the dispose function
        this.datasource = new atlasSource.DataSource(SBSBuildingClusteringLayer.BuildingLayer, {
            cluster: true,
            // Cluster Radius set to prevent overlapping of cluster labels
            clusterRadius: 80,
            clusterProperties: {
                cafeCount: ["+", ["case", ["==", ["get", "hasCafe"], "true"], 1, 0]]
            }
        });
        this.datasource.add(buildingFeature);
        this.map.sources.add(this.datasource);
        if (buildingFeature.length > 0) {
            var markerSpriteList = [
                mapImageSpriteCreator.createImageSprite(this.map, SBSCustomOutdoorMapIcons.BuildingMarkerIcon, markerOutdoorBuilding),
                mapImageSpriteCreator.createImageSprite(this.map, SBSCustomOutdoorMapIcons.ClusterMarkerIcon, markerClusterBuilding),
                mapImageSpriteCreator.createImageSprite(this.map, SBSCustomOutdoorMapIcons.BuildingCafeIcon, markerOutdoorBuildingPlusCafe)
            ];
            Promise.all(markerSpriteList).then(function () {
                var _a;
                if (!_this.datasource)
                    return;
                _this.buildingMarkerSymbolLayer = new atlasLayer.SymbolLayer(_this.datasource, SBSBuildingClusteringLayer.BuildingMarkerLayer, {
                    iconOptions: {
                        image: [
                            "match",
                            ["get", "hasCafe"],
                            "true",
                            SBSCustomOutdoorMapIcons.BuildingCafeIcon,
                            SBSCustomOutdoorMapIcons.BuildingMarkerIcon
                        ],
                        allowOverlap: true
                    },
                    filter: ["!", ["has", "point_count"]]
                });
                var buildingLabelSymbolLayer = new atlasLayer.SymbolLayer(_this.datasource, SBSBuildingClusteringLayer.BuildingLabelLayer, {
                    iconOptions: {
                        image: "none"
                    },
                    textOptions: {
                        textField: ["get", "buildingName"],
                        font: ["SegoeUi-Regular"],
                        size: 10,
                        color: colors.font,
                        offset: [0, 1.2],
                        allowOverlap: true,
                        haloWidth: 1,
                        haloColor: colors.halo
                    },
                    filter: ["!", ["has", "point_count"]]
                });
                var clusterMarkerSymbolLayer = new atlasLayer.SymbolLayer(_this.datasource, SBSBuildingClusteringLayer.ClusterMarkerLayer, {
                    iconOptions: {
                        image: SBSCustomOutdoorMapIcons.ClusterMarkerIcon,
                        allowOverlap: true
                    },
                    textOptions: {
                        textField: ["get", "point_count_abbreviated"],
                        offset: [0, -1.4],
                        font: ["SegoeUi-Bold"],
                        size: 14,
                        color: "#FFFFFF",
                        allowOverlap: false
                    },
                    filter: ["has", "point_count"]
                });
                var clusterLabelSymbolLayer = new atlasLayer.SymbolLayer(_this.datasource, SBSBuildingClusteringLayer.ClusterLabelLayer, {
                    iconOptions: {
                        image: "none",
                        allowOverlap: true
                    },
                    textOptions: {
                        textField: [
                            "concat",
                            ["to-string", ["get", "point_count_abbreviated"]],
                            " Microsoft \nbuildings",
                            [
                                "case",
                                ["==", ["get", "cafeCount"], 1],
                                ["concat", " & ", ["to-string", ["get", "cafeCount"]], "\ncafe"],
                                [">", ["get", "cafeCount"], 1],
                                ["concat", " & ", ["to-string", ["get", "cafeCount"]], "\ncafes"],
                                ""
                            ]
                        ],
                        offset: [
                            "case",
                            [">", ["get", "cafeCount"], 0],
                            ["literal", [0, 1.6]],
                            ["literal", [0, 1.2]]
                        ],
                        font: ["SegoeUi-Regular"],
                        size: 10,
                        color: colors.font,
                        allowOverlap: false,
                        haloWidth: 1,
                        haloColor: colors.halo
                    },
                    filter: ["has", "point_count"]
                });
                _this.map.layers.add([
                    clusterMarkerSymbolLayer,
                    clusterLabelSymbolLayer,
                    _this.buildingMarkerSymbolLayer,
                    buildingLabelSymbolLayer
                ]);
                _this.map.events.add("click", clusterMarkerSymbolLayer, _this.clusterClicked);
                _this.map.events.add("click", _this.buildingMarkerSymbolLayer, _this.clickBuildingMarker);
                var endTime = performance.now();
                var timeInMs = endTime - startTime;
                (_a = _this.telemetryCallback) === null || _a === void 0 ? void 0 : _a.call(_this, {
                    messageName: "[BuildingMarkers] Layer Created",
                    timeInMs: timeInMs,
                    loadEndTime: endTime,
                    epochTimeMs: Date.now()
                });
            });
        }
    };
    BuildingOverlayMapLayer.prototype.dispose = function () {
        this.map.events.remove("click", this.buildingMarkerSymbolLayer, this.clickBuildingMarker);
        // Cleaning up previous layers and datasources
        for (var layer in SBSBuildingClusteringLayer) {
            if (this.map.layers.getLayerById(layer)) {
                this.map.layers.remove(layer);
            }
        }
        if (this.datasource)
            this.map.sources.remove(this.datasource);
    };
    BuildingOverlayMapLayer.prototype.updateBuildingOverlayStyle = function () {
        var colors = getTextOptionColors(this.map);
        var clusterLabelLayer = this.map.layers.getLayerById(SBSBuildingClusteringLayer.ClusterLabelLayer);
        var buildingLabelLayer = this.map.layers.getLayerById(SBSBuildingClusteringLayer.BuildingLabelLayer);
        clusterLabelLayer.setOptions({ textOptions: { color: colors.font, haloColor: colors.halo } });
        buildingLabelLayer.setOptions({ textOptions: { color: colors.font, haloColor: colors.halo } });
    };
    BuildingOverlayMapLayer.prototype.toggleBuildingOverlayVisibility = function (isVisible) {
        for (var layer in SBSBuildingClusteringLayer) {
            var mapLayer = this.map.layers.getLayerById(layer);
            if (mapLayer) {
                mapLayer.setOptions({ visible: isVisible });
            }
        }
    };
    return BuildingOverlayMapLayer;
}());
export { BuildingOverlayMapLayer };
