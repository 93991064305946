import { Guid, convertStringToHash } from "@smartbuilding/utilities";
import { clearPath, clearRouteLayer, isWayFindingSupported, renderRouteLayer, selectPath } from "../../redux/Actions";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DirectionsErrorMessages } from "../../constants/error-constant";
import { IAuthClient } from "@smartbuilding/auth-client";
import { ILogger } from "@smartbuilding/log-provider";
import { IPathMetadata } from "../../redux/Types";
import { PageStatus } from "@smartbuilding/ui-components-space-card";
import { TraversalSpaceSubtype } from "@smartbuilding/directions-service";
import { getRoomBookedStatus } from "../../redux/Selectors/RoomBookedSelector";
import { getWayfindingSupportedStatusMap } from "../../redux/Selectors";

interface IUseDirectionController {
    /**
     * Flag to determine if way finding is supported for this floor
     */
    wayFindingSupported: boolean;
    /**
     * Flag to determine if useElevator is checked
     */
    useElevator: boolean;
    /**
     * Callback function for use elevator radio button
     */
    onUseElevatorValueChange: VoidFunction;
    /**
     * Callback function for direction button
     */
    onDirectionClick: VoidFunction;
    /**
     * Callback function to reset direction button
     */
    resetDirectionButton: VoidFunction;
    /**
     * Error message to display on error in rendering directions
     */
    directionsErrorMessage: string;
}

export function useDirectionController(
    destinationSpaceId: string,
    floorId: string,
    logger: ILogger,
    loggerSourceFileTitle: string,
    authClient: IAuthClient,
    isElectron: boolean,
    setPageStatus: React.Dispatch<React.SetStateAction<PageStatus>>,
    pathMetadata?: IPathMetadata,
    sourceSpaceId?: string
): IUseDirectionController {
    const userId = useRef("");
    const pathfindingLoggerID = useRef(Guid.newGuid().toString());
    const wayFindingSupportedStatus = useSelector(getWayfindingSupportedStatusMap);
    const [useElevator, setUseElevator] = useState(false);
    const dispatch = useDispatch();
    const [directionsErrorMessage, setDirectionsErrorMessage] = useState("");
    const isRoomBooked = useSelector(getRoomBookedStatus);
    useEffect(() => {
        if (floorId) {
            dispatch(isWayFindingSupported(floorId));
        } else {
            logger.logError(new Error(`[${loggerSourceFileTitle} Direction Controller] Floor ID is not available`));
        }
    }, [floorId, dispatch, logger, loggerSourceFileTitle]);

    useEffect(() => {
        setDirectionsErrorMessage("");
    }, [sourceSpaceId, destinationSpaceId]);

    useEffect(() => {
        authClient.getUser().then((user) => {
            userId.current = isElectron ? user : convertStringToHash(user).toString();
        });

        return () => {
            dispatch(clearRouteLayer());
            dispatch(clearPath());
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (pathMetadata?.routeData) {
            dispatch(clearPath());
            dispatch(
                selectPath({
                    destSpaceId: destinationSpaceId,
                    srcSpaceId: sourceSpaceId,
                    traversalType: useElevator
                        ? TraversalSpaceSubtype.ElevatorSpot
                        : TraversalSpaceSubtype.StairwellArea,
                    pathfindingLoggerID: pathMetadata.pathfindingLoggerID,
                    routeRenderStartTime: pathMetadata.routeRenderStartTime
                })
            );
            dispatch(renderRouteLayer());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [useElevator]);

    useEffect(() => {
        if (pathMetadata?.error && pathMetadata.pathfindingLoggerID) {
            let errorMessage = "";
            if (pathMetadata.errorCode === "400.700.000.000") {
                errorMessage = DirectionsErrorMessages.SameStartAndDestinationError;
            } else {
                errorMessage = DirectionsErrorMessages.FailedRouteError;
            }
            setDirectionsErrorMessage(errorMessage);
            setPageStatus(PageStatus.Default);
            logger.logError(
                new Error(
                    `[${loggerSourceFileTitle} Direction Controller] Failed to generate route for the selected room with pathfindingLoggerID: ${pathMetadata.pathfindingLoggerID}`
                )
            );
        } else {
            setDirectionsErrorMessage("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathMetadata]);

    useEffect(() => {
        if (pathMetadata?.retrieving) {
            setPageStatus(PageStatus.DirectionsRetrieving);
        } else if (isRoomBooked && pathMetadata?.routeData) {
            setPageStatus(PageStatus.BookingCancel);
        } else if (wayFindingSupportedStatus[floorId] && pathMetadata?.routeData) {
            setPageStatus(PageStatus.DirectionsEnd);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wayFindingSupportedStatus, floorId, pathMetadata]);

    const logEvent = useCallback(
        (message: string, pathfindingLoggerID: string) => {
            logger.logEvent(`[${loggerSourceFileTitle} Direction Controller] ${message}`, {
                destination: destinationSpaceId,
                sourceId: sourceSpaceId ?? "",
                traversalType: useElevator ? TraversalSpaceSubtype.ElevatorSpot : TraversalSpaceSubtype.StairwellArea,
                userId: userId.current,
                pathfindingLoggerID: pathfindingLoggerID
            });
        },
        [logger, loggerSourceFileTitle, destinationSpaceId, sourceSpaceId, useElevator]
    );

    const onUseElevatorValueChange = (): void => {
        setUseElevator(!useElevator);
        logEvent(`Use elevator click ${!useElevator}`, pathfindingLoggerID.current);
    };

    const endGettingDirections = (): void => {
        dispatch(clearRouteLayer());
        dispatch(clearPath());
        if (isRoomBooked) {
            setPageStatus(PageStatus.BookingCancel);
        } else {
            setPageStatus(PageStatus.Default);
        }
        setUseElevator(false);
    };

    const onDirectionClick = (): void => {
        if (!pathMetadata?.routeData) {
            const routeRenderStartTime = performance.now();
            dispatch(
                selectPath({
                    destSpaceId: destinationSpaceId,
                    srcSpaceId: sourceSpaceId,
                    traversalType: useElevator
                        ? TraversalSpaceSubtype.ElevatorSpot
                        : TraversalSpaceSubtype.StairwellArea,
                    pathfindingLoggerID: pathfindingLoggerID.current,
                    routeRenderStartTime: routeRenderStartTime
                })
            );
            dispatch(renderRouteLayer());
            logEvent("Directions click", pathfindingLoggerID.current);
        } else {
            logEvent("Exit Way Finding Click", pathfindingLoggerID.current);
            endGettingDirections();
        }
    };

    return {
        wayFindingSupported: wayFindingSupportedStatus[floorId],
        useElevator,
        onUseElevatorValueChange,
        onDirectionClick,
        resetDirectionButton: endGettingDirections,
        directionsErrorMessage
    };
}
