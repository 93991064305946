import {
    AccessibleOutlineIcon,
    AudioOutlineIcon,
    LargeScreenOutlineIcon,
    PeopleOutlineIcon,
    ProjectorOutlineIcon,
    SurfaceHubOutlineIcon,
    SvgIcon,
    VideoOutlineIcon,
    WhiteboardOutlineIcon
} from "@smartbuilding/ui-components-icons";
import {
    BlindsOutlineIcon,
    OutletsOutlineIcon,
    PodiumOutlineIcon,
    ProjectorScreenOutlineIcon,
    SofaOutlineIcon,
    SpeakersOutlineIcon,
    WebcamOutlineIcon,
    WindowOutlineIcon
} from "../../../Icons/RoomTags/index";
import { IPerson } from "@smartbuilding/people-service";
import { IRoomInfo } from "../../redux/Types";

export interface IDetailsPanelCardBaseProps {
    /**
     * Callback function triggered by back click button to remove latest pageInfo from details panel page stack
     */
    onPanelBackClickCallback: VoidFunction;
    /**
     * Callback function triggered by close click button to clean details panel page stack and close details panel
     */
    onPanelDismissCallback: VoidFunction;
}

export interface IDetailsPanelSpaceCardProps extends IDetailsPanelCardBaseProps {
    /**
     * The selected space's room info
     */
    selectedSpace: IRoomInfo;

    /**
     * The instant booking confirmation info
     */
    instantBookingConfirmationInfo?: IInstantBookingConfirmationInfo;
}

export interface IDetailsPanelPersonCardProps extends IDetailsPanelCardBaseProps {
    /**
     * The selected person's info
     */
    selectedPerson: IPerson;
}

export interface IDetailsPanelListViewCardProps extends IDetailsPanelCardBaseProps {
    /**
     * The selected category
     */
    selectedCategory: string;
}

export interface IInstantBookingConfirmationInfo {
    /**
     * reservation event id
     */
    reservationEventId: string;

    /**
     * reservation confirmation message
     */
    reservationMsg: string;
}

export const spaceCapabilitiesIconMap: Record<string, SvgIcon> = {
    Accessible: AccessibleOutlineIcon,
    Audio: AudioOutlineIcon,
    Blinds: BlindsOutlineIcon,
    Curtains: BlindsOutlineIcon,
    "Large Screen": LargeScreenOutlineIcon,
    Outlets: OutletsOutlineIcon,
    Podium: PodiumOutlineIcon,
    Projector: ProjectorOutlineIcon,
    "Projector Screen": ProjectorScreenOutlineIcon,
    Sofa: SofaOutlineIcon,
    Speakers: SpeakersOutlineIcon,
    "Surface Hub": SurfaceHubOutlineIcon,
    Teams: PeopleOutlineIcon,
    Video: VideoOutlineIcon,
    Webcam: WebcamOutlineIcon,
    Whiteboard: WhiteboardOutlineIcon,
    Windows: WindowOutlineIcon
};
