var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { DarkThemeOptions, LightThemeOptions, SensorLayerColors, SensorLayerNames, SensorStateColors } from "./SensorOverlayMapLayer.Types";
import { layer } from "azure-maps-control";
import { MapStyles } from "../../Types/MapStyles";
var SensorOverlayMapLayer = /** @class */ (function () {
    function SensorOverlayMapLayer(map, logger, mapFeaturesDataSource) {
        var _this = this;
        this.map = map;
        this.logger = logger;
        this.sensorPolygonLayer = new layer.PolygonLayer(mapFeaturesDataSource, SensorLayerNames.SensorLayer);
        this.sensorBaseLayer = new layer.PolygonLayer(mapFeaturesDataSource, SensorLayerNames.BaseLayer);
        this.sensorOutlineLayer = new layer.LineLayer(mapFeaturesDataSource, SensorLayerNames.OutlineLayer);
        var sensorColorExp = ["get", "sensorStatusColor"];
        this.sensorPolygonLayer.setOptions({
            fillColor: sensorColorExp,
            fillOpacity: DarkThemeOptions.SENSOR_LAYER_OPACITY,
            filter: ["all", ["has", "sensorStatusColor"], ["==", ["get", "levelId"], ["literal", ""]]],
            minZoom: 16,
            isVisible: true
        });
        this.sensorBaseLayer.setOptions({
            fillColor: [
                "case",
                ["==", ["get", "sensorStatusColor"], SensorStateColors.TRANSPARENT],
                "transparent",
                SensorLayerColors.DarkThemeBase
            ],
            fillOpacity: 1,
            minZoom: 16,
            filter: ["all", ["has", "sensorStatusColor"], ["==", ["get", "levelId"], ["literal", ""]]]
        });
        this.sensorOutlineLayer.setOptions({
            strokeColor: SensorLayerColors.DarkThemeOutline,
            strokeWidth: DarkThemeOptions.OUTLINE_LAYER_STROKE_WIDTH,
            minZoom: 16,
            filter: ["all", ["has", "sensorStatusColor"], ["==", ["get", "levelId"], ["literal", ""]]]
        });
        this.map.events.add("ready", function () {
            _this.toggleSensorLayerTheme();
            // Add layer to map underneath vertical_penetration layer - equivalent to how a room is colored
            _this.map.layers.add([_this.sensorBaseLayer, _this.sensorPolygonLayer, _this.sensorOutlineLayer], "vertical_penetration");
            _this.logger.logEvent("[SensorOverlayMapLayer] Sensor layer added to map");
        });
    }
    SensorOverlayMapLayer.prototype.setSensorLayerFloor = function (floorId) {
        var _this = this;
        var _a, _b, _c;
        (_a = this.sensorPolygonLayer) === null || _a === void 0 ? void 0 : _a.setOptions(__assign(__assign({}, this.sensorPolygonLayer.getOptions()), { filter: ["all", ["has", "sensorStatusColor"], ["==", ["get", "levelId"], ["literal", floorId]]] }));
        (_b = this.sensorBaseLayer) === null || _b === void 0 ? void 0 : _b.setOptions(__assign(__assign({}, this.sensorBaseLayer.getOptions()), { filter: ["all", ["has", "sensorStatusColor"], ["==", ["get", "levelId"], ["literal", floorId]]] }));
        (_c = this.sensorOutlineLayer) === null || _c === void 0 ? void 0 : _c.setOptions(__assign(__assign({}, this.sensorOutlineLayer.getOptions()), { filter: ["all", ["has", "sensorStatusColor"], ["==", ["get", "levelId"], ["literal", floorId]]] }));
        this.map.events.addOnce("ready", function () {
            _this.map.layers.remove([_this.sensorBaseLayer, _this.sensorPolygonLayer, _this.sensorOutlineLayer]);
            _this.map.layers.add([_this.sensorBaseLayer, _this.sensorPolygonLayer, _this.sensorOutlineLayer], "vertical_penetration");
        });
        this.logger.logEvent("[SensorOverlayMapLayer] Sensor layer floor switched", {
            floorId: floorId
        });
    };
    SensorOverlayMapLayer.prototype.toggleSensorLayerVisibility = function (isVisible) {
        this.sensorPolygonLayer.setOptions({ visible: isVisible });
        this.sensorBaseLayer.setOptions({ visible: isVisible });
        this.sensorOutlineLayer.setOptions({ visible: isVisible });
    };
    SensorOverlayMapLayer.prototype.toggleSensorLayerTheme = function () {
        var _this = this;
        this.map.events.addOnce("ready", function () {
            var mapTheme = _this.map.getStyle().style;
            if (mapTheme === MapStyles.Light) {
                _this.sensorPolygonLayer.setOptions({ fillOpacity: LightThemeOptions.SENSOR_LAYER_OPACITY });
                _this.sensorOutlineLayer.setOptions({ strokeWidth: LightThemeOptions.OUTLINE_LAYER_STROKE_WIDTH });
            }
            else if (mapTheme === MapStyles.Dark) {
                _this.sensorPolygonLayer.setOptions({ fillOpacity: DarkThemeOptions.SENSOR_LAYER_OPACITY });
                _this.sensorOutlineLayer.setOptions({ strokeWidth: DarkThemeOptions.OUTLINE_LAYER_STROKE_WIDTH });
            }
        });
    };
    return SensorOverlayMapLayer;
}());
export { SensorOverlayMapLayer };
