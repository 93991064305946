var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HtmlMarker, data, layer, source } from "azure-maps-control";
import { getDirectionsIndicatorSymbolLayerOptions, getHtmlMarkerOptionsForLabel, getHtmlMarkerOptionsFromStop, getLineLayerOptions, getVehicleHtmlMarkerOptions, getVehicleInTransitHtmlMarkerOptions } from "./VehicleTrackingLayerOptions";
import { getFeaturesFromRouteLeg, getUniqueStopsFromRouteLegs } from "./FeatureService";
import { getRouteLegDataSourceId, getRouteLegId, getRouteLegLineLayerId, getRouteLegSymbolLayerId, getStopLabelId, getVehicleMarkerId } from "./IdGenerator";
import { templatedIcons, vehicleTrackingLayerZoomLevel } from "./Constants";
import { getDirections } from "./RouteService";
var VehicleTrackingLayer = /** @class */ (function () {
    function VehicleTrackingLayer(map) {
        this.map = map;
        this.symbolDataSources = {};
        this.routeLegDataSources = {};
        this.routeLegLineLayers = {};
        this.symbolLayers = {};
        this.htmlMarkers = {};
        this.shouldCreateImageSprites = true;
        this.shouldCenterTheMap = true;
    }
    VehicleTrackingLayer.prototype.addOrUpdateVehicleTrackingLayer = function (vehicleTracking) {
        return __awaiter(this, void 0, void 0, function () {
            var routeLegs, vehicle, tripInfo, _i, routeLegs_1, routeLeg, renderIsNeeded;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        routeLegs = vehicleTracking.routeLegs, vehicle = vehicleTracking.vehicle, tripInfo = vehicleTracking.tripInfo;
                        if (!this.shouldCreateImageSprites) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.createImageSprites()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        this.addOrUpdateLocationMarkers(routeLegs);
                        _i = 0, routeLegs_1 = routeLegs;
                        _a.label = 3;
                    case 3:
                        if (!(_i < routeLegs_1.length)) return [3 /*break*/, 6];
                        routeLeg = routeLegs_1[_i];
                        renderIsNeeded = this.shouldRouteLegRender(routeLegs, routeLeg);
                        if (!renderIsNeeded) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.renderRouteLeg(routeLeg, vehicle, tripInfo)];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5:
                        _i++;
                        return [3 /*break*/, 3];
                    case 6:
                        if (this.shouldCenterTheMap) {
                            this.centerMap(vehicle.vehicleCurrentLocation);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    VehicleTrackingLayer.prototype.getBoundingBox = function () {
        return this.boundingBox;
    };
    VehicleTrackingLayer.prototype.dispose = function () {
        this.removeLineLayers();
        this.removeSymbolLayers();
        this.removeHtmlMarkers();
        this.removeDataSources();
    };
    /*
        Private methods goes here
    */
    VehicleTrackingLayer.prototype.createImageSprites = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _i, templatedIcons_1, icon;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _i = 0, templatedIcons_1 = templatedIcons;
                        _a.label = 1;
                    case 1:
                        if (!(_i < templatedIcons_1.length)) return [3 /*break*/, 4];
                        icon = templatedIcons_1[_i];
                        return [4 /*yield*/, this.map.imageSprite.createFromTemplate(icon.id, icon.templateName, icon.color, icon.secondaryColor, icon.scale)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4:
                        this.shouldCreateImageSprites = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    VehicleTrackingLayer.prototype.renderRouteLeg = function (routeLeg, vehicle, tripInfo) {
        return __awaiter(this, void 0, void 0, function () {
            var tripStatus, directionsData, dataSourceId, dataSource, ds;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        tripStatus = tripInfo.tripStatus;
                        return [4 /*yield*/, this.getRouteLegDirectionsInfo(routeLeg)];
                    case 1:
                        directionsData = _a.sent();
                        dataSourceId = getRouteLegDataSourceId(routeLeg);
                        dataSource = this.getDataSourceFromPositions(directionsData, dataSourceId);
                        this.routeLegDataSources[dataSourceId] = dataSource;
                        if (this.map.sources.getById(dataSourceId)) {
                            ds = this.map.sources.getById(dataSourceId);
                            ds.setShapes(dataSource.getShapes());
                        }
                        else {
                            this.map.sources.add(dataSource);
                        }
                        this.addLayersForRouteLeg(dataSource, routeLeg, tripInfo);
                        if (routeLeg.isActive && vehicle.vehicleCurrentLocation) {
                            if (tripStatus === "InTransit" && directionsData.length > 0) {
                                this.addOrUpdateVehicleInTransitMarker(directionsData[0], vehicle);
                            }
                            else {
                                this.addOrUpdateVehicleMarker(vehicle);
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    VehicleTrackingLayer.prototype.addOrUpdateVehicleMarker = function (vehicle) {
        var vehicleCurrentLocation = vehicle.vehicleCurrentLocation;
        if (vehicleCurrentLocation === undefined) {
            return;
        }
        var htmlMarkerOptions = getVehicleHtmlMarkerOptions(vehicle);
        var markerId = getVehicleMarkerId(vehicleCurrentLocation.id);
        this.addHtmlMarker(htmlMarkerOptions, markerId);
    };
    VehicleTrackingLayer.prototype.addOrUpdateVehicleInTransitMarker = function (routeStartingPosition, vehicle) {
        var vehicleCurrentLocation = vehicle.vehicleCurrentLocation;
        if (vehicleCurrentLocation === undefined) {
            return;
        }
        var htmlMarkerOptions = getVehicleInTransitHtmlMarkerOptions(routeStartingPosition);
        var markerId = getVehicleMarkerId(vehicleCurrentLocation.id);
        this.addHtmlMarker(htmlMarkerOptions, markerId);
    };
    VehicleTrackingLayer.prototype.addOrUpdateLocationMarkers = function (routeLegs) {
        var stops = getUniqueStopsFromRouteLegs(routeLegs);
        for (var _i = 0, stops_1 = stops; _i < stops_1.length; _i++) {
            var stop_1 = stops_1[_i];
            if (this.htmlMarkers[stop_1.id]) {
                continue;
            }
            var htmlMarkerOptions = getHtmlMarkerOptionsFromStop(stop_1);
            this.addHtmlMarker(htmlMarkerOptions, stop_1.id);
            var labelHtmlMarkerOptions = getHtmlMarkerOptionsForLabel(stop_1);
            var labelId = getStopLabelId(stop_1.id);
            this.addHtmlMarker(labelHtmlMarkerOptions, labelId);
        }
    };
    VehicleTrackingLayer.prototype.addHtmlMarker = function (options, id) {
        var existingMarker = this.htmlMarkers[id];
        if (existingMarker) {
            existingMarker.setOptions(options);
            return;
        }
        var newHtmlMarker = new HtmlMarker(options);
        this.map.markers.add(newHtmlMarker);
        this.htmlMarkers[id] = newHtmlMarker;
    };
    VehicleTrackingLayer.prototype.addLayersForRouteLeg = function (dataSource, routeLeg, tripInfo) {
        var lineLayerId = getRouteLegLineLayerId(routeLeg);
        var lineLayerOptions = getLineLayerOptions(routeLeg, tripInfo);
        var lineLayerForRouteLeg = new layer.LineLayer(dataSource, lineLayerId, lineLayerOptions);
        this.routeLegLineLayers[lineLayerId] = lineLayerForRouteLeg;
        var symbolLayerOptions = getDirectionsIndicatorSymbolLayerOptions();
        var symbolLayerId = getRouteLegSymbolLayerId(routeLeg);
        var symbolLayerForRouteLeg = new layer.SymbolLayer(dataSource, symbolLayerId, symbolLayerOptions);
        this.symbolLayers[symbolLayerId] = symbolLayerForRouteLeg;
        this.map.layers.add([lineLayerForRouteLeg, symbolLayerForRouteLeg]);
    };
    VehicleTrackingLayer.prototype.getDataSourceFromPositions = function (positions, dataSourceId) {
        var dataSource = new source.DataSource(dataSourceId);
        dataSource.add(new data.LineString(positions));
        return dataSource;
    };
    VehicleTrackingLayer.prototype.getRouteLegDirectionsInfo = function (routeLeg) {
        return __awaiter(this, void 0, void 0, function () {
            var featureSet, coordinates;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        featureSet = getFeaturesFromRouteLeg(routeLeg);
                        coordinates = featureSet.map(function (feature) { return feature.geometry.coordinates; });
                        return [4 /*yield*/, getDirections(coordinates, this.map)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    VehicleTrackingLayer.prototype.centerMap = function (vehicleCurrentLocation) {
        if (vehicleCurrentLocation) {
            var _a = vehicleCurrentLocation.waypoint, latitude = _a.latitude, longitude = _a.longitude;
            this.map.setCamera({
                center: [longitude, latitude],
                zoom: vehicleTrackingLayerZoomLevel
            });
            // subsequent updates shouldn't center the map
            this.shouldCenterTheMap = false;
        }
        else {
            var bbox = this.getBoundingBox();
            if (bbox) {
                this.map.setCamera({
                    bounds: bbox
                });
            }
        }
    };
    VehicleTrackingLayer.prototype.shouldRouteLegRender = function (routeLegs, routeLegToCheck) {
        var dataSourceId = getRouteLegDataSourceId(routeLegToCheck);
        if (!this.routeLegDataSources[dataSourceId]) {
            return true;
        }
        var activeRouteLeg = routeLegs.find(function (leg) { return leg.isActive; });
        var areTheSame = this.areEqual(routeLegToCheck, activeRouteLeg);
        if (areTheSame) {
            return true;
        }
        return false;
    };
    VehicleTrackingLayer.prototype.areEqual = function (routeLeg1, routeLeg2) {
        if (!routeLeg2 || !routeLeg1) {
            return false;
        }
        var key1 = getRouteLegId(routeLeg1);
        var key2 = getRouteLegId(routeLeg2);
        return key1 === key2;
    };
    VehicleTrackingLayer.prototype.removeHtmlMarkers = function () {
        for (var _i = 0, _a = Object.keys(this.htmlMarkers); _i < _a.length; _i++) {
            var key = _a[_i];
            var marker = this.htmlMarkers[key];
            if (marker) {
                this.map.markers.remove(marker);
            }
        }
    };
    VehicleTrackingLayer.prototype.removeLineLayers = function () {
        for (var _i = 0, _a = Object.keys(this.routeLegLineLayers); _i < _a.length; _i++) {
            var key = _a[_i];
            var routeLegLineLayerId = this.routeLegLineLayers[key].getId();
            if (routeLegLineLayerId && this.map.layers.getLayerById(routeLegLineLayerId)) {
                this.map.layers.remove(routeLegLineLayerId);
            }
        }
    };
    VehicleTrackingLayer.prototype.removeSymbolLayers = function () {
        for (var _i = 0, _a = Object.keys(this.symbolLayers); _i < _a.length; _i++) {
            var key = _a[_i];
            var symbolLayerId = this.symbolLayers[key].getId();
            if (symbolLayerId && this.map.layers.getLayerById(symbolLayerId)) {
                this.map.layers.remove(symbolLayerId);
            }
        }
    };
    VehicleTrackingLayer.prototype.removeDataSources = function () {
        for (var _i = 0, _a = Object.keys(this.routeLegDataSources); _i < _a.length; _i++) {
            var key = _a[_i];
            var routeLegDataSourceId = this.routeLegDataSources[key].getId();
            if (routeLegDataSourceId && this.map.sources.getById(routeLegDataSourceId)) {
                this.map.sources.remove(routeLegDataSourceId);
            }
        }
        for (var _b = 0, _c = Object.keys(this.symbolDataSources); _b < _c.length; _b++) {
            var key = _c[_b];
            var symbolDataSourceId = this.symbolDataSources[key].getId();
            if (symbolDataSourceId && this.map.sources.getById(symbolDataSourceId)) {
                this.map.sources.remove(symbolDataSourceId);
            }
        }
        this.routeLegDataSources = {};
        this.symbolDataSources = {};
    };
    return VehicleTrackingLayer;
}());
export { VehicleTrackingLayer };
