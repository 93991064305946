import { makeStyles } from "@fluentui/react-make-styles";

export const useBookableSpaceCardStyles = makeStyles({
    img: () => ({
        height: "150px",
        width: "100%",
        maxWidth: "106%",
        objectFit: "cover",
        marginBottom: "13px",
        marginTop: "12px",
        borderRadius: "8px"
    })
});
