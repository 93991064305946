import { HtmlMarker, data as atlasData, layer as atlasLayer, source as atlasSource } from "azure-maps-control";
import { PoiLayers } from "../CustomMapLayer.Types";
import { RouteIconLabels } from "../../Assets";
var POIOverlayMapLayer = /** @class */ (function () {
    function POIOverlayMapLayer(map, datasources, poiStyles) {
        this.map = map;
        this.datasources = datasources;
        this.poiStyles = poiStyles;
    }
    POIOverlayMapLayer.prototype.CreatePoiOverlayLayer = function (poiType, iconSvgCreator) {
        switch (poiType) {
            case PoiLayers.AedDevicePoi:
                return this.createPOIMarkersFromPoints(this.datasources.getAEDPointCloud, poiType);
            case PoiLayers.BombShelterPoi:
                return this.createPOIMarkersFromPoints(this.datasources.getBombShelterSpaces, poiType);
            case PoiLayers.EmergencyExitPoi:
                return this.createPOIMarkersFromPoints(this.datasources.getEmergencyExitPointCloud, poiType);
            case PoiLayers.FireExtinguisherPoi:
                return this.createPOIMarkersFromPoints(this.datasources.getFireExtinguisherPointCloud, poiType);
            case PoiLayers.KioskBlueDot:
                return this.createKioskMarkersFromPoints(this.datasources.getKioskBlueDot, iconSvgCreator);
            default:
                return Promise.resolve();
        }
    };
    POIOverlayMapLayer.prototype.createPOIMarkersFromPoints = function (pointCloud, mapLayer) {
        var _this = this;
        var datasource = new atlasSource.DataSource();
        this.map.sources.add(datasource);
        var points = [];
        for (var i = 0; i < pointCloud.length; i++) {
            points.push(new atlasData.Point(pointCloud[i]));
        }
        datasource.add(points);
        var colorOfMarker = "";
        var coordinateTemplate = "";
        if (mapLayer in PoiLayers) {
            colorOfMarker = this.poiStyles.SBSMapLayerColors[mapLayer];
            coordinateTemplate = mapLayer + "Template";
        }
        if (colorOfMarker && coordinateTemplate.length > 0 && points.length > 0) {
            this.map.events.add("ready", function () {
                return _this.map.imageSprite.add(coordinateTemplate, colorOfMarker).then(function () {
                    _this.map.layers.add(new atlasLayer.SymbolLayer(datasource, mapLayer, {
                        iconOptions: {
                            image: coordinateTemplate,
                            fadeDuration: 0
                        }
                    }));
                });
            });
        }
        return Promise.resolve();
    };
    POIOverlayMapLayer.prototype.createKioskMarkersFromPoints = function (pointCloud, iconSvgCreator) {
        var _this = this;
        if (!iconSvgCreator)
            return Promise.resolve();
        if (pointCloud.length > 0) {
            var kioskBlueDotPos = new atlasData.Position(pointCloud[0][0], pointCloud[0][1]);
            var marker = iconSvgCreator.createRouteMarkerSvg(RouteIconLabels.Start);
            var htmlOptions_1 = {
                htmlContent: marker.svg,
                position: kioskBlueDotPos,
                visible: true,
                anchor: "bottom",
                draggable: false,
                pixelOffset: [0, marker.heightOffset],
                kioskMarker: true
            };
            this.map.events.addOnce("ready", function () {
                _this.kioskMarker = new HtmlMarker(htmlOptions_1);
                _this.map.markers.add(_this.kioskMarker);
            });
        }
        return Promise.resolve();
    };
    POIOverlayMapLayer.prototype.clearKioskMarker = function () {
        var _this = this;
        this.map.events.addOnce("ready", function () {
            _this.kioskMarker && _this.map.markers.remove(_this.kioskMarker);
        });
    };
    return POIOverlayMapLayer;
}());
export { POIOverlayMapLayer };
