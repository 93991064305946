export function getSpaceCategoryMap(buildingCategories) {
    if (!buildingCategories || Object.keys(buildingCategories).length === 0)
        return {};
    var topLevelCategories = buildingCategories["root"].childCategories.filter(function (category) { return category !== "DisabledSpaces"; });
    var spaceCategoryMap = {};
    for (var _i = 0, topLevelCategories_1 = topLevelCategories; _i < topLevelCategories_1.length; _i++) {
        var category = topLevelCategories_1[_i];
        var spaceCategories = buildingCategories[category].childCategories;
        for (var _a = 0, spaceCategories_1 = spaceCategories; _a < spaceCategories_1.length; _a++) {
            var spaceCategory = spaceCategories_1[_a];
            spaceCategoryMap[spaceCategory] = buildingCategories[spaceCategory].spaces;
        }
    }
    return spaceCategoryMap;
}
