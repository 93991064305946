var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
var MostSearchService = /** @class */ (function () {
    function MostSearchService() {
        this.searchRecord = {};
        this.mostSearchLimit = 5;
        this.mostSearchItems = [];
    }
    MostSearchService.prototype.getRecentSearches = function () {
        var _this = this;
        var slicedEnteries = this.mostSearchItems.slice(0, this.mostSearchLimit);
        var result = {};
        slicedEnteries.forEach(function (key) {
            if (_this.searchRecord[key].itemIdOrType) {
                result[key] = _this.searchRecord[key].itemIdOrType;
            }
            else {
                result[key] = "";
            }
        });
        return result;
    };
    MostSearchService.prototype.addOrUpdateSearchRecord = function (item, itemType) {
        var foundItem = this.searchRecord[item];
        if (foundItem) {
            this.searchRecord[item].itemNumber++;
        }
        else {
            this.searchRecord[item] = { itemNumber: 1, itemIdOrType: "" };
            if (itemType) {
                this.searchRecord[item].itemIdOrType = itemType;
            }
        }
        this.sortRecord();
    };
    MostSearchService.prototype.sortRecord = function () {
        var _this = this;
        var sortedList = Object.keys(this.searchRecord).sort(function (a, b) {
            return _this.searchRecord[b].itemNumber - _this.searchRecord[a].itemNumber;
        });
        this.mostSearchItems = [];
        if (Object.keys(this.searchRecord).length >= this.mostSearchLimit) {
            this.mostSearchItems = __spreadArrays(sortedList.slice(0, this.mostSearchLimit));
        }
        else {
            this.mostSearchItems = __spreadArrays(sortedList);
        }
    };
    return MostSearchService;
}());
export { MostSearchService };
