import { SpaceHasIncludes } from "@smartbuilding/adt-v2-types";
import { getRoomCapabilitiesForSpace } from "./HelpingFunctions";
var RoomFeatureService = /** @class */ (function () {
    function RoomFeatureService(logger, spaceRepo) {
        this.logger = logger;
        this.spaceRepo = spaceRepo;
    }
    RoomFeatureService.prototype.getFloorNumber = function (spaceId) {
        var _this = this;
        if (!spaceId) {
            throw new Error("SpaceId is empty or undefined");
        }
        return this.spaceRepo.getSpaceById(spaceId, [SpaceHasIncludes.HasParent]).then(function (space) {
            var _a;
            var floorName = (_a = space.parent) === null || _a === void 0 ? void 0 : _a.name;
            if (floorName) {
                _this.logger.logEvent("Floor number of spaceId:" + spaceId + " is requested");
                return floorName;
            }
            return undefined;
        });
    };
    RoomFeatureService.prototype.getSpaceName = function (spaceId) {
        if (!spaceId) {
            throw new Error("SpaceId is empty or undefined");
        }
        return this.spaceRepo.getSpaceById(spaceId).then(function (space) {
            return space && space.name ? space.name : "";
        });
    };
    RoomFeatureService.prototype.getIconUrl = function (spaceId) {
        if (!spaceId) {
            throw new Error("SpaceId is empty or undefined");
        }
        // TODO: Fetch Icon image from the blob, since we don't have any blob service for
        // conference room images returning empty string for now
        // otherwise returning image url
        return "";
    };
    RoomFeatureService.prototype.getRoomCapabilities = function (spaceId) {
        if (!spaceId) {
            throw new Error("SpaceId is empty or undefined");
        }
        return this.spaceRepo.getSpaceById(spaceId, [SpaceHasIncludes.HasChildren]).then(function (space) {
            return space && space.properties ? getRoomCapabilitiesForSpace(space.properties) : {};
        });
    };
    RoomFeatureService.prototype.getConferenceRoomSize = function (spaceId) {
        if (!spaceId) {
            throw new Error("SpaceId is empty or undefined");
        }
        return this.spaceRepo.getSpaceById(spaceId, [SpaceHasIncludes.HasChildren]).then(function (space) {
            var _a, _b;
            return space && ((_a = space.calendar) === null || _a === void 0 ? void 0 : _a.capacity) ? (_b = space.calendar) === null || _b === void 0 ? void 0 : _b.capacity.toString() : "";
        });
    };
    RoomFeatureService.prototype.getDirection = function (spaceId) {
        if (!spaceId) {
            throw new Error("SpaceId is empty or undefined");
        }
        // TODO: Direction services are not migrated yet hence returning empty string
        throw new Error("Direction feature not implemented exception");
    };
    return RoomFeatureService;
}());
export { RoomFeatureService };
