class ErrorConstant {
    public ElectronKiosk = {
        DEVICENOTFOUND: "Can't get device info since it is not an Electron App"
    };

    public AuthConstant = {
        LOGINREQUIRED: "login_required"
    };
}

export enum DirectionsErrorMessages {
    SameStartAndDestinationError = "Source and destination cannot be the same",
    FailedRouteError = "Failed to generate route for the selected room"
}

export enum BookingErrorMessages {
    AliasInputError = "Please enter a valid email address or scan your card",
    ScanInputError = "Badge was not recognized. Try another one?",
    AvailableTimesSelectionError = "Please select available time slot for this booking"
}

export const errorConstant = new ErrorConstant();
