import React from "react";
import { createSvgIcon } from "@fluentui/react-icons-mdl2";

// eslint-disable-next-line @typescript-eslint/naming-convention
export const BlindsOutlineIcon = createSvgIcon({
    // eslint-disable-next-line react/display-name
    svg: (args) => {
        return (
            <svg viewBox="0 0 20 20" fill="none" className={args.classes.svg} xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M5.49805 3.00098C4.11734 3.00098 2.99805 4.12026 2.99805 5.50098V14.501C2.99805 15.8817 4.11733 17.001 5.49805 17.001H14.498C15.8788 17.001 16.998 15.8817 16.998 14.501V7.00098H17.001V6.00098H16.998V5.50098C16.998 4.12026 15.8788 3.00098 14.498 3.00098H5.49805ZM15.998 5.50098V6.00098H14.7086L15.853 4.85658C15.946 5.05181 15.998 5.27031 15.998 5.50098ZM15.1471 4.14827L13.2944 6.00098H10.4659L12.4659 4.00098H14.498C14.7305 4.00098 14.9507 4.05387 15.1471 4.14827ZM11.0517 4.00098L9.05171 6.00098H6.22328L8.22328 4.00098H11.0517ZM6.80907 4.00098L4.80907 6.00098H3.99805V5.50098C3.99805 4.67255 4.66962 4.00098 5.49805 4.00098H6.80907ZM3.99805 8.22621L5.22328 7.00098H8.05171L3.99805 11.0546V8.22621ZM9.46592 7.00098H12.2944L4.14534 15.15C4.05094 14.9536 3.99805 14.7335 3.99805 14.501V12.4689L9.46592 7.00098ZM13.7086 7.00098H15.998V7.53992L7.53699 16.001H5.49805C5.26738 16.001 5.04888 15.9489 4.85365 15.8559L13.7086 7.00098ZM15.998 8.95414V11.7826L11.7796 16.001H8.95121L15.998 8.95414ZM15.998 13.1968V14.501C15.998 15.3294 15.3265 16.001 14.498 16.001H13.1938L15.998 13.1968Z"
                    fill="currentColor"
                />
            </svg>
        );
    },
    displayName: "BlindsOutlineIcon"
});

// eslint-disable-next-line @typescript-eslint/naming-convention
export const BlindsFilledIcon = createSvgIcon({
    // eslint-disable-next-line react/display-name
    svg: (args) => {
        return (
            <svg viewBox="0 0 20 20" fill="none" className={args.classes.svg} xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M5.49805 3.00098C4.11733 3.00098 2.99805 4.12026 2.99805 5.50098V14.501C2.99805 15.8817 4.11733 17.001 5.49805 17.001H14.498C15.8788 17.001 16.998 15.8817 16.998 14.501V8H17.001V6.5H16.998V5.50098C16.998 4.12026 15.8788 3.00098 14.498 3.00098H5.49805ZM15.5005 6V6.5H14.2095L15.3549 5.35461C15.4482 5.55009 15.5005 5.76895 15.5005 6ZM14.6485 4.64682L12.7953 6.5H9.9669L11.9669 4.5H14.0005C14.2326 4.5 14.4524 4.55271 14.6485 4.64682ZM10.5527 4.5L8.55269 6.5H5.72426L7.72426 4.5H10.5527ZM6.31005 4.5L4.50049 6.30956V6C4.50049 5.17157 5.17206 4.5 6.00049 4.5H6.31005ZM7.05269 8L4.50049 10.5522V8H7.05269ZM4.50049 11.9664L8.4669 8H11.2953L4.6473 14.648C4.5532 14.4519 4.50049 14.2321 4.50049 14V11.9664ZM12.7095 8H15.5005V8.03748L8.03797 15.5H6.00049C5.76943 15.5 5.55058 15.4478 5.3551 15.3544L12.7095 8ZM15.5005 9.45169V12.2801L12.2806 15.5H9.45218L15.5005 9.45169ZM15.5005 13.6943V14C15.5005 14.8284 14.8289 15.5 14.0005 15.5H13.6948L15.5005 13.6943Z"
                    fill="currentColor"
                />
            </svg>
        );
    },
    displayName: "BlindsFilledIcon"
});
