import { OCVFeedback } from "./OCVFeedback/OCVFeedback";
import { PrivacyNotice } from "./PrivacyNotice/PrivacyNotice";
import React from "react";
import { useFooterButtonsStyles } from "./useFooterButtonsStyles";

export function FooterButtons(): JSX.Element {
    const styles = useFooterButtonsStyles();

    return (
        <div className={styles.footerButtonsContainer}>
            <OCVFeedback />
            <PrivacyNotice />
        </div>
    );
}
