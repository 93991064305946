import { ISBClientTheme } from "./Theme.Types";
import { sbClientDarkCustomizations } from "./DarkThemeSelection";
import { sbClientLightCustomizations } from "./LightThemeSelection";

export function GetSBClientTheme(isDarkMode: boolean): ISBClientTheme {
    if (isDarkMode) {
        return {
            ...sbClientDarkCustomizations.settings.theme
        };
    }
    return {
        ...sbClientLightCustomizations.settings.theme
    };
}
