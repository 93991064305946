import { createTheme } from "@fluentui/react";
export var customColor = createTheme({
    palette: {
        themePrimary: "#323130",
        themeLighterAlt: "#e7e6e5",
        themeLighter: "#d0cfce",
        themeLight: "#bab8b7",
        themeTertiary: "#a3a2a0",
        themeSecondary: "#8d8b8a",
        themeDarkAlt: "#767573",
        themeDark: "#605e5d",
        themeDarker: "#494847",
        neutralLighterAlt: "#faf9f8",
        neutralLighter: "#f3f2f1",
        neutralLight: "#edebe9",
        neutralQuaternaryAlt: "#e1dfdd",
        neutralQuaternary: "#d0d0d0",
        neutralTertiaryAlt: "#c8c6c4",
        neutralTertiary: "#ffccd1",
        neutralSecondary: "#ff98a3",
        neutralSecondaryAlt: "#ff98a3",
        neutralPrimaryAlt: "#ff6978",
        neutralPrimary: "#ff5466",
        neutralDark: "#c2404d",
        black: "#8f2f39",
        white: "#ffffff"
    }
});
export var customTheme = {
    palette: {
        themePrimary: "#ddee11",
        themeLighterAlt: "#fdfef5",
        themeLighter: "#f9fcd7",
        themeLight: "#f4fab4",
        themeTertiary: "#e9f46c",
        themeSecondary: "#dfef2b",
        themeDarkAlt: "#c5d50f",
        themeDark: "#a6b40d",
        themeDarker: "#7b8509",
        neutralLighterAlt: "#16396d",
        neutralLighter: "#1b3e73",
        neutralLight: "#24477c",
        neutralQuaternaryAlt: "#2a4d82",
        neutralQuaternary: "#2f5287",
        neutralTertiaryAlt: "#47699b",
        neutralTertiary: "#aba1aa",
        neutralSecondary: "#8f868e",
        neutralSecondaryAlt: "#8f868e",
        neutralPrimaryAlt: "#736c72",
        neutralPrimary: "#ffeffe",
        neutralDark: "#3b373a",
        black: "#1f1d1e",
        white: "#123467",
        blackTranslucent40: "rgba(0,0,0,.4)",
        accent: "#7da3c6",
        whiteTranslucent40: "rgba(255,255,255,.4)",
        yellowDark: "#d29200",
        yellow: "#ffb900",
        yellowLight: "#fff100",
        orange: "#d83b01",
        orangeLight: "#ea4300",
        orangeLighter: "#ff8c00",
        redDark: "#F1707B",
        red: "#e81123",
        magentaDark: "#5c005c",
        magenta: "#b4009e",
        magentaLight: "#e3008c",
        purpleDark: "#32145a",
        purple: "#5c2d91",
        purpleLight: "#b4a0ff",
        blueDark: "#002050",
        blueMid: "#00188f",
        blue: "#0078d4",
        blueLight: "#00bcf2",
        tealDark: "#004b50",
        teal: "#008272",
        tealLight: "#00b294",
        greenDark: "#004b1c",
        green: "#107c10",
        greenLight: "#bad80a"
    },
    effects: {
        elevation4: "0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108)",
        elevation8: "0 3.2px 7.2px 0 rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108)",
        elevation16: "0 6.4px 14.4px 0 rgba(0, 0, 0, 0.132), 0 1.2px 3.6px 0 rgba(0, 0, 0, 0.108)",
        elevation64: "0 25.6px 57.6px 0 rgba(0, 0, 0, 0.22), 0 4.8px 14.4px 0 rgba(0, 0, 0, 0.18)",
        roundedCorner2: "10px",
        roundedCorner4: "12px",
        roundedCorner6: "14px"
    },
    fonts: {
        tiny: {
            fontFamily: "'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Roboto', 'Helvetica Neue', sans-serif",
            MozOsxFontSmoothing: "grayscale",
            WebkitFontSmoothing: "antialiased",
            fontSize: "10px",
            fontWeight: 400
        },
        xSmall: {
            fontFamily: "'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Roboto', 'Helvetica Neue', sans-serif",
            MozOsxFontSmoothing: "grayscale",
            WebkitFontSmoothing: "antialiased",
            fontSize: "10px",
            fontWeight: 400
        },
        small: {
            fontFamily: "'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Roboto', 'Helvetica Neue', sans-serif",
            MozOsxFontSmoothing: "grayscale",
            WebkitFontSmoothing: "antialiased",
            fontSize: "12px",
            fontWeight: 400
        },
        smallPlus: {
            fontFamily: "'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Roboto', 'Helvetica Neue', sans-serif",
            MozOsxFontSmoothing: "grayscale",
            WebkitFontSmoothing: "antialiased",
            fontSize: "12px",
            fontWeight: 400
        },
        medium: {
            fontFamily: "'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Roboto', 'Helvetica Neue', sans-serif",
            MozOsxFontSmoothing: "grayscale",
            WebkitFontSmoothing: "antialiased",
            fontSize: "14px",
            fontWeight: 400
        },
        mediumPlus: {
            fontFamily: "'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Roboto', 'Helvetica Neue', sans-serif",
            MozOsxFontSmoothing: "grayscale",
            WebkitFontSmoothing: "antialiased",
            fontSize: "16px",
            fontWeight: 400
        },
        large: {
            fontFamily: "'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Roboto', 'Helvetica Neue', sans-serif",
            MozOsxFontSmoothing: "grayscale",
            WebkitFontSmoothing: "antialiased",
            fontSize: "18px",
            fontWeight: 400
        },
        xLarge: {
            fontFamily: "'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Roboto', 'Helvetica Neue', sans-serif",
            MozOsxFontSmoothing: "grayscale",
            WebkitFontSmoothing: "antialiased",
            fontSize: "20px",
            fontWeight: 600
        },
        xLargePlus: {
            fontFamily: "'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Roboto', 'Helvetica Neue', sans-serif",
            MozOsxFontSmoothing: "grayscale",
            WebkitFontSmoothing: "antialiased",
            fontSize: "24px",
            fontWeight: 600
        },
        xxLarge: {
            fontFamily: "'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Roboto', 'Helvetica Neue', sans-serif",
            MozOsxFontSmoothing: "grayscale",
            WebkitFontSmoothing: "antialiased",
            fontSize: "28px",
            fontWeight: 600
        },
        xxLargePlus: {
            fontFamily: "'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Roboto', 'Helvetica Neue', sans-serif",
            MozOsxFontSmoothing: "grayscale",
            WebkitFontSmoothing: "antialiased",
            fontSize: "32px",
            fontWeight: 600
        },
        superLarge: {
            fontFamily: "'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Roboto', 'Helvetica Neue', sans-serif",
            MozOsxFontSmoothing: "grayscale",
            WebkitFontSmoothing: "antialiased",
            fontSize: "42px",
            fontWeight: 600
        },
        mega: {
            fontFamily: "'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Roboto', 'Helvetica Neue', sans-serif",
            MozOsxFontSmoothing: "grayscale",
            WebkitFontSmoothing: "antialiased",
            fontSize: "68px",
            fontWeight: 600
        }
    },
    spacing: {
        s2: "4px",
        s1: "8px",
        m: "16px",
        l1: "20px",
        l2: "32px"
    },
    isInverted: false,
    disableGlobalClassNames: false,
    semanticColors: {
        bodyBackground: "#1b1a19",
        bodyFrameBackground: "#1b1a19",
        accentButtonText: "#1b1a19",
        buttonBackground: "#1b1a19",
        primaryButtonText: "#1b1a19",
        primaryButtonTextHovered: "#1b1a19",
        primaryButtonTextPressed: "#1b1a19",
        inputBackground: "#1b1a19",
        inputForegroundChecked: "#1b1a19",
        listBackground: "#252423",
        menuBackground: "#1b1a19",
        cardStandoutBackground: "#1b1a19",
        bodyTextChecked: "#ffffff",
        buttonTextCheckedHovered: "#ffffff",
        link: "#7da3c6",
        primaryButtonBackground: "#7da3c6",
        inputBackgroundChecked: "#7da3c6",
        inputIcon: "#7da3c6",
        inputFocusBorderAlt: "#7da3c6",
        menuIcon: "#7da3c6",
        menuHeader: "#7da3c6",
        accentButtonBackground: "#7da3c6",
        primaryButtonBackgroundPressed: "#5f7c97",
        inputBackgroundCheckedHovered: "#5f7c97",
        inputIconHovered: "#5f7c97",
        linkHovered: "#465b6f",
        primaryButtonBackgroundHovered: "#7193b3",
        inputPlaceholderBackgroundChecked: "#e7eff6",
        bodyBackgroundChecked: "#292827",
        bodyFrameDivider: "#292827",
        bodyDivider: "#292827",
        variantBorder: "#292827",
        buttonBackgroundCheckedHovered: "#292827",
        buttonBackgroundPressed: "#292827",
        listItemBackgroundChecked: "#292827",
        listHeaderBackgroundPressed: "#292827",
        menuItemBackgroundPressed: "#292827",
        menuItemBackgroundChecked: "#292827",
        bodyBackgroundHovered: "#252423",
        buttonBackgroundHovered: "#252423",
        buttonBackgroundDisabled: "#252423",
        buttonBorderDisabled: "#252423",
        primaryButtonBackgroundDisabled: "#252423",
        disabledBackground: "#252423",
        listItemBackgroundHovered: "#252423",
        listHeaderBackgroundHovered: "#252423",
        menuItemBackgroundHovered: "#252423",
        primaryButtonTextDisabled: "#3b3a39",
        disabledSubtext: "#3b3a39",
        listItemBackgroundCheckedHovered: "#323130",
        disabledBodyText: "#797775",
        variantBorderHovered: "#797775",
        buttonTextDisabled: "#797775",
        inputIconDisabled: "#797775",
        disabledText: "#797775",
        bodyText: "#f3f2f1",
        actionLink: "#f3f2f1",
        buttonText: "#f3f2f1",
        inputBorderHovered: "#f3f2f1",
        inputText: "#f3f2f1",
        listText: "#f3f2f1",
        menuItemText: "#f3f2f1",
        bodyStandoutBackground: "#201f1e",
        defaultStateBackground: "#201f1e",
        actionLinkHovered: "#faf9f8",
        buttonTextHovered: "#faf9f8",
        buttonTextChecked: "#faf9f8",
        buttonTextPressed: "#faf9f8",
        inputTextHovered: "#faf9f8",
        menuItemTextHovered: "#faf9f8",
        bodySubtext: "#a19f9d",
        focusBorder: "#a19f9d",
        inputBorder: "#a19f9d",
        smallInputBorder: "#a19f9d",
        inputPlaceholderText: "#a19f9d",
        buttonBorder: "#979693",
        disabledBodySubtext: "#484644",
        disabledBorder: "#484644",
        buttonBackgroundChecked: "#484644",
        menuDivider: "#484644",
        cardShadow: "0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108)",
        cardShadowHovered: "0 0 1px #797775",
        primaryButtonBorder: "transparent",
        errorText: "#a4262c",
        messageText: "#323130",
        messageLink: "#005A9E",
        messageLinkHovered: "#004578",
        infoIcon: "#605e5c",
        errorIcon: "#A80000",
        blockingIcon: "#FDE7E9",
        warningIcon: "#797775",
        severeWarningIcon: "#D83B01",
        successIcon: "#107C10",
        infoBackground: "#f3f2f1",
        errorBackground: "#FDE7E9",
        blockingBackground: "#FDE7E9",
        warningBackground: "#FFF4CE",
        severeWarningBackground: "#FED9CC",
        successBackground: "#DFF6DD",
        warningHighlight: "#ffb900",
        successText: "#107C10",
        listTextColor: "#323130",
        warningText: "#323130"
    },
    components: {
        Card: {
            styles: {
                root: {
                    background: "#252423"
                }
            }
        },
        DetailsList: {
            styles: {
                headerWrapper: {
                    selectors: {
                        ".ms-DetailsHeader": {
                            borderColor: "#3b3a39"
                        }
                    }
                }
            }
        },
        ActionButton: {
            styles: {
                root: {
                    backgroundColor: "#1b1a19"
                },
                rootDisabled: {
                    backgroundColor: "#252423"
                },
                rootHovered: {
                    backgroundColor: "#292827"
                },
                rootPressed: {
                    backgroundColor: "#323130"
                }
            }
        },
        DetailsRow: {
            styles: {
                root: {
                    selectors: {
                        ":hover": {
                            background: "#252423"
                        }
                    },
                    borderColor: "#323130"
                }
            }
        },
        Modal: {
            styles: {
                main: {
                    backgroundColor: "#252423"
                }
            }
        },
        Overlay: {
            styles: {
                root: {}
            }
        },
        VerticalDivider: {
            styles: {
                divider: {
                    backgroundColor: "#323130"
                },
                wrapper: {}
            }
        },
        DocumentCard: {
            styles: {
                root: {
                    border: "1px solid #323130",
                    selectors: {
                        ".ms-DocumentCardPreview": {
                            borderRight: "1px solid #323130"
                        }
                    }
                }
            }
        },
        DocumentCardPreview: {
            styles: {
                root: {
                    borderBottom: "1px solid #323130",
                    borderRight: "1px solid #323130"
                }
            }
        },
        Panel: {
            styles: {
                main: {
                    backgroundColor: "#252423"
                },
                closeButton: {
                    color: "#a19f9d",
                    selectors: {
                        ":hover": {
                            color: "#f3f2f1"
                        }
                    }
                }
            }
        },
        Separator: {
            styles: {
                root: {
                    selectors: {
                        ":before": {
                            backgroundColor: "#323130"
                        },
                        ":after": {
                            backgroundColor: "#323130"
                        }
                    }
                }
            }
        },
        SpinButton: {
            styles: {
                inputTextSelected: {
                    color: "#ffffff",
                    background: "#7da3c6"
                }
            }
        }
    },
    id: "0-103-104"
};
