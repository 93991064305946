var SpaceGroup = /** @class */ (function () {
    function SpaceGroup(objectIds, valueTypes, eventType, eventSubtype) {
        this.objectIds = [];
        this.valueTypes = [];
        this.objectIds = objectIds;
        this.valueTypes = valueTypes;
        this.eventType = eventType;
        this.eventSubtype = eventSubtype;
    }
    return SpaceGroup;
}());
export { SpaceGroup };
