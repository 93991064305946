import { FeatureExperimentsNamespaces, FeatureFlagNames } from "@smartbuilding/feature-flighting-service";
import { disablePeopleFeatures, enableInstantBooking, enableOCVFeedback, enableRoomTags } from "../redux/Actions";
import { getBuildingName, getDeviceConfigData, getFloor } from "../redux/Selectors";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ILogger } from "@smartbuilding/log-provider";
import { useFeatureFlightingContext } from "@smartbuilding/feature-flighting-service-react";
export function useFeatureFlightingValues(logger: ILogger): Record<string, string | number | boolean | undefined> {
    const [featureFlightValues, setFeatureFlightValues] = useState({
        PeopleFeatureDisabled: false,
        SpacePinAnimations: false,
        OCVEnabled: false,
        RoomTagEnabled: true,
        InstantBookingEnabled: false
    });
    const dispatch = useDispatch();
    const featureFlightingContext = useFeatureFlightingContext();

    useEffect(() => {
        featureFlightingContext.initialize().then(() => {
            const peopleFeatureDisabledValue = featureFlightingContext.getFeatureValue(
                FeatureExperimentsNamespaces.SmartBuildingClient,
                FeatureFlagNames.PeopleFeatureDisabled
            );
            const pinAnimationsValue = featureFlightingContext.getFeatureValue(
                FeatureExperimentsNamespaces.SmartBuildingClient,
                FeatureFlagNames.PinAnimations
            );
            const ocvEnabledValue = featureFlightingContext.getFeatureValue(
                FeatureExperimentsNamespaces.SmartBuildingClient,
                FeatureFlagNames.OCVEnabled
            );
            const roomTagEnabledValue = featureFlightingContext.getFeatureValue(
                FeatureExperimentsNamespaces.SmartBuildingClient,
                FeatureFlagNames.RoomTagEnabled
            );
            const instantBookingEnabledValue = featureFlightingContext.getFeatureValue(
                FeatureExperimentsNamespaces.SmartBuildingClient,
                FeatureFlagNames.InstantBookingEnabled
            );
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (window as any).SBSFeatureFlighting = {
                PeopleFeatureDisabled: !!peopleFeatureDisabledValue,
                SpacePinAnimations: !!pinAnimationsValue,
                OCVEnabled: !!ocvEnabledValue,
                RoomTagsEnabled: !!roomTagEnabledValue,
                InstantBookingEnabled: !!instantBookingEnabledValue
            };
            setFeatureFlightValues({
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                ...(window as any).SBSFeatureFlighting
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const buildingName = useSelector(getBuildingName);
    const buildingFloor = useSelector(getFloor);
    const deviceInfo = useSelector(getDeviceConfigData);

    useEffect(() => {
        if (featureFlightValues["PeopleFeatureDisabled"]) {
            dispatch(disablePeopleFeatures());

            if (logger && buildingName) {
                logger.logEvent("[PeopleFeatureDisabled] People features turned off", {
                    buildingName: buildingName,
                    buildingFloor: buildingFloor,
                    kioskInfo: deviceInfo
                });
            }
        }

        if (featureFlightValues["OCVEnabled"]) {
            dispatch(enableOCVFeedback());
        }

        if (featureFlightValues["RoomTagEnabled"]) {
            dispatch(enableRoomTags());
        }

        if (featureFlightValues["InstantBookingEnabled"]) {
            dispatch(enableInstantBooking());
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [featureFlightValues, logger, buildingName]);

    return featureFlightValues;
}
