import { IAction, IBaseAction } from "./IAction";
import { IListViewPageInfo, IPageInfo } from "../Types";

export enum DetailsPanelPageStackActions {
    ADD_PAGE = "ADD_PAGE",
    REMOVE_PAGE = "REMOVE_PAGE",
    CLEAR_STACK = "CLEAR_STACK"
}

export enum InstantBookingActions {
    INSTANT_BOOKING_ENABLED = "INSTANT_BOOKING_ENABLED"
}

export enum ListViewActions {
    LIST_VIEW_SPACE_SELECTION = "LIST_VIEW_SPACE_SELECTION"
}

export enum OCVFeedbackActions {
    OCV_FEEDBACK_ENABLED = "OCV_FEEDBACK_ENABLED"
}

export enum AppResetActions {
    APP_RESET = "APP_RESET",
    APP_RESET_IN_PROGRESS = "APP_RESET_IN_PROGRESS"
}

export enum RoomTagsActions {
    ROOM_TAG_ENABLED = "ROOM_TAG_ENABLED"
}

export type DetailsPanelPageStackAddPageAction = IAction<IPageInfo, DetailsPanelPageStackActions.ADD_PAGE>;
export type DetailsPanelPageStackRemovePageAction = IBaseAction<DetailsPanelPageStackActions.REMOVE_PAGE>;
export type DetailsPanelPageStackClearStackAction = IBaseAction<DetailsPanelPageStackActions.CLEAR_STACK>;
export type InstantBookingEnabledAction = IBaseAction<InstantBookingActions.INSTANT_BOOKING_ENABLED>;
export type ListViewSpaceSelectedAction = IAction<IListViewPageInfo, ListViewActions.LIST_VIEW_SPACE_SELECTION>;
export type OCVFeedbackEnabledAction = IBaseAction<OCVFeedbackActions.OCV_FEEDBACK_ENABLED>;
export type AppResetAction = IBaseAction<AppResetActions.APP_RESET>;
export type AppResetInProgressAction = IAction<boolean, AppResetActions.APP_RESET_IN_PROGRESS>;
export type RoomTagEnabledAction = IBaseAction<RoomTagsActions.ROOM_TAG_ENABLED>;

type DetailsPanelPageStackAction =
    | DetailsPanelPageStackAddPageAction
    | DetailsPanelPageStackRemovePageAction
    | DetailsPanelPageStackClearStackAction;

type OCVFeedbackAction = OCVFeedbackEnabledAction;

type RoomTagAction = RoomTagEnabledAction;

type InstantBookingAction = InstantBookingEnabledAction;

type ListViewAction = ListViewSpaceSelectedAction;

export type AppAction =
    | DetailsPanelPageStackAction
    | InstantBookingAction
    | ListViewAction
    | OCVFeedbackAction
    | AppResetAction
    | AppResetInProgressAction
    | RoomTagAction;
