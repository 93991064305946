import { ErrorAction, ErrorTypes } from "./ErrorActions";
import { IError } from "../Reducers/ErrorReducer";

export const setMapError = (error: IError): ErrorAction => ({
    type: ErrorTypes.MAP_ERROR,
    payload: error
});

export const setSagaError = (error: IError): ErrorAction => ({
    type: ErrorTypes.SAGA_ERROR,
    payload: error
});

export const setHttpError = (error: IError): ErrorAction => ({
    type: ErrorTypes.HTTP_ERROR,
    payload: error
});

export const setBootstrapperError = (error: IError): ErrorAction => ({
    type: ErrorTypes.BOOTSTRAPPER_ERROR,
    payload: error
});
