import OutOfService from "./OutOfService.svg";
import React from "react";
import { useFallbackStyles } from "./Fallback.Styles";

interface IProps {
    guid?: string | null;
}

export function Fallback(props: IProps): JSX.Element {
    const styles = useFallbackStyles();

    return (
        <div className={styles.container}>
            <img src={OutOfService} alt="Out Of Service" height={300} />
            <h1 className={styles.headerText}>We have a problem!</h1>
            <p className={styles.text}>
                But don’t worry, the SmartBuilding team has been notified and we’re working on getting this up and
                running!
            </p>
            <p className={styles.text}>If you have more questions, you can contact the team at sbs@microsoft.com</p>
            {props.guid && <p className={styles.guid}>id: {props.guid}</p>}
        </div>
    );
}
