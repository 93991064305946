export var SensorSubTypes;
(function (SensorSubTypes) {
    SensorSubTypes["ConferenceStatus"] = "ConferenceStatus";
    SensorSubTypes["CurrentSensor"] = "CurrentSensor";
    SensorSubTypes["ContactSwitchSensor"] = "ContactSwitchSensor";
    SensorSubTypes["FireAlarmSensor"] = "FireAlarmSensor";
    SensorSubTypes["MotionSensor"] = "MotionSensor";
    SensorSubTypes["OccupancyStatus"] = "OccupancyStatus";
    SensorSubTypes["PaperTowelCapacitySensor"] = "PaperTowelCapacitySensor";
    SensorSubTypes["PersonCountSensor"] = "PersonCountSensor";
    SensorSubTypes["SoundSensor"] = "SoundSensor";
    SensorSubTypes["TemperatureSensor"] = "TemperatureSensor";
    SensorSubTypes["ToiletPaperCapacitySensor"] = "ToiletPaperCapacitySensor";
    SensorSubTypes["VoltageSensor"] = "VoltageSensor";
    SensorSubTypes["AvailableCapacity"] = "AvailableCapacity";
    SensorSubTypes["PeopleCount"] = "PeopleCount";
})(SensorSubTypes || (SensorSubTypes = {}));
