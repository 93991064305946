export var SpaceType;
(function (SpaceType) {
    SpaceType["Area"] = "Area";
    SpaceType["Campus"] = "Campus";
    SpaceType["Customer"] = "Customer";
    SpaceType["Floor"] = "Floor";
    SpaceType["None"] = "None";
    SpaceType["Region"] = "Region";
    SpaceType["Room"] = "Room";
    SpaceType["Spot"] = "Spot";
    SpaceType["Tenant"] = "Tenant";
    SpaceType["Venue"] = "Venue";
})(SpaceType || (SpaceType = {}));
