interface IRestrictedSpaces {
    [key: string]: string[];
}

export const devRestrictedSpaces: IRestrictedSpaces = {
    "036eff2e-d9aa-40f7-8ddb-6aa2826a1b59": ["9946839f-0bfd-48ee-9e74-cf30ffe8f653"], // 34/5
    "de64cdec-a972-4b83-bec2-454acc100658": [
        "e6adfe23-dc28-492c-98d5-599655cdbd04", // dev 3/P1
        "4ace5995-cf16-4927-95df-a05c982efcdc" // dev 3/P2
    ],
    "50cf4984-0214-488f-b19a-7ab6814bfa52": [
        "ff08d31b-b9fc-42b1-9377-a44e8f05da3a", // dev 4/P1
        "8df37c87-9058-4f33-8338-6c5810cd9b1c" // dev 4/P2
    ],
    "8bea1680-78e2-402c-86b3-f5e6ec84b7d1": [
        "752f93cb-5f4b-4b12-8646-d8dd53684a88", // dev 5/P1
        "b51f09da-5704-4f58-8648-153fbbfb4980" // dev 5/P2
    ],
    "e67cf964-294f-46ae-b82c-9b9555608226": [
        "3e84340c-e82c-4e53-927b-d322da0f4b7d", // dev 6/P1
        "4a2a6859-66e6-4d47-8529-ca17c2f96d5a" // dev 6/P2
    ],
    "50d70927-af0c-4e26-a897-48bdd8f58e4e": [
        "629618dd-db5e-4e86-b07c-6f5bf49a5e2c", // dev 7/P2
        "08eef06c-dbe4-4de6-bed6-e7b8a6b218d8" // dev 7/R1
    ],
    "5e0bfdc7-166d-452d-8857-2ff0d795c211": [
        "6a4e23a9-71f5-4eda-8154-c825d45f90bc", // dev 8/P2
        "1633c487-629b-4bc0-8b06-1fcda2e39a2a" // dev 8/R1
    ],
    "53c0b10c-bb5b-4766-a23f-a5b134a43a4e": [
        "76c7956d-7fe8-4c79-ab1f-823f248f1578", // dev 9/P1
        "813d4998-9029-4eab-a750-e6b9631540d0" // dev 9/P2
    ]
};

export const prodRestrictedSpaces: IRestrictedSpaces = {
    "b58082a7-3a79-474f-9c0a-85f2a9708cec": ["95054f7e-cee1-477e-9111-88253ec1a783"], // 34/5
    "f731fd55-b871-4dd6-8ee4-60448fbb6100": [
        "a1b5c61e-d859-4e55-a2ec-146f29c64bac", // prod 3/P1
        "2a21d5b0-1349-420a-9ab4-a36081e8c83a" // prod 3/P2
    ],
    "0d5c3353-af98-4f0d-96a1-4cd0f25d72fb": [
        "3d8ceb22-63e7-4550-9b40-e5a094a79e04", // prod 4/P1
        "e8dc4785-a419-465e-85ca-e71a47ad18fe" // prod 4/P2
    ],
    "6273ddb2-a03d-46db-9255-71016713a42d": [
        "de8655d1-3935-413c-aae0-f8a23e13fee0", // prod 5/P1
        "1087d75d-92aa-4d09-811b-69073dfdbb06" // prod 5/P2
    ],
    "9e78313c-53e5-42c5-a7f7-69fcdff1ed99": [
        "6b6abf1f-51df-48af-97e2-c852301098fc", // prod 6/P1
        "83e10930-95f8-4d81-8bf9-46a4cfa4364f" // prod 6/P2
    ],
    "4c4171b5-6796-4986-a8e2-33383f62d5e8": [
        "3c454df2-80ef-4d45-9512-8164898e4fd6", // prod 7/P2
        "7d6c8f0a-0dfe-4c90-9e35-805bbf11799c" // prod 7/R1
    ],
    "02a965c3-234b-4978-a382-5f142b190fb8": [
        "a3f27a7c-e9c0-4532-b1ca-97bba73b3a5b", // prod 8/P2
        "e1a6d34f-63ed-497a-b494-6f1aba220e03" // prod 8/R1
    ],
    "7cd3be75-a734-471d-86dd-6abd88bb6ab5": [
        "aee4c6ae-bfdd-4b26-a18a-50cd12e57712", // prod 9/P1
        "25111c1b-906e-4270-9dbb-bc7dc94840c2" // prod 9/P2
    ]
};

export function getRestrictedSpaces(env: string): IRestrictedSpaces {
    return env === "Production" ? prodRestrictedSpaces : devRestrictedSpaces;
}
