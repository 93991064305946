import { AppMetricAction, AppMetricActionType, IAppStateUpdatePayload } from "../Actions/AppMetricsActions";
import { AppState, IAppMetricsStore } from "../Types/AppMetricTypes";

export const initialState: IAppMetricsStore = {
    activityId: "",
    appState: AppState.Unloaded,
    stateTimeStamps: {
        [AppState.Unloaded]: performance.now(),
        [AppState.Loading]: 0,
        [AppState.Loaded]: 0
    }
};

export function appMetricsReducer(
    state: IAppMetricsStore = initialState,
    action: AppMetricActionType
): IAppMetricsStore {
    switch (action.type) {
        case AppMetricAction.UPDATE_APP_STATE: {
            const updateActionPayload: IAppStateUpdatePayload = action.payload as IAppStateUpdatePayload;
            return {
                ...state,
                appState: updateActionPayload.newState,
                stateTimeStamps: {
                    ...state.stateTimeStamps,
                    [action.payload.newState]: action.payload.timeStamp
                }
            };
        }
        case AppMetricAction.ADD_ACTIVITY_ID: {
            return {
                ...state,
                activityId: action.payload.activityId
            };
        }
        case AppMetricAction.REMOVE_ACTIVITY_ID: {
            return {
                ...state,
                activityId: ""
            };
        }
        default:
            return state;
    }
}
