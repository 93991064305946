export var vehicleTrackingLayerZoomLevel = 15;
export var routePathStrokeWidth = 10;
// Colors
export var vehicleMarkerColor = "#107C10";
export var vehicleLabelBackgroundColor = "";
// Label Colors
export var defaultLabelBackgroundColor = "#107C10";
export var defaultLabelColor = "#FFFFFF";
export var defaultFontFamily = "Segoe UI";
export var defaultFontSize = "10px";
export var labelPadding = "5px";
export var labelBorderRadius = "5px";
export var stopLabelPixelOffsetX = 0;
export var stopLabelPixelOffsetY = 25;
export var pickupStopLabelBackgroundColor = "#171717";
export var pickupStopLabelColor = "#FFFFFF";
export var dropoffStopLabelBackgroundColor = "#C50F1F";
export var dropoffStopLabelColor = "#FFFFFF";
// Active Route colors
export var routePathActiveColor = "#359C35";
export var routePathBorderActiveColor = "#0F700F";
export var routePathArrowActiveColor = "#CAEACA";
// InActive Colors
export var routePathInActiveColor = "#C6C6C6";
export var routePathBorderInActiveColor = "#C6C6C6";
export var routePathArrowInActiveColor = "#646464";
// Delayed
export var routePathDelayedColor = "#E0181A";
export var dropoffLocationColor = "#C50F1F";
export var pickupLocationColor = "#171717";
// RouteService
export var routeCalculationRequestTimeout = 10000;
// Directions Indicator
export var directionsIndicatorLineSpacing = 100;
export var directionsIndicatorPlacement = "line";
export var directionsIndicatorAnchor = "center";
export var directionsIndicatorArrowSize = 0.75;
export var arrowupTemplatedIconId = "arrowupTemplatedIconId";
export var templatedIcons = [
    {
        id: arrowupTemplatedIconId,
        templateName: "arrow-up",
        color: "#CAEACA",
        secondaryColor: "black"
    }
];
