import { IPrivacyNoticeBaseProps, IPrivacyNoticeStyles } from "./PrivacyNotice.Types";
import React, { useContext } from "react";
import { PrivacyNoticeBase } from "./PrivacyNotice.Base";
import { ThemeContext } from "../../../theme-provider/ThemeProvider";
import { getPrivacyNoticeStyles } from "./PrivacyNotice.Styles";
import { styled } from "@fluentui/react";

// eslint-disable-next-line @typescript-eslint/naming-convention
const StyledPrivacyNotice = styled<IPrivacyNoticeBaseProps, {}, IPrivacyNoticeStyles>(
    PrivacyNoticeBase,
    getPrivacyNoticeStyles
);

export function PrivacyNotice(): JSX.Element {
    const { theme } = useContext(ThemeContext);
    return <StyledPrivacyNotice theme={theme} />;
}
