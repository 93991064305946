var ArrayUtils = /** @class */ (function () {
    function ArrayUtils() {
    }
    //Intentionally validate any value to see if all elements are of specified type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ArrayUtils.isType = function (type, array) {
        for (var _i = 0, array_1 = array; _i < array_1.length; _i++) {
            var item = array_1[_i];
            if (typeof item !== typeof type) {
                return false;
            }
        }
        return true;
    };
    return ArrayUtils;
}());
export { ArrayUtils };
