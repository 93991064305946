import {
    AppMetricAction,
    AppStateUpdateAction,
    StartTrackingEventAction,
    StopTrackingEventAction
} from "../Actions/AppMetricsActions";
import { AppState, IAppLoggedMetricsData } from "../Types/AppMetricTypes";
import { FloorSelectedAction, FloorSelectionCompleteAction, SpaceSelectedActions } from "../Actions";
import { all, call, select } from "redux-saga/effects";
import { IConfigurationService } from "@smartbuilding/configuration-provider";
import { ILogger } from "@smartbuilding/log-provider";
import { IWebClientConfiguration } from "../../constants";
import { getAppLoggedMetrics } from "../Selectors/AppMetricsSelectors";
import { getBuildingId } from "../Selectors";
import { takeEvery } from "@redux-saga/core/effects";

export class AppMetricsSaga {
    private appStateUpdateEventName = "[React Webclient Metric] App Loaded";
    private floorSwitchEvent = "[React Webclient Metric] Floor Switch Timing";
    private floorSelectionTimings: Record<string, { startTime: number; timeComplete: boolean }> = {};
    private eventStartTimestamp: Record<string, number> = {};

    public constructor(private logger: ILogger, private configService: IConfigurationService<IWebClientConfiguration>) {
        this.watcher = this.watcher.bind(this);
        this.onAppStateUpdate = this.onAppStateUpdate.bind(this);
        this.onFloorSelected = this.onFloorSelected.bind(this);
        this.onFloorSelectionComplete = this.onFloorSelectionComplete.bind(this);
        this.onStartTrackingEvent = this.onStartTrackingEvent.bind(this);
        this.onStopTrackingEvent = this.onStopTrackingEvent.bind(this);
        this.getCustomDimensions = this.getCustomDimensions.bind(this);
    }

    public *watcher(): Generator {
        yield all([
            takeEvery(AppMetricAction.UPDATE_APP_STATE, this.onAppStateUpdate),
            takeEvery(SpaceSelectedActions.FLOOR_SELECTED, this.onFloorSelected),
            takeEvery(SpaceSelectedActions.FLOOR_SELECTION_COMPLETE, this.onFloorSelectionComplete),
            takeEvery(AppMetricAction.START_TRACKING_EVENT, this.onStartTrackingEvent),
            takeEvery(AppMetricAction.STOP_TRACKING_EVENT, this.onStopTrackingEvent)
        ]);
    }

    private *onAppStateUpdate(action: AppStateUpdateAction): Generator {
        if (action.payload.newState === AppState.Loaded) {
            const appMetrics = (yield select(getAppLoggedMetrics)) as IAppLoggedMetricsData;
            this.logger.logEvent(this.appStateUpdateEventName, { ...appMetrics });
        }
    }

    private onFloorSelected(action: FloorSelectedAction): void {
        const floorId = action.payload;
        this.floorSelectionTimings[floorId] = {
            startTime: performance.now(),
            timeComplete: false
        };
    }

    private *onFloorSelectionComplete(action: FloorSelectionCompleteAction): Generator {
        const floorId = action.payload;
        const timingData = this.floorSelectionTimings[floorId];

        if (!timingData.timeComplete) {
            timingData.timeComplete = true;
            const currentTime = performance.now();
            const switchTiming = currentTime - timingData.startTime;
            const properties = (yield call(this.getCustomDimensions)) as Record<string, string>;
            this.logger.logEvent(this.floorSwitchEvent, {
                ...properties,
                switchedToFloorId: floorId,
                timeInMs: switchTiming.toString()
            });
        }
    }

    private onStartTrackingEvent(action: StartTrackingEventAction): void {
        this.eventStartTimestamp[action.payload.event] = action.payload.timeStamp;
    }

    private *onStopTrackingEvent(action: StopTrackingEventAction): Generator {
        const event = action.payload.event;
        const startTime = this.eventStartTimestamp[event] ?? 0;
        const endTime = action.payload.timeStamp ?? 0;

        const properties = (yield call(this.getCustomDimensions)) as Record<string, string>;
        this.logger.logEvent(event, {
            ...properties,
            timeInMs: startTime !== undefined ? (endTime - startTime).toString() : ""
        });
        delete this.eventStartTimestamp[event];
    }

    private *getCustomDimensions(): Generator {
        return {
            BuildingId: (yield select(getBuildingId)) as string,
            Environment: (yield call([this.configService, this.configService.getSetting], "Environment")) as string,
            ApplicationName: (yield call(
                [this.configService, this.configService.getSetting],
                "ApplicationName"
            )) as string,
            AADAppId: (yield call([this.configService, this.configService.getSetting], "AADAppId")) as string
        };
    }
}
