import * as SBClientDarkColorPalette from "./DarkThemeColorPalette";
import { ICustomizations, createTheme } from "office-ui-fabric-react";
import { ISBClientSemanticColors } from "./Theme.Types";

const p = SBClientDarkColorPalette;

const extendedSemanticColors: Partial<ISBClientSemanticColors> = {
    ////////////////////////////////
    ///   Base mapping changes
    ////////////////////////////////
    menuBackground: p.menuBackground,
    bodyStandoutBackground: p.neutralQuaternaryAlt,
    bodyTextChecked: p.neutralDark,
    primaryButtonText: p.black,
    primaryButtonTextHovered: p.black,
    primaryButtonTextPressed: p.black,
    primaryButtonTextDisabled: p.neutralTertiaryAlt,
    primaryButtonBackgroundDisabled: p.neutralLighter,
    accentButtonText: p.black,
    buttonBackground: p.neutralQuaternaryAlt,
    buttonBackgroundChecked: p.neutralLighter,
    buttonBackgroundHovered: p.neutralTertiaryAlt,
    buttonBackgroundCheckedHovered: p.neutralTertiaryAlt,
    buttonBackgroundPressed: p.neutralTertiary,
    buttonTextHovered: p.neutralPrimary,
    buttonTextDisabled: p.neutralTertiaryAlt,
    inputForegroundChecked: p.black,
    menuIcon: p.neutralPrimary,
    menuHeader: p.neutralPrimary,
    listItemBackgroundChecked: p.neutralQuaternaryAlt,
    listItemBackgroundCheckedHovered: p.neutralQuaternary,
    link: p.themeDarkAlt,

    // Message bar colors
    messageText: p.neutralPrimary,
    messageLink: p.themeDarkAlt,
    messageLinkHovered: p.themeDarker,

    infoIcon: p.neutralSecondary,
    errorIcon: p.highStatus,
    blockingIcon: p.highStatus,
    successIcon: p.readyStatus,
    warningIcon: p.neutralPrimary,
    severeWarningIcon: p.mediumStatus,

    infoBackground: p.neutralLighter,
    errorBackground: p.errorBackground,
    blockingBackground: p.blockingBackground,
    warningBackground: p.warningBackground,
    severeWarningBackground: p.severeWarningBackground,
    successBackground: p.successBackground,

    // Error and warnings
    errorText: p.errorText,
    warningHighlight: p.warningHighlight,
    warningText: p.neutralPrimary,

    ////////////////////////////////
    // Extended colors
    ////////////////////////////////

    dashboardBackdrop: p.dashboardBackdrop,
    panelBackground: p.panelBackground,
    overlayBackground: p.overlayBackground,
    nonOverlayBackground: p.nonOverlayBackground,

    // Divider lines
    sectionDividerLine: p.neutralPrimary,
    primaryDividerLine: p.neutralTertiary,
    secondaryDividerLine: p.neutralTertiaryAlt,

    // Text
    headerText: p.black,

    // Nav
    navBackground: p.navBackground,
    navItemBackgroundHovered: p.neutralTertiaryAlt,
    navItemBackgroundPressed: p.neutralTertiary,

    // Status
    criticalStatus: p.criticalStatus,
    highStatus: p.highStatus,
    mediumStatus: p.mediumStatus,
    lowStatus: p.lowStatus,
    readyStatus: p.readyStatus,
    goodStatus: p.goodStatus,
    unkownStatus: p.unkownStatus,

    // Steps
    stepCompleted: p.themePrimary,
    stepCurrent: p.themeLight,
    stepNotStarted: p.neutralTertiaryAlt,
    stepModifierBorder: p.themePrimary,
    stepHover: p.themeDark,
    stepPressed: p.themeDarker,
    stepError: p.highStatus,
    allStepsComplete: p.readyStatus,

    contextualAlertBackground: p.contextualAlertBackground,

    // Help + Feedback
    needHelpForeground: p.black,
    needHelpBackground: p.needHelpBackground,
    needHelpBackgroundPressed: p.needHelpBackgroundPressed,
    feedbackForeground: p.black,
    feedbackBackground: p.neutralTertiaryAlt,
    feedbackBackgroundPressed: p.neutralTertiary,

    // Because the Fabric controls bank on theme.palette.white/black inverting.
    alwaysWhite: p.black,
    alwaysBlack: p.white,

    // fonts
    segoeUI: p.segoeUI,

    // spaces
    hallwayColor: p.darkGrayColor,
    wallAndBordersColor: p.lightGrayColor,
    deskColor: p.blueGrayColor
};

export const sbClientDarkColorTheme: ICustomizations = {
    settings: {
        theme: createTheme({
            palette: {
                themePrimary: p.themePrimary,
                themeLighterAlt: p.themeLighterAlt,
                themeLighter: p.themeLighter,
                themeLight: p.themeLight,
                themeTertiary: p.themeTertiary,
                themeSecondary: p.themeSecondary,
                themeDarkAlt: p.themeDarkAlt,
                themeDark: p.themeDark,
                themeDarker: p.themeDarker,
                neutralLighterAlt: p.neutralLighterAlt,
                neutralLighter: p.neutralLighter,
                neutralLight: p.neutralLight,
                neutralQuaternaryAlt: p.neutralQuaternaryAlt,
                neutralQuaternary: p.neutralQuaternary,
                neutralTertiaryAlt: p.neutralTertiaryAlt,
                neutralTertiary: p.neutralTertiary,
                neutralSecondary: p.neutralSecondary,
                neutralPrimaryAlt: p.neutralPrimaryAlt,
                neutralPrimary: p.neutralPrimary,
                neutralDark: p.neutralDark,
                black: p.black,
                white: p.white,
                whiteTranslucent40: p.whiteTranslucent40
            },
            semanticColors: extendedSemanticColors,
            isInverted: false
        })
    },
    scopedSettings: {}
};

export const sbClientDarkCustomizations: ICustomizations = sbClientDarkColorTheme;

/*
 // Adding more specific styles, create a styles class called SBClientStyles and uncomment below lines

export const sbClientDarkCustomizationsWithStyles: ICustomizations = {
  settings: sbClientDarkColorTheme.settings,
  scopedSettings: SBClientStyles
};
*/
