import {
    LayoutActionsTypes,
    SetDetailsPanelStateAction,
    SetMenuPanelStateAction,
    SetZoomStateAction,
    ToggleDetailsPanelAction,
    ToggleMenuPanelAction
} from "./LayoutActions";

export function toggleDetailsPanelAction(): ToggleDetailsPanelAction {
    return { type: LayoutActionsTypes.TOGGLE_DETAILS_PANEL };
}

export function toggleMenuPanelAction(): ToggleMenuPanelAction {
    return { type: LayoutActionsTypes.TOGGLE_MENU_PANEL };
}

export function setZoomStateAction(flag: boolean): SetZoomStateAction {
    return { type: LayoutActionsTypes.SET_ZOOM_STATE, payload: flag };
}

export function setDetailsPanelStateAction(flag: boolean): SetDetailsPanelStateAction {
    return { type: LayoutActionsTypes.SET_DETAILS_PANEL_STATE, payload: flag };
}

export function setMenuPanelStateAction(flag: boolean): SetMenuPanelStateAction {
    return { type: LayoutActionsTypes.SET_MENU_PANEL_STATE, payload: flag };
}
