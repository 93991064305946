export var FeedbackType;
(function (FeedbackType) {
    FeedbackType["Smile"] = "Smile";
    FeedbackType["Frown"] = "Frown";
    FeedbackType["Idea"] = "Idea";
})(FeedbackType || (FeedbackType = {}));
export var FeedbackAgeGroup;
(function (FeedbackAgeGroup) {
    FeedbackAgeGroup["Undefined"] = "Undefined";
    FeedbackAgeGroup["MinorWithoutParentalConsent"] = "MinorWithoutParentalConsent";
    FeedbackAgeGroup["MinorWithParentalConsent"] = "MinorWithParentalConsent";
    FeedbackAgeGroup["NotAdult"] = "NotAdult";
    FeedbackAgeGroup["Adult"] = "Adult";
    FeedbackAgeGroup["MinorNoParentalConsentRequired"] = "MinorNoParentalConsentRequired";
})(FeedbackAgeGroup || (FeedbackAgeGroup = {}));
export var FeedbackAuthenticationType;
(function (FeedbackAuthenticationType) {
    FeedbackAuthenticationType["MSA"] = "MSA";
    FeedbackAuthenticationType["AAD"] = "AAD";
    FeedbackAuthenticationType["Unauthenticated"] = "Unauthenticated";
})(FeedbackAuthenticationType || (FeedbackAuthenticationType = {}));
export var FeedbackPolicyValue;
(function (FeedbackPolicyValue) {
    FeedbackPolicyValue["Enabled"] = "Enabled";
    FeedbackPolicyValue["Disabled"] = "Disabled";
    FeedbackPolicyValue["NotConfigured"] = "Not Configured";
})(FeedbackPolicyValue || (FeedbackPolicyValue = {}));
export var FeedbackScreenshotInfoImageFormat;
(function (FeedbackScreenshotInfoImageFormat) {
    FeedbackScreenshotInfoImageFormat["JPEG"] = "jpeg";
    FeedbackScreenshotInfoImageFormat["PNG"] = "png";
})(FeedbackScreenshotInfoImageFormat || (FeedbackScreenshotInfoImageFormat = {}));
// All enums should be string
export var FeedbackScreenshotSourceType;
(function (FeedbackScreenshotSourceType) {
    FeedbackScreenshotSourceType["AutoCapture"] = "AutoCapture";
    FeedbackScreenshotSourceType["AutoCaptureWithHtml2Canvas"] = "AutoCaptureWithHtml2Canvas";
    FeedbackScreenshotSourceType["ProvidedAtInitialization"] = "ProvidedAtInitialization";
    FeedbackScreenshotSourceType["DynamicallyProvided"] = "DynamicallyProvided"; // When screenshot is provided dynamically using callback, used in Win32 and Mac Scenarios.
})(FeedbackScreenshotSourceType || (FeedbackScreenshotSourceType = {}));
export var FeedbackUiType;
(function (FeedbackUiType) {
    FeedbackUiType["SidePane"] = "SidePane";
    FeedbackUiType["Modal"] = "Modal";
    FeedbackUiType["NoSurface"] = "NoSurface";
    FeedbackUiType["CallOut"] = "CallOut"; // Used for inscreen pop up dialogue
})(FeedbackUiType || (FeedbackUiType = {}));
export var FeedbackHostPlatformType;
(function (FeedbackHostPlatformType) {
    FeedbackHostPlatformType["Windows"] = "Windows";
    FeedbackHostPlatformType["IOS"] = "iOS";
    FeedbackHostPlatformType["Android"] = "Android";
    FeedbackHostPlatformType["WacTaskPane"] = "WacTaskPane";
    FeedbackHostPlatformType["MacOS"] = "MacOS";
    FeedbackHostPlatformType["Web"] = "Web";
})(FeedbackHostPlatformType || (FeedbackHostPlatformType = {}));
