import {
    IPeopleStore,
    ImageSize,
    PersonBlobImageMap,
    SpacePersonMap,
    SuggestionBlobImageMap
} from "../Types/IPeopleStore";
import {
    PeopleAction,
    PeopleRetrieveActions,
    PeopleScannedActions,
    PeopleSelectedActions,
    PeopleSuggestedActions
} from "../Actions/PeopleActions";
import { IPerson } from "@smartbuilding/people-service";

export const initialState: IPeopleStore = {
    currentUser: undefined,
    featureDisabled: false,
    people: [],
    peopleImageMap: {
        [ImageSize.Large]: {},
        [ImageSize.Small]: {}
    },
    suggestionImageMap: {
        [ImageSize.Large]: {},
        [ImageSize.Small]: {}
    },
    spacePeopleMap: {},
    selectedPerson: undefined,
    peopleCardMap: {},
    scannedPersonCardId: "",
    suggestionsSearchingKey: "",
    peopleSuggestionMap: {},
    badgeScanStatus: ""
};

export function peopleReducer(state = initialState, action: PeopleAction): IPeopleStore {
    switch (action.type) {
        case PeopleRetrieveActions.CURRENT_USER_RETRIEVED:
            return {
                ...state,
                currentUser: {
                    organizerEmailAddress: action.payload.userEmail,
                    organizerName: action.payload.userName,
                    organizerImg: action.payload.image
                }
            };
        case PeopleRetrieveActions.PEOPLE_IN_BUILDING_RETRIEVED:
            return peopleSpaceMapUpdate(state, action.payload);

        case PeopleRetrieveActions.PEOPLE_AT_SPACE_RETRIEVED:
            return peopleSpaceMapUpdate(state, action.payload);

        case PeopleRetrieveActions.PERSON_RETRIEVED:
            return addPersonToStore(state, action.payload);

        case PeopleRetrieveActions.PEOPLE_IMAGES_RETRIEVED:
            return addPeopleImagesToStore(state, action.payload.data, action.payload.size);

        case PeopleSelectedActions.PERSON_SELECTED:
            return { ...state, selectedPerson: state.people.find((p) => p.dtId === action.payload) };

        case PeopleSelectedActions.PERSON_SELECTED_CLEARED:
            return { ...state, selectedPerson: undefined };

        case PeopleRetrieveActions.BADGE_SCAN_STATUS:
            return {
                ...state,
                badgeScanStatus: action.payload
            };

        case PeopleRetrieveActions.PEOPLE_ALIAS_RETRIEVED: {
            return {
                ...state,
                peopleCardMap: { ...state.peopleCardMap, [action.payload.cardId]: action.payload.aliasData }
            };
        }

        case PeopleRetrieveActions.PEOPLE_FEATURES_DISABLED: {
            return {
                ...state,
                featureDisabled: true
            };
        }

        case PeopleScannedActions.PERSON_BADGE_SCANNED:
            return { ...state, scannedPersonCardId: action.payload };

        case PeopleScannedActions.PERSON_SCAN_CLEARED:
            return { ...state, scannedPersonCardId: "" };

        case PeopleSuggestedActions.RETRIEVE_PEOPLE_SUGGESTIONS:
            return { ...state, suggestionsSearchingKey: action.payload };

        case PeopleSuggestedActions.PEOPLE_SUGGESTIONS_RETRIEVED: {
            return {
                ...state,
                peopleSuggestionMap: {
                    ...state.peopleSuggestionMap,
                    [action.payload.aliasInputValue]: action.payload.suggestions
                }
            };
        }

        case PeopleSuggestedActions.SUGGESTION_IMAGE_RETRIEVED: {
            return addSuggestionImagesToStore(state, action.payload.data, action.payload.size);
        }
        default:
            return state;
    }
}

function peopleSpaceMapUpdate(state: IPeopleStore, spacePersonMap: SpacePersonMap): IPeopleStore {
    const peopleToAdd: IPerson[] = [];
    const peopleSpaceMap = { ...state.spacePeopleMap };
    for (const spaceId of Object.keys(spacePersonMap)) {
        // Removing duplicates from list of people
        const peopleAtSpace = removeDuplicatesFromPeople(spacePersonMap[spaceId]);
        const peopleIds = peopleAtSpace.map((p) => p.dtId);

        // We want to maintain keep the person details that was just passed in
        const filteredStatePpl = state.people.filter((person) => !peopleAtSpace.find((p) => p.dtId === person.dtId));
        const peopleToStore = filteredStatePpl.concat(peopleAtSpace);

        if (!peopleSpaceMap[spaceId]) {
            peopleSpaceMap[spaceId] = peopleIds;
        } else {
            const filteredIds = peopleSpaceMap[spaceId].filter((id) => !peopleIds.find((pId) => pId === id));
            peopleSpaceMap[spaceId] = peopleIds.concat(filteredIds);
        }

        peopleToAdd.push(...peopleToStore);
    }

    return { ...state, people: removeDuplicatesFromPeople(peopleToAdd), spacePeopleMap: peopleSpaceMap };
}

function addPersonToStore(state: IPeopleStore, person: IPerson): IPeopleStore {
    const peopleSpaceMap = { ...state.spacePeopleMap };
    const filteredPeople = state.people.filter((p) => p.dtId !== person.dtId);
    const peopleToStore = filteredPeople.concat(person);

    if (!peopleSpaceMap[person.spaceId]) {
        peopleSpaceMap[person.spaceId] = [];
    }
    peopleSpaceMap[person.spaceId] = peopleSpaceMap[person.spaceId].concat(person.dtId);

    return { ...state, people: peopleToStore, spacePeopleMap: peopleSpaceMap };
}

function addPeopleImagesToStore(state: IPeopleStore, imageMap: PersonBlobImageMap, size: ImageSize): IPeopleStore {
    const peopleImageMap = { ...state.peopleImageMap[size] };
    for (const personId of Object.keys(imageMap)) {
        peopleImageMap[personId] = imageMap[personId];
    }

    return { ...state, peopleImageMap: { ...state.peopleImageMap, [size]: peopleImageMap } };
}

function addSuggestionImagesToStore(
    state: IPeopleStore,
    imageMap: SuggestionBlobImageMap,
    size: ImageSize
): IPeopleStore {
    const suggestionImageMap = { ...state.suggestionImageMap[size] };
    for (const upn of Object.keys(imageMap)) {
        suggestionImageMap[upn] = imageMap[upn];
    }

    return { ...state, suggestionImageMap: { ...state.suggestionImageMap, [size]: suggestionImageMap } };
}

function removeDuplicatesFromPeople(people: IPerson[]): IPerson[] {
    return people.reduce((arr, person) => {
        if (arr.find((p) => person.dtId === p.dtId)) {
            return arr;
        } else {
            return arr.concat(person);
        }
    }, [] as IPerson[]);
}
