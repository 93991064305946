import { isString } from "util";
export function createCleanUserQuery(query) {
    var cleanQuery = {};
    if (Array.isArray(query.ids)) {
        cleanQuery.ids = query.ids.sort();
    }
    if (Array.isArray(query.includes)) {
        cleanQuery.includes = query.includes.sort();
    }
    if (isString(query.filter) && query.filter.length > 0) {
        cleanQuery.filter = query.filter;
    }
    if (isString(query.type) && query.type.length > 0) {
        cleanQuery.type = query.type;
    }
    if (isString(query.skiptoken) && query.skiptoken.length > 0) {
        cleanQuery.skiptoken = query.skiptoken;
    }
    return cleanQuery;
}
