import { AppState } from "../Types/AppMetricTypes";
import { Guid } from "@smartbuilding/utilities";
import { IAction } from "./IAction";

export enum AppMetricAction {
    UPDATE_APP_STATE = "UPDATE_APP_STATE",
    ADD_ACTIVITY_ID = "ADD_ACTIVITY_ID",
    REMOVE_ACTIVITY_ID = "REMOVE_ACTIVITY_ID",
    START_TRACKING_EVENT = "START_TRACKING_EVENT",
    STOP_TRACKING_EVENT = "STOP_TRACKING_EVENT"
}

export interface IAppStateUpdatePayload {
    newState: AppState;
    timeStamp: number;
}

export interface IActivityIDPayload {
    activityId: string;
}
export interface ITrackingEventPayload {
    event: string;
    timeStamp: number;
}

export type AppStateUpdateAction = IAction<IAppStateUpdatePayload, AppMetricAction.UPDATE_APP_STATE>;
export type AddActivityIDAction = IAction<IActivityIDPayload, AppMetricAction.ADD_ACTIVITY_ID>;
export type RemoveActivityIDAction = IAction<null, AppMetricAction.REMOVE_ACTIVITY_ID>;
export type StartTrackingEventAction = IAction<ITrackingEventPayload, AppMetricAction.START_TRACKING_EVENT>;
export type StopTrackingEventAction = IAction<ITrackingEventPayload, AppMetricAction.STOP_TRACKING_EVENT>;

//Placeholder for additional actions
export type AppMetricActionType = AppStateUpdateAction | AddActivityIDAction | RemoveActivityIDAction;

export function createAppStateUpdateAction(appState: AppState): AppMetricActionType {
    return {
        type: AppMetricAction.UPDATE_APP_STATE,
        payload: {
            newState: appState,
            timeStamp: performance.now()
        }
    };
}

export function startTrackingEventAction(event: string): StartTrackingEventAction {
    return {
        type: AppMetricAction.START_TRACKING_EVENT,
        payload: {
            timeStamp: performance.now(),
            event
        }
    };
}

export function stopTrackingEventAction(event: string): StopTrackingEventAction {
    return {
        type: AppMetricAction.STOP_TRACKING_EVENT,
        payload: {
            timeStamp: performance.now(),
            event
        }
    };
}

export function addActivityID(): AppMetricActionType {
    return {
        type: AppMetricAction.ADD_ACTIVITY_ID,
        payload: { activityId: Guid.newGuid().toString() }
    };
}

export function removeActivityID(): AppMetricActionType {
    return {
        type: AppMetricAction.REMOVE_ACTIVITY_ID,
        payload: null
    };
}
