var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
/*
    Objects returned in response from DIP ADTv2 APIs can get split into two separate objects with same dtId across pages.
    To overcome this, we merge the split objects into a single object.
*/
export function mergePaginatedResponseObject(lastObject, firstObject) {
    var _a;
    var combinedSpace = {};
    for (var _i = 0, _b = Object.entries(lastObject); _i < _b.length; _i++) {
        var _c = _b[_i], key = _c[0], lastObjectValue = _c[1];
        if (Array.isArray(lastObjectValue)) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            var combinedValue = [];
            var firstObjectValue = firstObject[key];
            if (Array.isArray(firstObjectValue)) {
                combinedValue = __spreadArrays((lastObjectValue || []), (firstObjectValue || []));
            }
            combinedSpace = __assign(__assign(__assign({}, lastObject), firstObject), (_a = {}, _a[key] = combinedValue, _a));
            return combinedSpace;
        }
        else {
            combinedSpace = __assign(__assign({}, lastObject), firstObject);
        }
    }
    return combinedSpace;
}
export function extractTypeFromSpace(model) {
    if (!model || Object.keys(model).length === 0) {
        return "";
    }
    var modelArray = model === null || model === void 0 ? void 0 : model.split(";");
    var arrayWithMetadata = modelArray && modelArray[0].split(":").pop();
    return arrayWithMetadata !== null && arrayWithMetadata !== void 0 ? arrayWithMetadata : "";
}
