import React from "react";
import { createSvgIcon } from "@fluentui/react-icons-mdl2";
// eslint-disable-next-line @typescript-eslint/naming-convention
export var BusyIcon = createSvgIcon({
    // eslint-disable-next-line react/display-name
    svg: function (args) {
        return (React.createElement("svg", { width: "12", height: "12", viewBox: "0 0 12 12", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6Z", fill: "#D74553" })));
    },
    displayName: "BusyIcon"
});
