import { IListViewPageInfo, IPageInfo, IRoomInfo } from "../Types";
import { IInstantBookingConfirmationInfo } from "../../components/DetailsPanel/DetailsPanelProps";
import { IPerson } from "@smartbuilding/people-service";
import { RootState } from "../Reducers/RootReducer";
import { createSelector } from "reselect";
import { getPeople } from "./PeopleSelectors";
import { getRoomMap } from "./SpaceSelectors";

export const getDetailsPanelStack = (store: RootState): Array<IPageInfo> => store.app.detailsPanelPageStack;

export const getCurrentPageInfo = createSelector([getDetailsPanelStack], (stack): IPageInfo | undefined =>
    stack.length > 0 ? stack[stack.length - 1] : undefined
);

export const getSelectedSpace = createSelector([getCurrentPageInfo, getRoomMap], (pageInfo, spaceMap):
    | IRoomInfo
    | undefined => (pageInfo && pageInfo.selectedSpaceId ? spaceMap[pageInfo.selectedSpaceId] : undefined));

export const getSelectedPerson = createSelector([getCurrentPageInfo, getPeople], (pageInfo, people):
    | IPerson
    | undefined =>
    pageInfo && pageInfo.selectedPersonId ? people.find((p) => p.dtId === pageInfo.selectedPersonId) : undefined
);

export const getSelectedCategory = createSelector(
    [getCurrentPageInfo],
    (pageInfo): string | undefined => pageInfo?.selectedCategory
);

export const getSelectedListViewCard = createSelector(
    [getCurrentPageInfo],
    (pageInfo): IListViewPageInfo | undefined => pageInfo?.selectedListViewCard
);

export const getSelectedInstantBookingDuration = createSelector(
    [getCurrentPageInfo],
    (pageInfo): number | undefined => pageInfo?.selectedInstantBookingDuration
);

export const getInstantBookingConfirmationInfo = createSelector(
    [getCurrentPageInfo],
    (pageInfo): IInstantBookingConfirmationInfo | undefined => pageInfo?.instantBookingConfirmationInfo
);

export const getInstantBookingEnabled = (store: RootState): boolean => store.app.instantBookingEnabled;

export const getOCVFeedbackEnabled = (store: RootState): boolean => store.app.ocvFeedbackEnabled;

export const getResetInProgress = (store: RootState): boolean => store.app.resetInProgress;

export const getRoomTagEnabled = (store: RootState): boolean => store.app.roomTagEnabled;
