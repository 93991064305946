import {
    BadgeScanStatusAction,
    CurrentUserRetrievedAction,
    PeopleAliasDataRetrievedAction,
    PeopleAtSpaceRetrievedAction,
    PeopleFeaturesDisabledAction,
    PeopleImagesRetrievedAction,
    PeopleInBuildingRetrievedAction,
    PeopleRetrieveActions,
    PeopleScannedActions,
    PeopleSelectedActions,
    PeopleSuggestedActions,
    PeopleSuggestionsRetrievedAction,
    PersonRetrievedAction,
    PersonScanClearedAction,
    PersonScannedAction,
    PersonSelectedAction,
    PersonSelectionClearedAction,
    RetrieveCurrentUserAction,
    RetrievePeopleAtSpaceAction,
    RetrievePeopleImagesAction,
    RetrievePeopleInBuildingAction,
    RetrievePeopleSuggestionsAction,
    RetrievePersonCardDataAction,
    RetrievePersonWithImgAction,
    RetrieveSuggestionsWithImageAction,
    SuggestionImageRetrievedAction
} from "./PeopleActions";
import { IPersonCardData, IPersonSuggestionData } from "@smartbuilding/smartbuilding-api-service";
import { ImageSize, PersonBlobImageMap, SpacePersonMap, SuggestionBlobImageMap } from "../Types/IPeopleStore";
import { IPerson } from "@smartbuilding/people-service";

export function setCurrentUser(userEmail: string, userName: string | undefined): RetrieveCurrentUserAction {
    return {
        payload: { userEmail, userName },
        type: PeopleRetrieveActions.RETRIEVE_CURRENT_USER
    };
}

export function storeCurrentUser(
    userEmail: string,
    userName: string | undefined,
    image: string | undefined
): CurrentUserRetrievedAction {
    return {
        payload: { userEmail, userName, image },
        type: PeopleRetrieveActions.CURRENT_USER_RETRIEVED
    };
}

export function retrievePeopleInBuilding(buildingId: string): RetrievePeopleInBuildingAction {
    return {
        payload: buildingId,
        type: PeopleRetrieveActions.RETRIEVE_PEOPLE_IN_BUILDING
    };
}

export function peopleInBuildingRetrieved(spacePersonMap: SpacePersonMap): PeopleInBuildingRetrievedAction {
    return {
        payload: spacePersonMap,
        type: PeopleRetrieveActions.PEOPLE_IN_BUILDING_RETRIEVED
    };
}

export function retrievePeopleAtSpace(spaceId: string): RetrievePeopleAtSpaceAction {
    return {
        payload: spaceId,
        type: PeopleRetrieveActions.RETRIEVE_PEOPLE_AT_SPACE
    };
}

export function peopleAtSpaceRetrieved(spaceId: string, people: IPerson[]): PeopleAtSpaceRetrievedAction {
    return {
        payload: { [spaceId]: people },
        type: PeopleRetrieveActions.PEOPLE_AT_SPACE_RETRIEVED
    };
}

export function retrievePersonWithImg(upn: string, size: ImageSize): RetrievePersonWithImgAction {
    return {
        payload: { upn: upn, size: size },
        type: PeopleRetrieveActions.RETRIEVE_PERSON_WITH_IMG
    };
}

export function personRetrieved(person: IPerson): PersonRetrievedAction {
    return {
        payload: person,
        type: PeopleRetrieveActions.PERSON_RETRIEVED
    };
}

export function retrievePersonCardData(cardKeyId: string): RetrievePersonCardDataAction {
    return {
        payload: cardKeyId,
        type: PeopleRetrieveActions.RETRIEVE_PERSON_CARD_DATA
    };
}

export function retrievePeopleImages(peopleIds: string[], imageSize: ImageSize): RetrievePeopleImagesAction {
    return {
        payload: { peopleIds: peopleIds, size: imageSize },
        type: PeopleRetrieveActions.RETRIEVE_PEOPLE_IMAGES
    };
}

export function peopleImagesRetrieved(
    upnImageMap: PersonBlobImageMap,
    imageSize: ImageSize
): PeopleImagesRetrievedAction {
    return {
        payload: { data: upnImageMap, size: imageSize },
        type: PeopleRetrieveActions.PEOPLE_IMAGES_RETRIEVED
    };
}

export function personSelected(personId: string): PersonSelectedAction {
    return {
        payload: personId,
        type: PeopleSelectedActions.PERSON_SELECTED
    };
}

export function clearPersonSelection(): PersonSelectionClearedAction {
    return {
        type: PeopleSelectedActions.PERSON_SELECTED_CLEARED
    };
}

export function personScanned(personId: string): PersonScannedAction {
    return {
        payload: personId,
        type: PeopleScannedActions.PERSON_BADGE_SCANNED
    };
}
export function personCardDataRetrieved(cardId: string, aliasData: IPersonCardData): PeopleAliasDataRetrievedAction {
    return {
        payload: { cardId, aliasData },
        type: PeopleRetrieveActions.PEOPLE_ALIAS_RETRIEVED
    };
}

export function clearPersonScanAction(): PersonScanClearedAction {
    return {
        type: PeopleScannedActions.PERSON_SCAN_CLEARED
    };
}

export function setBadgeScanStatus(status: string): BadgeScanStatusAction {
    return {
        payload: status,
        type: PeopleRetrieveActions.BADGE_SCAN_STATUS
    };
}

export function retrieveSuggestions(aliasInputValue: string): RetrievePeopleSuggestionsAction {
    return {
        payload: aliasInputValue,
        type: PeopleSuggestedActions.RETRIEVE_PEOPLE_SUGGESTIONS
    };
}

export function peopleSuggestionsRetrieved(
    aliasInputValue: string,
    suggestions: IPersonSuggestionData[]
): PeopleSuggestionsRetrievedAction {
    return {
        payload: { aliasInputValue, suggestions },
        type: PeopleSuggestedActions.PEOPLE_SUGGESTIONS_RETRIEVED
    };
}

export function suggestionImageRetrieved(
    suggestionImageMap: SuggestionBlobImageMap,
    imageSize: ImageSize
): SuggestionImageRetrievedAction {
    return {
        payload: { data: suggestionImageMap, size: imageSize },
        type: PeopleSuggestedActions.SUGGESTION_IMAGE_RETRIEVED
    };
}

export function retrieveSuggestionsWithImage(upn: string, size: ImageSize): RetrieveSuggestionsWithImageAction {
    return {
        payload: { upn: upn, size: size },
        type: PeopleSuggestedActions.RETRIEVE_SUGGESTIONS_WITH_IMAGE
    };
}

export function disablePeopleFeatures(): PeopleFeaturesDisabledAction {
    return {
        type: PeopleRetrieveActions.PEOPLE_FEATURES_DISABLED
    };
}
