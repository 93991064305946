var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { data, layer } from "azure-maps-control";
import { removeAllIcons, removeAllLabels, restoreAllIcons, restoreAllLabels } from "../../MapUtilities/AzureMapUtilities";
import { SpaceSymbolLayer } from "./SpaceSymbolLayer";
var featureCategoryProperty = "category";
var featureLevelIdProperty = "levelId";
var SpaceSymbolManager = /** @class */ (function () {
    // on application load
    function SpaceSymbolManager(map, logger, mapFeaturesDataSource) {
        var _this = this;
        this.map = map;
        this.logger = logger;
        this.mapFeaturesDataSource = mapFeaturesDataSource;
        this.menuSpaceSymbolLayer = undefined;
        this.clickSpaceSymbolLayer = undefined;
        this.menuFeatureCollection = [];
        this.clickFeatureCollection = [];
        this.highlightedSpace = "";
        this.enableAnimations = false;
        this.pinLayers = {};
        this.pinLayers["menuPinTopLayer"] = new layer.SymbolLayer(mapFeaturesDataSource, "menuPinTopLayer", {
            visible: false
        });
        this.pinLayers["menuPinTailLayer"] = new layer.SymbolLayer(mapFeaturesDataSource, "menuPinTailLayer", {
            visible: false
        });
        this.pinLayers["clickPinTopLayer"] = new layer.SymbolLayer(mapFeaturesDataSource, "clickPinTopLayer", {
            visible: false
        });
        this.pinLayers["clickPinTailLayer"] = new layer.SymbolLayer(mapFeaturesDataSource, "clickPinTailLayer", {
            visible: false
        });
        this.map.events.add("ready", function () {
            _this.map.layers.add([
                _this.pinLayers["menuPinTailLayer"],
                _this.pinLayers["menuPinTopLayer"],
                _this.pinLayers["clickPinTailLayer"],
                _this.pinLayers["clickPinTopLayer"]
            ]);
        });
        // TODO: this should be updated once we update the azure maps control package. There seems to be a bug with the layers.move function.
        // TODO: look at refactoring all our layers to utilize the indoormapready event.
        this.map.events.add("layeradded", function (e) {
            if (e.getId() === "labels_indoor") {
                _this.map.layers.remove([
                    _this.pinLayers["menuPinTailLayer"],
                    _this.pinLayers["menuPinTopLayer"],
                    _this.pinLayers["clickPinTailLayer"],
                    _this.pinLayers["clickPinTopLayer"]
                ]);
                _this.map.layers.add([
                    _this.pinLayers["menuPinTailLayer"],
                    _this.pinLayers["menuPinTopLayer"],
                    _this.pinLayers["clickPinTailLayer"],
                    _this.pinLayers["clickPinTopLayer"]
                ]);
            }
        });
    }
    SpaceSymbolManager.prototype.enablePinAnimations = function () {
        this.enableAnimations = true;
    };
    // on building load
    SpaceSymbolManager.prototype.initialize = function (categoryRoomMap, pinZoomVisibility, dismissCallback, backClickCallback, 
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    subscribers, telemetryCallback) {
        return __awaiter(this, void 0, void 0, function () {
            var initializationStart, initializationEnd;
            return __generator(this, function (_a) {
                initializationStart = performance.now();
                this.clickSpaceSymbolLayer = new SpaceSymbolLayer(this.map, this.pinLayers["clickPinTopLayer"], this.pinLayers["clickPinTailLayer"], false, pinZoomVisibility, dismissCallback);
                this.menuSpaceSymbolLayer = new SpaceSymbolLayer(this.map, this.pinLayers["menuPinTopLayer"], this.pinLayers["menuPinTailLayer"], true, pinZoomVisibility, backClickCallback, subscribers);
                // set the layer's filter to empty
                this.clickSpaceSymbolLayer.hide();
                this.menuSpaceSymbolLayer.hide();
                // set the layer's visibility to off
                this.menuSpaceSymbolLayer.turnVisibilityOff();
                this.clickSpaceSymbolLayer.turnVisibilityOff();
                if (this.enableAnimations) {
                    // start pins in removed position
                    this.clickSpaceSymbolLayer.remove();
                    this.menuSpaceSymbolLayer.remove();
                }
                else {
                    this.clickSpaceSymbolLayer.noAnimationShow();
                    this.menuSpaceSymbolLayer.noAnimationShow();
                }
                initializationEnd = performance.now();
                this.logger.logEvent("[SpaceSymbolLayer] Space Pins initialized", {
                    initializationTimeMs: initializationEnd - initializationStart
                });
                telemetryCallback === null || telemetryCallback === void 0 ? void 0 : telemetryCallback({
                    messageName: "[SpaceSymbolLayer] Space Pins initialized",
                    timeInMs: initializationEnd - initializationStart,
                    loadEndTime: initializationEnd,
                    epochTimeMs: Date.now()
                });
                return [2 /*return*/];
            });
        });
    };
    SpaceSymbolManager.prototype.renderSpaceSymbolLayer = function (renderSource, featureCollection, buildingLevel, isLayerCurrentlyVisible, centerOffset) {
        if (centerOffset === void 0) { centerOffset = [0, 0]; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(renderSource === "menu")) return [3 /*break*/, 1];
                        this.renderMenuSpaceSymbolLayer(featureCollection, buildingLevel, isLayerCurrentlyVisible);
                        return [3 /*break*/, 3];
                    case 1:
                        if (!(renderSource === "click")) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.renderClickSpaceSymbolLayer(featureCollection, buildingLevel, isLayerCurrentlyVisible, centerOffset)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SpaceSymbolManager.prototype.removeSpaceSymbolLayer = function () {
        var _a, _b, _c, _d;
        this.removeHighlight();
        if (this.enableAnimations) {
            (_a = this.menuSpaceSymbolLayer) === null || _a === void 0 ? void 0 : _a.remove();
            (_b = this.clickSpaceSymbolLayer) === null || _b === void 0 ? void 0 : _b.remove();
        }
        this.menuFeatureCollection = [];
        this.clickFeatureCollection = [];
        (_c = this.menuSpaceSymbolLayer) === null || _c === void 0 ? void 0 : _c.hide();
        (_d = this.clickSpaceSymbolLayer) === null || _d === void 0 ? void 0 : _d.hide();
        if (this.menuSpaceSymbolLayer && this.map) {
            restoreAllIcons(this.map);
            restoreAllLabels(this.map);
        }
    };
    SpaceSymbolManager.prototype.show = function () {
        var _a, _b;
        (_a = this.menuSpaceSymbolLayer) === null || _a === void 0 ? void 0 : _a.turnVisibilityOn();
        (_b = this.clickSpaceSymbolLayer) === null || _b === void 0 ? void 0 : _b.turnVisibilityOn();
        if (this.menuFeatureCollection.length !== 0 && this.map) {
            removeAllIcons(this.map);
            removeAllLabels(this.map);
        }
    };
    SpaceSymbolManager.prototype.hide = function () {
        var _a, _b;
        (_a = this.menuSpaceSymbolLayer) === null || _a === void 0 ? void 0 : _a.turnVisibilityOff();
        (_b = this.clickSpaceSymbolLayer) === null || _b === void 0 ? void 0 : _b.turnVisibilityOff();
        if (this.map) {
            restoreAllIcons(this.map);
            restoreAllLabels(this.map);
        }
    };
    SpaceSymbolManager.prototype.highlightSpace = function (highlightId, category, buildingLevel, isLayerVisible, centerOffset) {
        var _a, _b, _c;
        if (centerOffset === void 0) { centerOffset = [0, 0]; }
        return __awaiter(this, void 0, void 0, function () {
            var spaceLocation;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (!this.enableAnimations) return [3 /*break*/, 2];
                        return [4 /*yield*/, ((_a = this.menuSpaceSymbolLayer) === null || _a === void 0 ? void 0 : _a.unselect())];
                    case 1:
                        _d.sent();
                        _d.label = 2;
                    case 2:
                        spaceLocation = this.findSpaceLocation(highlightId);
                        if (!this.menuFeatureCollection.includes(category) || !spaceLocation) {
                            // check category is currently rendered, check for ghost rooms (1185 in Mill F)
                            return [2 /*return*/];
                        }
                        if (spaceLocation && spaceLocation.levelId !== buildingLevel) {
                            // if space not on current floor, unselect and return.
                            this.highlightedSpace = "";
                            return [2 /*return*/];
                        }
                        this.highlightedSpace = highlightId;
                        if (isLayerVisible && spaceLocation) {
                            this.map.setCamera({
                                center: spaceLocation.coordinate,
                                centerOffset: centerOffset,
                                duration: 200,
                                type: "ease"
                            });
                        }
                        if (this.enableAnimations) {
                            (_b = this.menuSpaceSymbolLayer) === null || _b === void 0 ? void 0 : _b.select(highlightId);
                        }
                        else {
                            (_c = this.menuSpaceSymbolLayer) === null || _c === void 0 ? void 0 : _c.noAnimationSelect(highlightId);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    SpaceSymbolManager.prototype.removeHighlight = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!this.highlightedSpace) return [3 /*break*/, 4];
                        if (!this.enableAnimations) return [3 /*break*/, 2];
                        return [4 /*yield*/, ((_a = this.menuSpaceSymbolLayer) === null || _a === void 0 ? void 0 : _a.unselect())];
                    case 1:
                        _c.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        (_b = this.menuSpaceSymbolLayer) === null || _b === void 0 ? void 0 : _b.noAnimationUnselect();
                        _c.label = 3;
                    case 3:
                        this.highlightedSpace = "";
                        _c.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    SpaceSymbolManager.prototype.dispose = function () {
        var _a, _b, _c, _d;
        (_a = this.clickSpaceSymbolLayer) === null || _a === void 0 ? void 0 : _a.hide();
        (_b = this.menuSpaceSymbolLayer) === null || _b === void 0 ? void 0 : _b.hide();
        (_c = this.clickSpaceSymbolLayer) === null || _c === void 0 ? void 0 : _c.dispose();
        (_d = this.menuSpaceSymbolLayer) === null || _d === void 0 ? void 0 : _d.dispose();
    };
    SpaceSymbolManager.prototype.renderClickSpaceSymbolLayer = function (featureCollection, buildingLevel, isLayerCurrentlyVisible, centerOffset) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var filter, clickedFeature, spaceLocation;
            return __generator(this, function (_c) {
                this.clickFeatureCollection = featureCollection;
                filter = [
                    "all",
                    ["has", "category"],
                    ["in", ["get", "id"], ["literal", this.clickFeatureCollection]],
                    ["==", ["get", "levelId"], buildingLevel]
                ];
                (_a = this.clickSpaceSymbolLayer) === null || _a === void 0 ? void 0 : _a.filter(filter);
                clickedFeature = featureCollection[0];
                spaceLocation = this.findSpaceLocation(clickedFeature);
                if (isLayerCurrentlyVisible && spaceLocation && buildingLevel === spaceLocation.levelId) {
                    this.map.setCamera({
                        center: spaceLocation.coordinate,
                        centerOffset: centerOffset,
                        duration: 200,
                        type: "ease"
                    });
                }
                if (this.enableAnimations)
                    (_b = this.clickSpaceSymbolLayer) === null || _b === void 0 ? void 0 : _b.show();
                this.logger.logEvent("[SpaceSymbolLayer] rendered pin via click on map");
                return [2 /*return*/];
            });
        });
    };
    SpaceSymbolManager.prototype.renderMenuSpaceSymbolLayer = function (featureCollection, buildingLevel, isLayerCurrentlyVisible) {
        var _a, _b, _c;
        if (featureCollection.length === 0)
            return;
        this.clickFeatureCollection = [];
        (_a = this.clickSpaceSymbolLayer) === null || _a === void 0 ? void 0 : _a.hide();
        if (featureCollection.length !== 0 && isLayerCurrentlyVisible && this.map) {
            removeAllIcons(this.map);
            removeAllLabels(this.map);
        }
        this.menuFeatureCollection = featureCollection;
        var filter = [
            "all",
            ["has", "category"],
            ["in", ["get", "category"], ["literal", this.menuFeatureCollection]],
            ["==", ["get", "levelId"], buildingLevel]
        ];
        (_b = this.menuSpaceSymbolLayer) === null || _b === void 0 ? void 0 : _b.filter(filter);
        if (this.enableAnimations)
            (_c = this.menuSpaceSymbolLayer) === null || _c === void 0 ? void 0 : _c.show();
        this.logger.logEvent("[SpaceSymbolLayer] rendered menu pins on map");
    };
    SpaceSymbolManager.prototype.setSpaceSymbolLayerFloor = function (levelId) {
        this.renderSpaceSymbolLayer("menu", this.menuFeatureCollection, levelId, false);
        this.renderSpaceSymbolLayer("click", [], levelId, true);
    };
    SpaceSymbolManager.prototype.findSpaceLocation = function (spaceId) {
        var featureShape = this.mapFeaturesDataSource.getShapeById(spaceId);
        if (featureShape) {
            if (featureShape.getProperties()[featureCategoryProperty]) {
                return {
                    coordinate: data.BoundingBox.getCenter(featureShape.getBounds()),
                    levelId: featureShape.getProperties()[featureLevelIdProperty]
                };
            }
            else {
                return undefined;
            }
        }
        return undefined;
    };
    return SpaceSymbolManager;
}());
export { SpaceSymbolManager };
