export var SensorDataType;
(function (SensorDataType) {
    SensorDataType["ConferenceStatus"] = "ConferenceStatus";
    SensorDataType["ConferenceStatusWithCalendarEvents"] = "ConferenceStatusWithCalendarEvents";
    SensorDataType["ContactSwitch"] = "ContactSwitch";
    SensorDataType["FireExtinguisher"] = "FireExtinguisher";
    SensorDataType["FireExtinguisherPressure"] = "fireExtinguisherPressure";
    SensorDataType["FireExtinguisherPresence"] = "fireExtinguisherPresence";
    SensorDataType["FireExtinguisherObstruction"] = "fireExtinguisherObstruction";
    SensorDataType["Motion"] = "Motion";
    SensorDataType["OccupancyStatus"] = "OccupancyStatus";
    SensorDataType["PaperTowelCapacity"] = "PaperTowelCapacity";
    SensorDataType["Sound"] = "Sound";
    SensorDataType["Temperature"] = "Temperature";
    SensorDataType["ToiletPaperCapacity"] = "ToiletPaperCapacity";
    SensorDataType["AvailableCapacity"] = "AvailableCapacity";
    SensorDataType["PeopleCount"] = "PeopleCount";
})(SensorDataType || (SensorDataType = {}));
