import {
    AEDFilledIcon,
    BombShelterFilledIcon,
    ExitFilledIcon,
    FireExtinguisherFilledIcon,
    SvgIcon,
    TVMonitorIcon
} from "@smartbuilding/ui-components-icons";
import { IMenuPoiBaseProps, IMenuPoiStyleProps, IMenuPoiStyles, defaultMenuPoiButtonLabels } from "./MenuPoi.Types";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { Stack, classNamesFunction } from "@fluentui/react";
import {
    clearPeopleLayer,
    clearPoiLayer,
    clearSpacePinsLayer,
    renderPeopleLayer,
    renderPoiLayer,
    renderSpacePinsLayer
} from "../../../redux/Actions";
import { MenuPanelCustomProperties } from "../MenuPanelCustomProperties";
import { PoiType } from "@smartbuilding/poi-service";
import { ToggleButton } from "@smartbuilding/ui-components-buttons";
import { useDispatch } from "react-redux";

const getClassNames = classNamesFunction<IMenuPoiStyleProps, IMenuPoiStyles>();
export function MenuPoiBase(props: IMenuPoiBaseProps): JSX.Element {
    const { styles, theme, pointsOfInterest, floorId, buildingId, mapLayers } = props;
    const poiButtonLabels = defaultMenuPoiButtonLabels;
    const selectedPoi = useRef<null | PoiType>(null);
    const [poiButtons, setPoiButtons] = useState<PoiType[]>([]);
    const classNames = getClassNames(styles, { theme: theme });
    const dispatch = useDispatch();
    const poiIcons: Record<PoiType, { base: SvgIcon; hoverIcon?: SvgIcon; activeIcon?: SvgIcon }> = {
        KioskLocation: { base: TVMonitorIcon }, // TODO: replace this icon when kioskLocation icon is available
        AEDDevice: { base: AEDFilledIcon },
        BombShelter: { base: BombShelterFilledIcon },
        EmergencyExit: { base: ExitFilledIcon },
        FireExtinguisher: { base: FireExtinguisherFilledIcon }
    };

    useEffect(() => {
        if (!pointsOfInterest) return;
        selectedPoi.current = null;

        const poiButtons: PoiType[] = [];
        Object.keys(pointsOfInterest).forEach((floorId) => {
            if (pointsOfInterest[floorId]) {
                Object.keys(pointsOfInterest[floorId]).forEach((poi) => {
                    const poiType = poi as PoiType;
                    if (
                        pointsOfInterest[floorId][poiType] &&
                        poiType !== PoiType.KioskLocation &&
                        !poiButtons.includes(poiType)
                    ) {
                        poiButtons.push(poiType);
                    }
                });
            }
        });

        props.logger.logEvent("[Menu Poi] rendered", {
            [MenuPanelCustomProperties.PoiButtonCount]: poiButtons.length.toString(),
            [MenuPanelCustomProperties.PoiButtons]: poiButtons.toString()
        });
        setPoiButtons(poiButtons);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pointsOfInterest]);

    useEffect(() => {
        selectedPoi.current = null;
        dispatch(renderPeopleLayer());
    }, [dispatch, floorId]);

    useEffect(() => {
        selectedPoi.current = null;
        dispatch(clearPoiLayer());
    }, [dispatch, buildingId]);

    const onClick = (button: PoiType): void => {
        props.logger.logEvent("[Menu Poi] button clicked", {
            [MenuPanelCustomProperties.PoiButton]: button.toString()
        });

        if (!selectedPoi.current || selectedPoi.current !== button) {
            selectedPoi.current = button;
            dispatch(renderPoiLayer(button));
            dispatch(clearPeopleLayer());
            dispatch(clearSpacePinsLayer());
        } else {
            selectedPoi.current = null;
            dispatch(clearPoiLayer());
            dispatch(renderPeopleLayer());
            dispatch(renderSpacePinsLayer());
        }
    };

    const checkFloorHasPOI = (poiButton: PoiType): boolean => {
        if (floorId && pointsOfInterest[floorId]) {
            return Object.keys(pointsOfInterest[floorId]).includes(poiButton);
        }
        return false;
    };

    const getToggleButton = (poiButton: PoiType): ReactElement | null => {
        switch (poiButton) {
            case PoiType.AEDDevice:
                return (
                    <ToggleButton
                        key={`poi-button-${poiButton.toString()}`}
                        text={poiButtonLabels[poiButton]}
                        icon={poiIcons[poiButton]}
                        orientation="Vertical"
                        disabled={!checkFloorHasPOI(poiButton)}
                        appearance="subtle"
                        onClick={() => onClick(poiButton)}
                        checked={mapLayers.poi.AEDDevice}
                    />
                );
            case PoiType.BombShelter:
                return (
                    <ToggleButton
                        key={`poi-button-${poiButton.toString()}`}
                        text={poiButtonLabels[poiButton]}
                        icon={poiIcons[poiButton]}
                        orientation="Vertical"
                        disabled={!checkFloorHasPOI(poiButton)}
                        appearance="subtle"
                        onClick={() => onClick(poiButton)}
                        checked={mapLayers.poi.BombShelter}
                    />
                );
            case PoiType.EmergencyExit:
                return (
                    <ToggleButton
                        key={`poi-button-${poiButton.toString()}`}
                        text={poiButtonLabels[poiButton]}
                        icon={poiIcons[poiButton]}
                        orientation="Vertical"
                        disabled={!checkFloorHasPOI(poiButton)}
                        appearance="subtle"
                        onClick={() => onClick(poiButton)}
                        checked={mapLayers.poi.EmergencyExit}
                    />
                );
            case PoiType.FireExtinguisher:
                return (
                    <ToggleButton
                        key={`poi-button-${poiButton.toString()}`}
                        text={poiButtonLabels[poiButton]}
                        icon={poiIcons[poiButton]}
                        orientation="Vertical"
                        disabled={!checkFloorHasPOI(poiButton)}
                        appearance="subtle"
                        onClick={() => onClick(poiButton)}
                        checked={mapLayers.poi.FireExtinguisher}
                    />
                );
            case PoiType.KioskLocation:
                return (
                    <ToggleButton
                        key={`poi-button-${poiButton.toString()}`}
                        text={poiButtonLabels[poiButton]}
                        icon={poiIcons[poiButton]}
                        orientation="Vertical"
                        disabled={!checkFloorHasPOI(poiButton)}
                        appearance="subtle"
                        onClick={() => onClick(poiButton)}
                        checked={mapLayers.poi.KioskLocation}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <Stack className={classNames.root} data-testid="menuPoi">
            {poiButtons.map((btn) => getToggleButton(btn))}
        </Stack>
    );
}
