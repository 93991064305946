import { poiTypesLabels } from "./IPoiModels";
import { groupBy } from "@smartbuilding/utilities";
var PoiDetailsService = /** @class */ (function () {
    function PoiDetailsService(logger, spaceRepo) {
        this.logger = logger;
        this.spaceRepo = spaceRepo;
        this.serviceTag = "[PoiDetailsService]";
    }
    PoiDetailsService.prototype.getAllPoiDetails = function (pointsOfInterest) {
        var poiDetailsRecord = {};
        pointsOfInterest.forEach(function (poiData) {
            var _a, _b;
            poiDetailsRecord[poiData.dtId] = {
                name: poiData.name,
                floorId: poiData.floorId ? poiData.floorId : "",
                location: ((_a = poiData === null || poiData === void 0 ? void 0 : poiData.location) === null || _a === void 0 ? void 0 : _a[0]) !== undefined
                    ? (_b = poiData === null || poiData === void 0 ? void 0 : poiData.location) === null || _b === void 0 ? void 0 : _b[0] : {}
            };
        });
        return this.transformDataForAllPoiDetails(poiDetailsRecord);
    };
    PoiDetailsService.prototype.transformDataForAllPoiDetails = function (poiDetailsRecord) {
        var poiDetailsResults = {};
        //grouping by floor
        var groupByFloorPoiData = groupBy(Object.values(poiDetailsRecord), "floorId");
        //Grouping by Poi data
        Object.keys(groupByFloorPoiData).forEach(function (floorId) {
            var poiData = {};
            groupByFloorPoiData[floorId].forEach(function (items) {
                var _a;
                if (!poiData[poiTypesLabels[items.name]]) {
                    poiData[poiTypesLabels[items.name]] = [];
                }
                if (items.location.latitude !== undefined || items.location.longitude !== undefined) {
                    (_a = poiData[poiTypesLabels[items.name]]) === null || _a === void 0 ? void 0 : _a.push({
                        longitude: items.location.longitude,
                        latitude: items.location.latitude
                    });
                }
            });
            poiDetailsResults[floorId] = poiData;
        });
        return poiDetailsResults;
    };
    return PoiDetailsService;
}());
export { PoiDetailsService };
