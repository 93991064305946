var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { SpaceUtilities } from "../SpaceUtilities";
import { createCleanSpaceQuery } from "../CreateCleanSpaceQuery";
import { mergePaginatedResponseObject } from "../../commonUtils/CommonADTFunctions";
import objectHash from "object-hash";
var SpaceRepo = /** @class */ (function () {
    function SpaceRepo(spaceHttpService) {
        var _this = this;
        this.spaceHttpService = spaceHttpService;
        this.serviceTag = "[Space.Repo.Service]";
        this.spaceCache = {};
        this.queryCache = {};
        this.addToQueryCache = function (spaces, spaceQuery, strictIncludes) {
            var spaceIds = spaces.map(function (space) { var _a; return (space.dtId ? (_a = space.dtId) === null || _a === void 0 ? void 0 : _a.toLowerCase() : ""); });
            _this.queryCache[objectHash(__assign(__assign({}, spaceQuery), { strictIncludes: strictIncludes }))] = spaceIds;
            return spaces;
        };
        this.addOrUpdateSpacesInCache = function (spaces) {
            for (var _i = 0, spaces_1 = spaces; _i < spaces_1.length; _i++) {
                var space = spaces_1[_i];
                _this.addOrUpdateEntry(space);
                if (space.children && space.children.length > 0) {
                    _this.addOrUpdateSpacesInCache(space.children);
                }
            }
            return spaces;
        };
        this.addOrUpdateEntry = function (space) {
            var _a;
            var spaceId = (_a = space.dtId) === null || _a === void 0 ? void 0 : _a.toLowerCase();
            if (!spaceId) {
                return;
            }
            var cacheEntry = _this.spaceCache[spaceId];
            if (!cacheEntry) {
                _this.spaceCache[spaceId] = __assign({}, space);
            }
            else {
                _this.spaceCache[spaceId] = __assign(__assign(__assign({}, cacheEntry), space), { calendar: space.calendar ? space.calendar : cacheEntry.calendar, values: space.values ? space.values : cacheEntry.values });
            }
        };
    }
    SpaceRepo.prototype.getSpaceByIdForPathfinding = function (spaceId) {
        return __awaiter(this, void 0, void 0, function () {
            var error;
            return __generator(this, function (_a) {
                if (!spaceId || typeof spaceId !== "string") {
                    error = new Error("[Space.Repo.Service]: invalid argument specified: 'spaceId='" + spaceId);
                    return [2 /*return*/, Promise.reject(error)];
                }
                return [2 /*return*/, this.spaceHttpService.getSpaceByIdForPathfinding(spaceId)];
            });
        });
    };
    SpaceRepo.prototype.getSpaceById = function (spaceId, includes, type) {
        return __awaiter(this, void 0, void 0, function () {
            var error, spaceFromCache, retrievedSpaces, noIncludesResult, includesResult, _a, _b, _c, _d, finalSpace;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        if (!spaceId || typeof spaceId !== "string") {
                            error = new Error("[Space.Repo.Service]: invalid argument specified: 'spaceId='" + spaceId);
                            return [2 /*return*/, Promise.reject(error)];
                        }
                        spaceFromCache = this.cloneSpaceFromCache(spaceId, includes, true);
                        if (spaceFromCache) {
                            return [2 /*return*/, spaceFromCache];
                        }
                        retrievedSpaces = [];
                        noIncludesResult = this.spaceHttpService.getSpaceById(spaceId, [], type);
                        if (includes && includes.length > 0) {
                            includesResult = this.spaceHttpService.getSpaceById(spaceId, includes, type);
                        }
                        _b = (_a = retrievedSpaces).push;
                        return [4 /*yield*/, noIncludesResult];
                    case 1:
                        _b.apply(_a, [_e.sent()]);
                        if (!includesResult) return [3 /*break*/, 3];
                        _d = (_c = retrievedSpaces).push;
                        return [4 /*yield*/, includesResult];
                    case 2:
                        _d.apply(_c, [_e.sent()]);
                        _e.label = 3;
                    case 3:
                        this.addOrUpdateSpacesInCache(retrievedSpaces);
                        finalSpace = this.cloneSpaceFromCache(spaceId, includes);
                        if (!finalSpace) {
                            throw new Error("Space with spaceId=" + spaceId + " could not be found");
                        }
                        return [2 /*return*/, finalSpace];
                }
            });
        });
    };
    SpaceRepo.prototype.getSpaces = function (filter, includes, type, 
    /*
        Only spaces with all listed includes are queried from ADT
        If you as for a space, and it does not have a matching include
        it will not be returned in the results.

        Since this historically not been the desired behavior space repo
        has been designed to do two queries, one with includes and one without
        to get all spaces matching the filter (and not filtering based on includes)

        HOWEVER: since newer code sometimes relies on this behavior of ADT
        included here is a strictIncludes parameter which means that it will
        make only one query, and rely on the includes to filter out spaces that are unwanted
        for example buildings without map data. NOTE: ADT plans to make it so that
        even spaces which do not have all specified includes will be returned
        after which point it will be up to space repo to implement the strictInclude feature
    */
    strictIncludes) {
        if (strictIncludes === void 0) { strictIncludes = false; }
        return __awaiter(this, void 0, void 0, function () {
            var error, spaceQuery, cachedQuerySpaces, includesResult, noIncludesResult, queriedSpaces, _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (!filter && !type) {
                            error = new Error("[Space.Repo.Service]: Please do not query for all spaces at once. Its recommended to use a filter or at least specify a type.");
                            throw error;
                        }
                        spaceQuery = createCleanSpaceQuery({
                            type: type,
                            filter: filter,
                            includes: includes
                        });
                        cachedQuerySpaces = this.getCachedQueriedSpaces(spaceQuery, strictIncludes);
                        if (cachedQuerySpaces) {
                            return [2 /*return*/, cachedQuerySpaces];
                        }
                        includesResult = this.getSpacesHelper(spaceQuery);
                        noIncludesResult = Promise.resolve([]);
                        if (!strictIncludes) {
                            noIncludesResult = this.getSpacesHelper(__assign(__assign({}, spaceQuery), { includes: [] }));
                        }
                        return [4 /*yield*/, includesResult];
                    case 1:
                        _a = [(_d.sent())];
                        return [4 /*yield*/, noIncludesResult];
                    case 2:
                        queriedSpaces = __spreadArrays.apply(void 0, _a.concat([(_d.sent())]));
                        this.addOrUpdateSpacesInCache(queriedSpaces);
                        if (!strictIncludes) return [3 /*break*/, 4];
                        _b = this.addToQueryCache;
                        return [4 /*yield*/, includesResult];
                    case 3:
                        _b.apply(this, [_d.sent(), spaceQuery, strictIncludes]);
                        return [3 /*break*/, 6];
                    case 4:
                        _c = this.addToQueryCache;
                        return [4 /*yield*/, noIncludesResult];
                    case 5:
                        _c.apply(this, [_d.sent(), spaceQuery, strictIncludes]);
                        _d.label = 6;
                    case 6: return [2 /*return*/, this.getCachedQueriedSpaces(spaceQuery, strictIncludes) || []];
                }
            });
        });
    };
    SpaceRepo.prototype.cloneSpaceFromCache = function (spaceId, includes, requireIncludesToMatch) {
        if (includes === void 0) { includes = []; }
        if (requireIncludesToMatch === void 0) { requireIncludesToMatch = false; }
        var spaceFromCache = this.spaceCache[spaceId.toLowerCase()];
        if (spaceFromCache) {
            if (!requireIncludesToMatch || SpaceUtilities.spaceHasIncludes(spaceFromCache, includes)) {
                return JSON.parse(JSON.stringify(spaceFromCache));
            }
        }
        return undefined;
    };
    SpaceRepo.prototype.getCachedQueriedSpaces = function (spaceQuery, strictIncludes) {
        var queryHash = objectHash(__assign(__assign({}, spaceQuery), { strictIncludes: strictIncludes }));
        var cachedQueryResults = this.queryCache[queryHash];
        if (!cachedQueryResults) {
            return undefined;
        }
        var queriedSpaces = [];
        for (var _i = 0, cachedQueryResults_1 = cachedQueryResults; _i < cachedQueryResults_1.length; _i++) {
            var spaceId = cachedQueryResults_1[_i];
            var spaceFromCache = this.cloneSpaceFromCache(spaceId, spaceQuery.includes);
            if (spaceFromCache) {
                queriedSpaces.push(spaceFromCache);
            }
        }
        return queriedSpaces;
    };
    SpaceRepo.prototype.getSpacesHelper = function (spaceQuery) {
        return __awaiter(this, void 0, void 0, function () {
            var collatedSpaces, spacesResponse, lastSpace, firstSpace, combinedSpace, nextQueryToken;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        collatedSpaces = [];
                        _a.label = 1;
                    case 1:
                        if (!spaceQuery) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.spaceHttpService.getSpaces(spaceQuery)];
                    case 2:
                        spacesResponse = _a.sent();
                        if (collatedSpaces.length > 0) {
                            lastSpace = collatedSpaces[collatedSpaces.length - 1];
                            firstSpace = spacesResponse.items[0];
                            if (firstSpace.dtId === lastSpace.dtId) {
                                combinedSpace = mergePaginatedResponseObject(lastSpace, firstSpace);
                                collatedSpaces.pop();
                                collatedSpaces.push(combinedSpace);
                                spacesResponse.items.shift();
                            }
                        }
                        Array.prototype.push.apply(collatedSpaces, spacesResponse.items);
                        nextQueryToken = this.getSkipToken(spacesResponse.nextPageLink);
                        spaceQuery = nextQueryToken ? __assign(__assign({}, spaceQuery), { skiptoken: nextQueryToken }) : undefined;
                        return [3 /*break*/, 1];
                    case 3: return [2 /*return*/, collatedSpaces];
                }
            });
        });
    };
    SpaceRepo.prototype.getSkipToken = function (url) {
        if (!url) {
            return "";
        }
        var urlObj = new URL(url);
        return new URLSearchParams(urlObj.search).get("skiptoken") || "";
    };
    return SpaceRepo;
}());
export { SpaceRepo };
