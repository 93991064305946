import { IPathMetadata, IPathRequest, generateMyHubUrlMapKey, generatePathMetadataKey } from "../Types";
import { RootState } from "../Reducers/RootReducer";
import { createSelector } from "reselect";
import { getFloor } from "./SpaceSelectors";

export const getPathMetadataMap = (store: RootState): Record<string, IPathMetadata> => store.pathFinding.pathMetadata;
export const getMyHubUrlMap = (store: RootState): Record<string, string> => store.pathFinding.mobileHandOffMap;
export const getSelectedPathRequest = (store: RootState): IPathRequest | undefined => store.pathFinding.selectedPath;
export const getWayfindingSupportedStatusMap = (store: RootState): Record<string, boolean> =>
    store.pathFinding.wayFindingSupportedStatus;

export const getSelectedPathMapKey = createSelector([getSelectedPathRequest], (request) =>
    request ? generatePathMetadataKey(request) : undefined
);
export const getSelectedPathMetadata = createSelector(
    [getPathMetadataMap, getSelectedPathMapKey],
    (pathMetadataMap, mapKey) => (mapKey ? pathMetadataMap[mapKey] : undefined)
);
export const getSelectedRouteData = createSelector(
    [getSelectedPathMetadata],
    (pathMetadata) => pathMetadata?.routeData
);
export const getFloorWayfindingSupportedStatus = createSelector(
    [getWayfindingSupportedStatusMap, getFloor],
    (supportedMap, floor) => (floor ? supportedMap[floor.id] : undefined)
);
export const getQRCodeParam = createSelector([getSelectedPathMetadata], (pathMetadata) => pathMetadata?.qrCodeParams);
export const getSelectedUrl = createSelector([getMyHubUrlMap, getQRCodeParam], (myhubUrlMap, qrCodeParams) => {
    if (!qrCodeParams) return undefined;
    return myhubUrlMap[generateMyHubUrlMapKey(qrCodeParams)];
});
