export var SpaceSubtype;
(function (SpaceSubtype) {
    SpaceSubtype["AtriumvoidArea"] = "AtriumVoidArea";
    SpaceSubtype["AuditoriumArea"] = "AuditoriumArea";
    SpaceSubtype["Bathroom"] = "Bathroom";
    SpaceSubtype["BreakRoom"] = "BreakRoom";
    SpaceSubtype["BreakoutArea"] = "BreakoutArea";
    SpaceSubtype["CafeArea"] = "CafeArea";
    SpaceSubtype["CafeteriaArea"] = "CafeteriaArea";
    SpaceSubtype["ChairSpot"] = "ChairSpot";
    SpaceSubtype["Classroom"] = "Classroom";
    SpaceSubtype["CommercialBuildingVenue"] = "CommercialBuildingVenue";
    SpaceSubtype["CommercialOfficeCustomer"] = "CommercialOfficeCustomer";
    SpaceSubtype["ConferenceRoom"] = "ConferenceRoom";
    SpaceSubtype["ConversationRoom"] = "ConversationRoom";
    SpaceSubtype["CopyRoom"] = "CopyRoom";
    SpaceSubtype["CopySpot"] = "CopySpot";
    SpaceSubtype["CountryRegion"] = "CountryRegion";
    SpaceSubtype["CustomerServiceVenue"] = "CustomerServiceVenue";
    SpaceSubtype["DeskSpot"] = "DeskSpot";
    SpaceSubtype["DoorSpot"] = "DoorSpot";
    SpaceSubtype["EducationCustomer"] = "EducationCustomer";
    SpaceSubtype["ElevatorSpot"] = "ElevatorSpot";
    SpaceSubtype["EngineeringVenue"] = "EngineeringVenue";
    SpaceSubtype["EntertainmentCustomer"] = "EntertainmentCustomer";
    SpaceSubtype["EntranceSpot"] = "EntranceSpot";
    SpaceSubtype["FieldServiceVenue"] = "FieldServiceVenue";
    SpaceSubtype["FitnessArea"] = "FitnessArea";
    SpaceSubtype["FocusRoom"] = "FocusRoom";
    SpaceSubtype["FoodservicesVenue"] = "FoodservicesVenue";
    SpaceSubtype["GarbageCollectionSpot"] = "GarbageCollectionSpot";
    SpaceSubtype["GeographyRegion"] = "GeographyRegion";
    SpaceSubtype["GlassRoom"] = "GlassRoom";
    SpaceSubtype["HallwayArea"] = "HallwayArea";
    SpaceSubtype["HealthcareCustomer"] = "HealthcareCustomer";
    SpaceSubtype["HospitalityCustomer"] = "HospitalityCustomer";
    SpaceSubtype["JanitorclosetRoom"] = "JanitorClosetRoom";
    SpaceSubtype["KitchenArea"] = "KitchenArea";
    SpaceSubtype["LabRoom"] = "LabRoom";
    SpaceSubtype["LectureRoom"] = "LectureRoom";
    SpaceSubtype["LifescienceCustomer"] = "LifescienceCustomer";
    SpaceSubtype["LobbyArea"] = "LobbyArea";
    SpaceSubtype["LoungeArea"] = "LoungeArea";
    SpaceSubtype["Mailroom"] = "Mailroom";
    SpaceSubtype["MakerSpaceArea"] = "MakerSpaceArea";
    SpaceSubtype["ManufacturingArea"] = "ManufacturingArea";
    SpaceSubtype["ManufacturingCustomer"] = "ManufacturingCustomer";
    SpaceSubtype["ManufacturingVenue"] = "ManufacturingVenue";
    SpaceSubtype["MarketingVenue"] = "MarketingVenue";
    SpaceSubtype["MechanicalelectricalRoom"] = "MechanicalElectricalRoom";
    SpaceSubtype["MeditationMultifaithRoom"] = "MeditationMultiFaithRoom";
    SpaceSubtype["MenBathroom"] = "MenBathroom";
    SpaceSubtype["MothersRoom"] = "MothersRoom";
    SpaceSubtype["MultipurposeRoom"] = "MultipurposeRoom";
    SpaceSubtype["NamedRegion"] = "NamedRegion";
    SpaceSubtype["NeighborhoodArea"] = "NeighborhoodArea";
    SpaceSubtype["None"] = "None";
    SpaceSubtype["OfficeArea"] = "OfficeArea";
    SpaceSubtype["OfficeRoom"] = "OfficeRoom";
    SpaceSubtype["OpenOfficeArea"] = "OpenOfficeArea";
    SpaceSubtype["OpenSeatingArea"] = "OpenSeatingArea";
    SpaceSubtype["OpenSpaceArea"] = "OpenSpaceArea";
    SpaceSubtype["OtherArea"] = "OtherArea";
    SpaceSubtype["OtherRoom"] = "OtherRoom";
    SpaceSubtype["OtherSpot"] = "OtherSpot";
    SpaceSubtype["ParkingArea"] = "ParkingArea";
    SpaceSubtype["ParkingGarageArea"] = "ParkingGarageArea";
    SpaceSubtype["PatientRoom"] = "PatientRoom";
    SpaceSubtype["PhoneRoom"] = "PhoneRoom";
    SpaceSubtype["PrinterSpot"] = "PrinterSpot";
    SpaceSubtype["PrivateOfficeRoom"] = "PrivateOfficeRoom";
    SpaceSubtype["PublicSectorCustomer"] = "PublicSectorCustomer";
    SpaceSubtype["ReceptionArea"] = "ReceptionArea";
    SpaceSubtype["RecreationGamingArea"] = "RecreationGamingArea";
    SpaceSubtype["ResidentialBuildingVenue"] = "ResidentialBuildingVenue";
    SpaceSubtype["RetailCustomer"] = "RetailCustomer";
    SpaceSubtype["SalesVenue"] = "SalesVenue";
    SpaceSubtype["ServerRoom"] = "ServerRoom";
    SpaceSubtype["ShowerLockerRoom"] = "ShowerLockerRoom";
    SpaceSubtype["StairwellArea"] = "StairwellArea";
    SpaceSubtype["Stockroom"] = "Stockroom";
    SpaceSubtype["StorageArea"] = "StorageArea";
    SpaceSubtype["StoreVenue"] = "StoreVenue";
    SpaceSubtype["SurgeryRoom"] = "SurgeryRoom";
    SpaceSubtype["TeamRoom"] = "TeamRoom";
    SpaceSubtype["TechLinkArea"] = "TechLinkArea";
    SpaceSubtype["TestArea"] = "TestArea";
    SpaceSubtype["TestCustomer"] = "TestCustomer";
    SpaceSubtype["TestFloor"] = "TestFloor";
    SpaceSubtype["TestRegion"] = "TestRegion";
    SpaceSubtype["TestRoom"] = "TestRoom";
    SpaceSubtype["TestSpot"] = "TestSpot";
    SpaceSubtype["TestTenant"] = "TestTenant";
    SpaceSubtype["TestVenue"] = "TestVenue";
    SpaceSubtype["TransitionArea"] = "TransitionArea";
    SpaceSubtype["TransportationCustomer"] = "TransportationCustomer";
    SpaceSubtype["VendingSpot"] = "VendingSpot";
    SpaceSubtype["VoidArea"] = "VoidArea";
    SpaceSubtype["WellbeingArea"] = "WellbeingArea";
    SpaceSubtype["WomenBathroom"] = "WomenBathroom";
    SpaceSubtype["WorkstationArea"] = "WorkstationArea";
})(SpaceSubtype || (SpaceSubtype = {}));
