export enum AppState {
    //App has not begun loading
    Unloaded = "Unloaded",
    //App is currently initializing.
    Loading = "Loading",
    //App is in useable state
    Loaded = "Loaded"
}

export interface IAppMetricsStore {
    activityId: string;
    appState: AppState;
    //Millisecond timestamp
    stateTimeStamps: Record<AppState, number>;
}

export interface IAppLoggedMetricsData {
    activityId: string;
    domCompleteAfterMs: string;
    domInteractiveAfterMs: string;
    appUseableAfterMs: string;
}
