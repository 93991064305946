import { IPrivacyNoticeStyles } from "./PrivacyNotice.Types";

export function getPrivacyNoticeStyles(): IPrivacyNoticeStyles {
    return {
        rootContainer: {
            paddingLeft: "12px",
            paddingRight: "12px"
        },
        modalContainer: {
            display: "flex",
            alignItems: "center",
            paddingBottom: "20px",
            backgroundColor: "white",

            ".ms-Modal-scrollableContent": {
                display: "flex",
                flexFlow: "column",
                alignItems: "center"
            }
        },
        noticeStyle: {
            color: "#000000",
            fontSize: "16px",
            wordBreak: "break-word",
            width: "240px",
            fontWeight: "bold",
            lineHeight: "16px",
            paddingBottom: "5px",
            textAlign: "center"
        },

        urlStyle: {
            color: "#0000ff",
            fontSize: "12px",
            wordBreak: "break-word",
            fontWeight: "400px",
            lineHeight: "16px",
            width: "240px",
            paddingBottom: "5px",
            textAlign: "center"
        },

        cancelIconButton: {
            marginLeft: "auto",
            marginTop: "4px",
            marginRight: "2px",
            backgroundColor: "transparent",
            color: "#000000",
            fontSize: "medium",
            ":hover": {
                backgroundColor: "transparent",
                color: "#000000"
            },
            ":active": {
                backgroundColor: "transparent",
                color: "#000000"
            }
        }
    };
}
