export var TraversalSpaceSubtype;
(function (TraversalSpaceSubtype) {
    TraversalSpaceSubtype["ElevatorSpot"] = "ElevatorSpot";
    TraversalSpaceSubtype["StairwellArea"] = "StairwellArea";
})(TraversalSpaceSubtype || (TraversalSpaceSubtype = {}));
export var TraversalSpaceType;
(function (TraversalSpaceType) {
    TraversalSpaceType["Area"] = "Area";
    TraversalSpaceType["Spot"] = "Spot";
})(TraversalSpaceType || (TraversalSpaceType = {}));
