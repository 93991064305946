import { SpaceHasIncludes } from "@smartbuilding/adt-v2-types";
var SpaceUtilities = /** @class */ (function () {
    function SpaceUtilities() {
    }
    SpaceUtilities.spaceHasIncludes = function (space, includes) {
        if (!Array.isArray(includes) || includes.length === 0) {
            return true;
        }
        for (var _i = 0, includes_1 = includes; _i < includes_1.length; _i++) {
            var prop = includes_1[_i];
            switch (prop) {
                case SpaceHasIncludes.HasLocation:
                    if (!space.location) {
                        return false;
                    }
                    break;
                case SpaceHasIncludes.HasAddress:
                    if (!space.address) {
                        return false;
                    }
                    break;
                case SpaceHasIncludes.HasChildren:
                    if (!space.children) {
                        return false;
                    }
                    break;
                case SpaceHasIncludes.HasSensors:
                    if (!space.sensors) {
                        return false;
                    }
                    break;
                case SpaceHasIncludes.HasValues:
                    if (!space.values) {
                        return false;
                    }
                    break;
                case SpaceHasIncludes.IsInTimezone:
                    if (!space.timeZone) {
                        return false;
                    }
                    break;
                case SpaceHasIncludes.HasParent:
                    if (!space.parent) {
                        return false;
                    }
                    break;
                case SpaceHasIncludes.HasPeople:
                    if (!space.people) {
                        return false;
                    }
                    break;
                case SpaceHasIncludes.HasMapData:
                    if (!space.mapData) {
                        return false;
                    }
                    break;
                default:
                    throw new Error("[SpaceUtilities]: Error parsing space includes: No check for " + prop + ".");
            }
        }
        return true;
    };
    return SpaceUtilities;
}());
export { SpaceUtilities };
