import { ILogger } from "@smartbuilding/log-provider";
import { MapErrorEvent } from "azure-maps-control";
import { Observer } from "./Observer";

export class MapErrorObserver extends Observer {
    public constructor(private logger: ILogger) {
        super();
        this.logError = this.logError.bind(this);
        this.subscribe(this.logError);
    }

    private logError(error: unknown): void {
        const err = error instanceof Error ? error : (error as MapErrorEvent).error;
        this.logger.logError(err, {
            message: "[AzureMaps] Error Event"
        });
    }
}
