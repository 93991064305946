import { GenericSpaceCard, IOrganizerDetails, PageStatus } from "@smartbuilding/ui-components-space-card";
import { IDetailsPanelSpaceCardProps, spaceCapabilitiesIconMap } from "../DetailsPanelProps";
import {
    IPersonSuggestionData,
    ISlotsInformation,
    ISmartBuildingApiRoomHttp
} from "@smartbuilding/smartbuilding-api-service";
import { Provider, serviceIdentifiers } from "../../../serviceContainer/ServiceIdentifiers";
import React, { useEffect, useRef, useState } from "react";
import {
    TimeSlot,
    createReservationTimeSlot,
    findLastSelectedTimeSlot,
    generateTimeSlotsList,
    getFirstPrecheckedSlot,
    handleNonContiguousSelection
} from "./BookableSpaceCardUtilities";
import {
    cancelBookingConfirmation,
    getCurrentFreeSlots,
    isRoomBookable,
    spaceBookingConfirmation
} from "./useBookableSpaceCardUtilities";
import {
    clearPersonScanAction,
    personScanned,
    retrievePersonWithImg,
    retrieveSuggestions,
    retrieveSuggestionsWithImage,
    setBadgeScanStatus
} from "../../../redux/Actions";
import {
    getBadgeScanStatus,
    getBuildingName,
    getCurrentUser,
    getFloors,
    getPersonCardData,
    getPersonImageMap,
    getRoomTagEnabled,
    getSelectedPathMetadata,
    getSuggestionImageMap,
    getSuggestions
} from "../../../redux/Selectors";
import { getIsDetailsPanelOpen, getIsZoomed } from "../../../redux/Selectors/LayoutSelectors";
import { useDispatch, useSelector } from "react-redux";
import { BookingErrorMessages } from "../../../constants/error-constant";
import { IAuthClient } from "@smartbuilding/auth-client";
import { ICheckableListItem } from "@smartbuilding/ui-components-list";
import { ILogger } from "@smartbuilding/log-provider";
import { ImageSize } from "../../../redux/Types";
import { SpaceTypeNames /*, getStatusIcon*/ } from "@smartbuilding/utilities";
import { SvgIcon } from "@smartbuilding/ui-components-icons";
import converter from "number-to-words";
import { electronService } from "@smartbuilding/electron-service";
import { toggleDetailsPanelAction } from "../../../redux/Actions/LayoutActionCreator";
import { updateRoomBookingStatus } from "../../../redux/Actions/RoomBookingActionsCreator";
import { useBadgeScanFeatureFlag } from "./useBadgeScanStatusFlag";
import { useBookableSpaceCardStyles } from "./useBookableSpaceCardStyles";
import { useDirectionController } from "../useDirectionController";
import { useInjection } from "../../../serviceContainer/ServiceContainerProvider";
import { useMyHubPathFindingQRCodeUrl } from "../useMyHubPathFindingQRCodeURL";
import { useRoomStatus } from "../../../hooks/useRoomStatus";

export function BookableSpaceCard(props: IDetailsPanelSpaceCardProps): JSX.Element {
    const { selectedSpace, onPanelBackClickCallback, onPanelDismissCallback, instantBookingConfirmationInfo } = props;
    const styles = useBookableSpaceCardStyles();
    const spaceBookingSubject = "Smart Building Booking";
    const loggerMsgTitle = "Bookable Space Card";
    const msDomain = "@microsoft.com";
    const numberOfSlots = 5;
    const spaceBookingBodyText = "<html> This meeting was booked using Smart Buildings. </html>";
    const defaultEmptyAvailableTimesText = "There are no times for Smartbuilding to book right now.";
    const confAlias = selectedSpace.cardAttributes.conferenceRoomAlias ?? "";
    const spaceHttpProvider = useInjection<Provider<ISmartBuildingApiRoomHttp>>(
        serviceIdentifiers.smartBuildingApiRoomHttpService
    );
    const logger = useInjection<ILogger>(serviceIdentifiers.logger);
    const authClient = useInjection<IAuthClient>(serviceIdentifiers.authClient);
    const buildingName = useSelector(getBuildingName) ?? "";
    const floors = useSelector(getFloors);
    const pathMetadata = useSelector(getSelectedPathMetadata);
    const isDetailsPanelOpen = useSelector(getIsDetailsPanelOpen);
    const isZoomed = useSelector(getIsZoomed);
    const currentUserInfo = useSelector(getCurrentUser);
    const personImage = useSelector(getPersonImageMap)[ImageSize.Small];
    const personCardInfo = useSelector(getPersonCardData);
    const suggestionImage = useSelector(getSuggestionImageMap)[ImageSize.Small];
    const suggestions = useSelector(getSuggestions);
    const isReservationCancelled = useRef<boolean>(false);
    const suggestionDebounceTimeout = useRef<undefined | NodeJS.Timeout>(undefined);
    const { status } = useRoomStatus(selectedSpace.cardAttributes.id);
    const [floorName, setFloorName] = useState("");
    const [pageStatus, setPageStatus] = useState<PageStatus>(PageStatus.Default);
    const [timeSlotMap, setTimeSlotMap] = useState<Record<string, ISlotsInformation>>({});
    const [availableTimes, setAvailableTimes] = useState<Array<ICheckableListItem>>([]);
    const [isAvailableTimesLoading, setIsAvailableTimesLoading] = useState(false);
    const [currentSlotInformation, setCurrentSlotInformation] = useState<ISlotsInformation | undefined>();
    const [reservationEventId, setReservationEventId] = useState("");
    const [reservationMsg, setReservationMsg] = useState<string | undefined>(undefined);
    const [aliasInputValue, setAliasInputValue] = useState("");
    const [aliasInputErrorMessage, setAliasInputErrorMessage] = useState("");
    const [organizerDetails, setOrganizerDetails] = useState<IOrganizerDetails | undefined>(undefined);
    const badgeScanStatus = useSelector(getBadgeScanStatus);
    const prevBadgeCardRef = useRef<string>("");
    const [showSuggestions, setShowSuggestions] = useState(false);
    const lastKeyPressTime = useRef<number>(new Date().getTime());
    const typingFrom = useRef<string>("Person");
    const scannedPersonImage = personCardInfo ? personImage[personCardInfo.dtId] : undefined;
    const badgeScanFlagFromFeatureFlighting = useBadgeScanFeatureFlag();
    const enableRoomTagFlag = useSelector(getRoomTagEnabled);
    const dispatch = useDispatch();
    const toggleDetailsPanel = (): void => {
        dispatch(toggleDetailsPanelAction());
    };
    const isBookable = isRoomBookable(selectedSpace);
    const pathfindingQRCodeUrl = useMyHubPathFindingQRCodeUrl();
    const {
        wayFindingSupported,
        useElevator,
        onUseElevatorValueChange,
        onDirectionClick,
        resetDirectionButton,
        directionsErrorMessage
    } = useDirectionController(
        selectedSpace.cardAttributes.id,
        selectedSpace.cardAttributes.floorId,
        logger,
        loggerMsgTitle,
        authClient,
        electronService.isElectron(),
        setPageStatus,
        pathMetadata
    );

    const handleBackClickAction = (): void => {
        if (!isBookable || pageStatus === PageStatus.Default) {
            dispatch(clearPersonScanAction());
            onPanelBackClickCallback();
        } else if (
            pageStatus === PageStatus.DirectionsEnd ||
            pageStatus === PageStatus.DirectionsRetrieving ||
            pageStatus === PageStatus.BookingCancel
        ) {
            setPageStatus(PageStatus.Default);
            dispatch(updateRoomBookingStatus(false));
        }
    };

    const getNavHeaderAriaLabel = (): string => {
        const mainPageLabel = `${SpaceTypeNames[selectedSpace.type]}s ${getFloorBaseText(floorName)}`;

        switch (pageStatus) {
            case PageStatus.Default:
                if (isReservationCancelled.current) {
                    return `${getCancelLabel()} ${mainPageLabel}`;
                }
                return mainPageLabel;
            case PageStatus.BookingCancel:
                return `${SpaceTypeNames[selectedSpace.type]} ${selectedSpace.cardAttributes.spaceName} reserved.`;
            case PageStatus.DirectionsEnd:
                return `Directions to ${SpaceTypeNames[selectedSpace.type]} ${selectedSpace.cardAttributes.spaceName}`;
            default:
                return "";
        }
    };

    const getFloorBaseText = (floorName: string): string => {
        const floorNumber = parseInt(floorName);
        return (!isNaN(floorNumber) ? converter.toOrdinal(floorNumber) : floorName) + " floor";
    };

    //To-Do: Clean up timer
    const getCancelLabel = (): string => {
        setTimeout(() => {
            isReservationCancelled.current = false;
        }, 100);
        return `${SpaceTypeNames[selectedSpace.type]} ${selectedSpace.cardAttributes.spaceName} reservation cancelled.`;
    };

    const getButtonDescribedBy = (backBtnOrNot: boolean): string => {
        switch (pageStatus) {
            case PageStatus.Default:
                return backBtnOrNot ? "booking-back-button" : "booking-close-button";
            case PageStatus.BookingCancel:
                return backBtnOrNot ? "confirm-back-button" : "confirm-close-button";
            case PageStatus.DirectionsEnd:
                return backBtnOrNot ? "directions-back-button" : "directions-close-button";
            default:
                return "";
        }
    };

    const getSpaceFeatureList = (): { icon: SvgIcon; text: string }[] | undefined => {
        const featureList: { icon: SvgIcon; text: string }[] | undefined = selectedSpace.cardAttributes.roomCapabilities
            ? Object.values(selectedSpace.cardAttributes.roomCapabilities).map((capability) => ({
                  icon: spaceCapabilitiesIconMap[capability.title],
                  text: capability.title
              }))
            : undefined;
        if (selectedSpace.cardAttributes.roomCapacity !== undefined) {
            const roomCapacity = {
                icon: spaceCapabilitiesIconMap["Teams"],
                text: selectedSpace.cardAttributes.roomCapacity?.toString() + " person space"
            };
            featureList?.unshift(roomCapacity);
        }

        return featureList;
    };

    const getSpaceTags = (): { icon: SvgIcon; text: string }[] => {
        if (!selectedSpace.tags || !enableRoomTagFlag) return [];
        const featureList = getSpaceFeatureList();
        const featureListTags = featureList ? featureList.map((feature) => feature.text) : [];
        return selectedSpace.tags
            .filter((tag) => !featureListTags.includes(tag))
            .map((tag) => ({
                icon: spaceCapabilitiesIconMap[tag] || null,
                text: tag
            }));
    };

    const getAvailableTimes = (): ICheckableListItem[] => {
        return availableTimes.length > 0 ? availableTimes : [];
    };

    const handleAvailableTimesChange = (
        checkedItems: Record<string, boolean>,
        selectedItem?: ICheckableListItem
    ): void => {
        if (!selectedItem) return;

        const timeSlots: TimeSlot[] = generateTimeSlotsList(checkedItems, timeSlotMap);
        const firstSelectedTimeSlotIndex = timeSlots.findIndex((slot) => slot.isChecked);
        const firstSelectedTimeSlot = timeSlots[firstSelectedTimeSlotIndex];
        const lastSelectedTimeSlot = findLastSelectedTimeSlot(timeSlots);

        if (!firstSelectedTimeSlot || !lastSelectedTimeSlot) {
            setCurrentSlotInformation(undefined);
            return;
        }

        let startingTimeSlot = firstSelectedTimeSlot;
        let endingTimeSlot = firstSelectedTimeSlot;

        for (let i = firstSelectedTimeSlotIndex + 1; i < timeSlots.length; i++) {
            const timeSlot = timeSlots[i];

            if (!timeSlot.isChecked || !timeSlot.isBookable) continue;

            if (timeSlot.startTimeUtc > endingTimeSlot.endTimeUtc) {
                const { startingSlot, endingSlot } = handleNonContiguousSelection(
                    checkedItems,
                    timeSlots,
                    firstSelectedTimeSlot,
                    lastSelectedTimeSlot,
                    selectedItem
                );
                startingTimeSlot = startingSlot;
                endingTimeSlot = endingSlot;
                break;
            }

            endingTimeSlot = endingTimeSlot.endTimeUtc >= timeSlot.endTimeUtc ? endingTimeSlot : timeSlot;
        }

        setCurrentSlotInformation(createReservationTimeSlot(startingTimeSlot, endingTimeSlot));
    };

    const onAliasValueChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setAliasInputErrorMessage("");
        const input = event.currentTarget.value;
        setAliasInputValue(input);
        setShowSuggestions(input.length > 0);
        debounceHelper(() => {
            dispatch(retrieveSuggestions(input));
        }, 200);
    };

    const debounceHelper = (callbackFn: () => void, delay: number): void => {
        if (suggestionDebounceTimeout.current) clearTimeout(suggestionDebounceTimeout.current);
        suggestionDebounceTimeout.current = setTimeout(() => {
            callbackFn();
        }, delay);
    };

    const onAliasInputBlur = (): void => {
        if (aliasInputValue) {
            if (aliasInputValue.indexOf(msDomain) > -1) {
                setOrganizerDetails({ organizerEmailAddress: aliasInputValue });
            } else {
                setAliasInputErrorMessage(BookingErrorMessages.AliasInputError);
                setShowSuggestions(false);
            }
        }
    };

    const organizerDetailsDeleteCallback = (): void => {
        setOrganizerDetails(undefined);
    };

    const onSuggestionClick = (suggestion: IOrganizerDetails): void => {
        setOrganizerDetails(suggestion);
    };

    const convertSuggestions = (suggestions: IPersonSuggestionData[] | undefined): IOrganizerDetails[] => {
        if (suggestions === undefined || suggestions.length === 0) {
            return [];
        }

        const organizerDetailList = suggestions.map((suggestion) => ({
            organizerName: suggestion.fullName,
            organizerEmailAddress: suggestion.emailName,
            organizerImg: suggestionImage[suggestion.emailName],
            organizerTitle: suggestion.jobTitle
        }));

        return organizerDetailList;
    };

    const isValidCardKeyNumber = (cardNumber: string): boolean => {
        //make sure 1st digit is not 0 for example  "01" , other digits can contain characters from 0-9;
        return new RegExp("^([1-9]|[1-9]\\d+)$").test(cardNumber);
    };

    const handleBookRoomClick = (): void => {
        if (pageStatus === PageStatus.Default) {
            if (!organizerDetails && !currentUserInfo) {
                setAliasInputErrorMessage(BookingErrorMessages.AliasInputError);
            }
            if (!currentSlotInformation) setAliasInputErrorMessage(BookingErrorMessages.AvailableTimesSelectionError);
            const organizerEmailAddress =
                currentUserInfo?.organizerEmailAddress ?? organizerDetails?.organizerEmailAddress;
            if (organizerEmailAddress && currentSlotInformation) {
                setPageStatus(PageStatus.BookingConfirming);
                spaceBookingConfirmation(
                    spaceHttpProvider,
                    buildingName,
                    selectedSpace,
                    confAlias,
                    currentSlotInformation,
                    organizerEmailAddress,
                    spaceBookingSubject,
                    spaceBookingBodyText,
                    logger,
                    loggerMsgTitle,
                    electronService.isElectron(),
                    setReservationEventId,
                    setPageStatus,
                    setReservationMsg
                );
                if (wayFindingSupported) {
                    onDirectionClick();
                    dispatch(updateRoomBookingStatus(true));
                }
            }
        } else if (pageStatus === PageStatus.BookingCancel) {
            setPageStatus(PageStatus.BookingCanceling);
            cancelBookingConfirmation(
                spaceHttpProvider,
                reservationEventId,
                confAlias,
                logger,
                loggerMsgTitle,
                electronService.isElectron(),
                buildingName,
                selectedSpace,
                cancelBookingHelper
            );
            isReservationCancelled.current = true;
            const latency = electronService.isElectron() ? 10000 : 7000;
            setTimeout(() => {
                getCurrentFreeSlots(
                    spaceHttpProvider,
                    confAlias,
                    numberOfSlots,
                    setTimeSlotMap,
                    setAvailableTimes,
                    logger,
                    loggerMsgTitle,
                    setIsAvailableTimesLoading
                );
            }, latency);
            dispatch(updateRoomBookingStatus(false));
        }
    };
    const cancelBookingHelper = (): void => {
        setPageStatus(PageStatus.Default);
        setReservationEventId("");
    };

    const getBookButtonSecondaryText = (): string => {
        if (!currentSlotInformation) return "";

        const regex = /\s+/g;
        const startEndTime = `${currentSlotInformation?.slotStartTime}-${currentSlotInformation?.slotEndTime}`.replace(
            regex,
            ""
        );
        const duration = `(${currentSlotInformation?.slotDuration} mins)`;

        return `${startEndTime} ${duration}`;
    };

    useEffect(() => {
        if (instantBookingConfirmationInfo) {
            setPageStatus(PageStatus.BookingCancel);
            //setReservationEventId(instantBookingConfirmationInfo.reservationEventId);
            onDirectionClick();
            dispatch(updateRoomBookingStatus(true));
        } else {
            setPageStatus(PageStatus.Default);
            dispatch(updateRoomBookingStatus(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSpace, instantBookingConfirmationInfo]);

    useEffect(() => {
        if (isBookable && pageStatus === PageStatus.Default) {
            getCurrentFreeSlots(
                spaceHttpProvider,
                confAlias,
                numberOfSlots,
                setTimeSlotMap,
                setAvailableTimes,
                logger,
                loggerMsgTitle,
                setIsAvailableTimesLoading
            );
            dispatch(setBadgeScanStatus(""));
            prevBadgeCardRef.current = "";
            setAliasInputValue("");
            setAliasInputErrorMessage("");
            setOrganizerDetails(undefined);
        }

        if (isBookable && (pageStatus === PageStatus.DirectionsEnd || pageStatus === PageStatus.DirectionsRetrieving)) {
            dispatch(setBadgeScanStatus(""));
            prevBadgeCardRef.current = "";
            setAliasInputValue("");
            setAliasInputErrorMessage("");
            setAvailableTimes([]);
            setOrganizerDetails(undefined);
            setShowSuggestions(false);
        }

        if (isBookable && pageStatus === PageStatus.BookingCancel) {
            setAvailableTimes([]);
            setOrganizerDetails(undefined);
        }

        if (pageStatus === PageStatus.Default && (pathMetadata?.routeData || pathMetadata?.retrieving)) {
            resetDirectionButton();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confAlias, isBookable, pageStatus]);
    useEffect(() => {
        setCurrentSlotInformation(getFirstPrecheckedSlot(timeSlotMap, availableTimes));
    }, [timeSlotMap, availableTimes]);

    useEffect(() => {
        setFloorName(floors.find((f) => f.id === selectedSpace.cardAttributes.floorId)?.name ?? "");
    }, [floors, selectedSpace]);

    useEffect(() => {
        dispatch(clearPersonScanAction());
        dispatch(setBadgeScanStatus(""));
        prevBadgeCardRef.current = "";
        setAliasInputErrorMessage("");
        setAliasInputValue("");
        setShowSuggestions(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizerDetails]);

    useEffect(() => {
        if (suggestions) {
            suggestions.forEach((suggestion) => {
                const imageUrl = suggestionImage[suggestion.emailName];
                if (!imageUrl) {
                    logger.logEvent(`[${loggerMsgTitle}] Cannot find suggestionImage from cache.`, {
                        UPN: suggestion.emailName
                    });
                    dispatch(retrieveSuggestionsWithImage(suggestion.emailName, ImageSize.Small));
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [suggestions]);

    useEffect(() => {
        if (personCardInfo && !organizerDetails) {
            logger.logEvent(`[${loggerMsgTitle}] Badge Scanned Successfully`, {
                cardValidStatus: true
            });
            const personImageUrl = personImage[personCardInfo.dtId];
            const organizerEmailAddress = `${personCardInfo.emailName.toLowerCase()}${msDomain}`;
            setOrganizerDetails({
                organizerEmailAddress: organizerEmailAddress,
                organizerName: personCardInfo.fullName
            });
            if (!personImageUrl) {
                dispatch(retrievePersonWithImg(organizerEmailAddress, ImageSize.Small));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [personImage, dispatch, personCardInfo]);

    useEffect(() => {
        if (scannedPersonImage && organizerDetails) {
            setOrganizerDetails({ ...organizerDetails, organizerImg: scannedPersonImage });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scannedPersonImage]);

    const handleKeyPress = React.useCallback(
        (event) => {
            const currentTime = new Date().getTime();
            const timeDiff = currentTime - lastKeyPressTime.current;

            if (timeDiff < 40) {
                if (typingFrom.current === "Person") {
                    prevBadgeCardRef.current = prevBadgeCardRef.current.slice(-1);
                    typingFrom.current = "Device";
                }
            } else {
                typingFrom.current = "Person";
            }
            lastKeyPressTime.current = currentTime;

            if (event.key === "Enter") {
                if (isValidCardKeyNumber(prevBadgeCardRef.current)) {
                    setAliasInputErrorMessage("");
                    dispatch(personScanned(prevBadgeCardRef.current));
                } else {
                    logger.logError(
                        new Error(`[${loggerMsgTitle}] Invalid Badge scanned: ${prevBadgeCardRef.current}`)
                    );
                    dispatch(setBadgeScanStatus(BookingErrorMessages.ScanInputError));
                }
                prevBadgeCardRef.current = "";
            } else {
                prevBadgeCardRef.current = prevBadgeCardRef.current + `${event.key}`;
            }

            logger.logTrace(`[${loggerMsgTitle}] Badge reader latency`, {
                value: prevBadgeCardRef.current,
                type: typingFrom.current,
                latency: timeDiff
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch]
    );

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (badgeScanFlagFromFeatureFlighting) {
            const spaceCardContainer = document.querySelector('[data-testid="GenericSpaceCardScrollContainer"]');
            spaceCardContainer?.addEventListener("keydown", handleKeyPress);
            (spaceCardContainer as HTMLElement).focus();

            return () => {
                spaceCardContainer?.removeEventListener("keydown", handleKeyPress);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [badgeScanFlagFromFeatureFlighting]);

    return (
        <>
            <div aria-live="polite" style={{ position: "absolute", top: "-99999px" }}>
                {pageStatus === PageStatus.BookingCanceling ? "Cancelling room booking" : ""}
                {pageStatus === PageStatus.BookingConfirming ? "Booking room" : ""}
            </div>
            <GenericSpaceCard
                navHeaderBackBtnCallback={handleBackClickAction}
                navHeaderCloseBtnCallback={onPanelDismissCallback}
                navHeaderAriaLabel={getNavHeaderAriaLabel()}
                navHeaderBackBtnLabel="Back"
                navHeaderCloseBtnLabel="Close"
                navHeaderBackBtnDescribedBy={getButtonDescribedBy(true)}
                navHeaderCloseBtnDescribedBy={getButtonDescribedBy(false)}
                spaceCardPrimaryName={selectedSpace.cardAttributes.spaceName}
                spaceCardFriendlyName={selectedSpace.cardAttributes.friendlyName}
                spaceCardSecondaryText={`${SpaceTypeNames[selectedSpace.type]} | ${buildingName} , floor ${floorName}`}
                infoComponent={
                    selectedSpace.cardAttributes.icon && (
                        <img className={styles.img} src={selectedSpace.cardAttributes.icon} alt={"Room Visual"} />
                    )
                }
                status={{
                    /**
                     * Effective date: 8/16/2024
                     * statusLabel is used to show the free/busy status of the calendar
                     * and this value is required so, value has been set to empty string.
                     *
                     * statusIcon is used to show the icon based on the status of the room
                     */
                    //statusLabel: status.primaryStatus,
                    statusLabel: status.secondaryStatus,
                    //statusIcon: getStatusIcon(status.primaryStatus),
                    statusColorName: status.statusColor,
                    // statusDescription: status.secondaryStatus,
                    // statusCaption: "updated now", TODO: Adding caption when update frequence functionality is supported
                    // statusCaptionUpdated: true,
                    showIcon: status.primaryStatus !== "",
                    size: "Large",
                    block: true
                }}
                roomFeatureList={getSpaceFeatureList()}
                roomTagsList={getSpaceTags()}
                availableTimeSlots={isBookable ? getAvailableTimes() : undefined}
                availableTimesShowShimmer={isAvailableTimesLoading}
                handleAvailableTimesChange={handleAvailableTimesChange}
                emptyAvailableTimesText={defaultEmptyAvailableTimesText}
                enableBadgeScan={isBookable && electronService.isElectron() && badgeScanFlagFromFeatureFlighting}
                enableFocusOnOrganizer={isBookable}
                invalidCardKeyErrorMessage={badgeScanStatus}
                onAliasValueChange={isBookable ? onAliasValueChange : undefined}
                onAliasValueBlur={onAliasInputBlur}
                aliasInputValue={aliasInputValue}
                aliasInputPlaceholder={"enter your email"}
                aliasInputErrorMessage={aliasInputErrorMessage}
                organizerDetails={electronService.isElectron() ? organizerDetails : currentUserInfo}
                organizerDetailsDeleteCallback={organizerDetailsDeleteCallback}
                suggestions={showSuggestions ? convertSuggestions(suggestions) : undefined}
                onSuggestionClick={onSuggestionClick}
                pageStatus={pageStatus}
                onBookRoomClick={isBookable ? handleBookRoomClick : undefined}
                bookingButtonSecondaryText={getBookButtonSecondaryText()}
                useElevatorChecked={useElevator}
                onUseElevatorValueChange={wayFindingSupported ? onUseElevatorValueChange : undefined}
                directionsErrorMessage={directionsErrorMessage}
                onDirectionClick={wayFindingSupported ? onDirectionClick : undefined}
                isZoomed={isZoomed}
                isDocked={!isDetailsPanelOpen}
                sliderCallback={toggleDetailsPanel}
                sliderAriaLabel={`Press to ${!isDetailsPanelOpen ? "open" : "close"} details panel.`}
                scrollerAriaLabel={"Scroll"}
                {...pathfindingQRCodeUrl}
                isElectron={electronService.isElectron()}
                bookingConfirmationPageMessage={
                    reservationMsg === undefined ? instantBookingConfirmationInfo?.reservationMsg : reservationMsg
                }
            />
        </>
    );
}
