export enum MenuPanelCustomProperties {
    FloorId = "FloorId",
    BuildingName = "BuildingName",
    RegionName = "RegionName",
    FloorName = "FloorName",
    ButtonLabel = "MenuButtonLabel",
    ButtonSubLabel = "MenuButtonSubLabel",
    OnClickCallbackRegistered = "OnClickCallbackRegistered",
    PoiButtonCount = "MenuPoiButtonCount",
    PoiButtons = "MenuPoiButtons",
    PoiButton = "MenuPoiButton",
    SearchValue = "SearchValue"
}
