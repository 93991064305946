var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import { FeatureFlightingContext } from "./FeatureFlightingContext";
/**
 * Component that renders a default or variant component depending on the result
 * of the result of the provided feature experimentation treatment value
 */
var VariantComponentExperiment = /** @class */ (function (_super) {
    __extends(VariantComponentExperiment, _super);
    function VariantComponentExperiment(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { useVariantComponent: false };
        return _this;
    }
    VariantComponentExperiment.prototype.componentDidMount = function () {
        var _this = this;
        var context = this.context;
        var _a = this.props, namespace = _a.namespace, flag = _a.flag;
        context.initialize().then(function () {
            var val = context.getFeatureValue(namespace, flag);
            if (typeof val === "boolean") {
                _this.setState({ useVariantComponent: val });
            }
        });
    };
    VariantComponentExperiment.prototype.render = function () {
        return this.state.useVariantComponent ? this.props.variantComponent : this.props.defaultComponent;
    };
    VariantComponentExperiment.contextType = FeatureFlightingContext;
    return VariantComponentExperiment;
}(React.Component));
export { VariantComponentExperiment };
