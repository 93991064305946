import { pinTail, pinTop } from "./SpacePinIcons";
export var SpacePinIconLabels;
(function (SpacePinIconLabels) {
    SpacePinIconLabels["PinTail"] = "PinTail";
    SpacePinIconLabels["PinTop"] = "PinTop";
})(SpacePinIconLabels || (SpacePinIconLabels = {}));
export var spacePinIcons = {
    PinTail: pinTail,
    PinTop: pinTop
};
