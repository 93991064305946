import { FooterButton, FooterButtonType } from "@smartbuilding/ui-components-buttons";
import { IIconProps, IconButton, Modal, classNamesFunction } from "@fluentui/react";
import {
    IPrivacyNoticeBaseProps,
    IPrivacyNoticeStyles,
    dataPrivacyNotice,
    noticeMessageKiosk,
    noticeMessageWeb,
    privacyNoticeButtonText
} from "./PrivacyNotice.Types";
import React, { useEffect } from "react";
import QRCode from "qrcode.react";
import { electronService } from "@smartbuilding/electron-service";
import { getResetStatus } from "../../../redux/Selectors/ResetSelector";
import { useSelector } from "react-redux";

const getClassNames = classNamesFunction<{}, IPrivacyNoticeStyles>();
export function PrivacyNoticeBase(props: IPrivacyNoticeBaseProps): JSX.Element {
    const shouldReset = useSelector(getResetStatus);
    const classNames = getClassNames(props.styles, { theme: props.theme });
    const [open, setOpen] = React.useState(false);
    const cancelIcon: IIconProps = { iconName: "Cancel" };

    useEffect(() => {
        if (shouldReset) {
            setOpen(false);
        }
    }, [shouldReset]);

    return (
        <div id={"privacy"} className={classNames.rootContainer}>
            <FooterButton
                type={FooterButtonType.Privacy}
                text={privacyNoticeButtonText}
                onClick={() => setOpen(true)}
            />
            <Modal
                allowTouchBodyScroll={false}
                isOpen={open}
                isBlocking={false}
                containerClassName={classNames.modalContainer}
            >
                <IconButton
                    className={classNames.cancelIconButton}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={() => setOpen(false)}
                />
                {electronService.isElectron() ? (
                    <>
                        <div className={classNames.noticeStyle}>{noticeMessageKiosk}</div>
                        <QRCode id="qr-gen" value={dataPrivacyNotice} size={280} level={"H"} includeMargin={true} />
                        <span className={classNames.urlStyle}>{dataPrivacyNotice}</span>
                    </>
                ) : (
                    <>
                        <div className={classNames.noticeStyle}>{noticeMessageWeb}</div>
                        <QRCode id="qr-gen" value={dataPrivacyNotice} size={280} level={"H"} includeMargin={true} />
                        <a className={classNames.urlStyle} href={dataPrivacyNotice} target="_blank" rel="noreferrer">
                            Privacy notice
                        </a>
                    </>
                )}
            </Modal>
        </div>
    );
}
