import React from "react";
import { createSvgIcon } from "@fluentui/react-icons-mdl2";
// eslint-disable-next-line @typescript-eslint/naming-convention
export var AvailableIcon = createSvgIcon({
    // eslint-disable-next-line react/display-name
    svg: function (args) {
        return (React.createElement("svg", { width: "12", height: "12", viewBox: "0 0 12 12", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM8.53033 5.28033L5.78033 8.03033C5.48744 8.32322 5.01256 8.32322 4.71967 8.03033L3.71967 7.03033C3.42678 6.73744 3.42678 6.26256 3.71967 5.96967C4.01256 5.67678 4.48744 5.67678 4.78033 5.96967L5.25 6.43934L7.46967 4.21967C7.76256 3.92678 8.23744 3.92678 8.53033 4.21967C8.82322 4.51256 8.82322 4.98744 8.53033 5.28033Z", fill: "#92C353" })));
    },
    displayName: "AvailableIcon"
});
