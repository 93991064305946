import { Callback, IObserver } from "@smartbuilding/utilities";

export class Observer implements IObserver {
    private observers: Array<Callback> = [];

    public notify(payload: unknown): void {
        this.observers.forEach((observer) => observer(payload));
    }

    public subscribe(callback: Callback): void {
        this.observers.push(callback);
    }

    public unsubscribe(callback: Callback): void {
        this.observers = this.observers.filter((observer) => observer !== callback);
    }
}
