import { IAction, IBaseAction } from "./IAction";
import { PoiType } from "@smartbuilding/poi-service";

export enum MapViewActions {
    SPACE_CLICKED = "SPACE_CLICKED",
    RENDER_POI_LAYER = "RENDER_POI_LAYER",
    CLEAR_POI_LAYER = "CLEAR_POI_LAYER",
    RENDER_ROUTE_LAYER = "RENDER_ROUTE_LAYER",
    CLEAR_ROUTE_LAYER = "CLEAR_ROUTE_LAYER",
    RENDER_PEOPLE_LAYER = "RENDER_PEOPLE_LAYER",
    CLEAR_PEOPLE_LAYER = "CLEAR_PEOPLE_LAYER",
    RENDER_PEOPLEDENSITY_LAYER = "RENDER_PEOPLEDENSITY_LAYER",
    CLEAR_PEOPLEDENSITY_LAYER = "CLEAR_PEOPLEDENSITY_LAYER",
    RENDER_HOTDESK_LAYER = "RENDER_HOTDESK_LAYER",
    CLEAR_HOTDESK_LAYER = "CLEAR_HOTDESK_LAYER",
    RENDER_SPACEPINS_LAYER = "RENDER_SPACEPINS_LAYER",
    CLEAR_SPACEPINS_LAYER = "CLEAR_SPACEPINS_LAYER"
}

export type SpaceClickedAction = IAction<{ name: string; id?: string }, MapViewActions.SPACE_CLICKED>;
export type RenderPoiLayerAction = IAction<PoiType, MapViewActions.RENDER_POI_LAYER>;
export type ClearPoiLayerAction = IBaseAction<MapViewActions.CLEAR_POI_LAYER>;
export type RenderRouteLayerAction = IBaseAction<MapViewActions.RENDER_ROUTE_LAYER>;
export type ClearRouteLayerAction = IBaseAction<MapViewActions.CLEAR_ROUTE_LAYER>;
export type RenderPeopleLayerAction = IBaseAction<MapViewActions.RENDER_PEOPLE_LAYER>;
export type ClearPeopleLayerAction = IBaseAction<MapViewActions.CLEAR_PEOPLE_LAYER>;
export type RenderPeopleDensityLayerAction = IBaseAction<MapViewActions.RENDER_PEOPLEDENSITY_LAYER>;
export type ClearPeopleDensityLayerAction = IBaseAction<MapViewActions.CLEAR_PEOPLEDENSITY_LAYER>;
export type RenderHotDeskLayerAction = IBaseAction<MapViewActions.RENDER_HOTDESK_LAYER>;
export type ClearHotDesKLayerAction = IBaseAction<MapViewActions.CLEAR_HOTDESK_LAYER>;
export type RenderSpacePinsLayerAction = IBaseAction<MapViewActions.RENDER_SPACEPINS_LAYER>;
export type ClearSpacePinsLayerAction = IBaseAction<MapViewActions.CLEAR_SPACEPINS_LAYER>;

type MapViewAction =
    | SpaceClickedAction
    | RenderPoiLayerAction
    | ClearPoiLayerAction
    | RenderRouteLayerAction
    | ClearRouteLayerAction
    | RenderPeopleLayerAction
    | ClearPeopleLayerAction
    | RenderPeopleDensityLayerAction
    | ClearPeopleDensityLayerAction
    | RenderHotDeskLayerAction
    | ClearHotDesKLayerAction
    | RenderSpacePinsLayerAction
    | ClearSpacePinsLayerAction;

export type MapAction = MapViewAction;
