var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var ConsoleLogger = /** @class */ (function () {
    function ConsoleLogger() {
    }
    ConsoleLogger.prototype.logError = function (error, properties) {
        console.log(error);
        console.log(properties);
    };
    ConsoleLogger.prototype.logTrace = function (message, properties) {
        console.log(message);
        console.log(properties);
    };
    ConsoleLogger.prototype.logEvent = function (name, properties) {
        console.log(name);
        console.log(properties);
    };
    ConsoleLogger.prototype.logWarning = function (message, properties) {
        console.log(message);
        console.log(properties);
    };
    ConsoleLogger.prototype.startTrackingPage = function (name) {
        console.log("Tracking Page: " + name);
    };
    ConsoleLogger.prototype.stopTrackingPage = function (name, url, properties) {
        console.log("Stopping tracking page: " + name + ", url: " + url);
    };
    ConsoleLogger.prototype.trackMetric = function (name, average, measurementCount, smallestMeasurement, largestMeasurement, properties) {
        console.log("Tracking Metric: " + name + " average: " + average);
        this.logOptionalProps(__assign({ measurementCount: measurementCount, smallestMeasurement: smallestMeasurement, largestMeasurement: largestMeasurement }, (properties || {})));
    };
    ConsoleLogger.prototype.trackDependencyData = function (name, serverRequestId, responseCode, absoluteUrl, success, requestDuration, requestMethod, properties) {
        console.log("Tracking Dependency: " + name + " serverRequestId: " + serverRequestId + " responseCode: " + responseCode);
        this.logOptionalProps(__assign({ absoluteUrl: absoluteUrl, success: success, requestDuration: requestDuration, requestMethod: requestMethod }, (properties || {})));
    };
    ConsoleLogger.prototype.addErrorToProperties = function (properties, error) {
        return __assign(__assign({}, properties), { Error: JSON.stringify(error), ErrorMsg: error.message });
    };
    // Will try to log any param passed in.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ConsoleLogger.prototype.logOptionalProps = function (optionalParams) {
        var keys = Object.keys(optionalParams);
        for (var _i = 0, keys_1 = keys; _i < keys_1.length; _i++) {
            var key = keys_1[_i];
            if (typeof optionalParams[key] !== "undefined") {
                console.log(key + ": " + optionalParams[key]);
            }
        }
    };
    return ConsoleLogger;
}());
export { ConsoleLogger };
