import { AdtConverters, IAzureDigitalTwinV3SpaceRetrieve } from "@smartbuilding/utilities";
import {
    DeviceConfigSelectedActions,
    setBuilding,
    setBuildingToDefaultId,
    setRoom,
    storeDeviceConfigData
} from "../Actions";
import { IDipResponse, dipSagas, getTwins } from "@dip/redux-sagas";
import { IElectronDeviceInfo, electronService } from "@smartbuilding/electron-service";
import { all, call, put } from "redux-saga/effects";
import { IDeviceConfigStore } from "../Types";
import { ILogger } from "@smartbuilding/log-provider";
import { ISpaceRepo } from "@smartbuilding/adt-v2-api";
import { QueryBuilder } from "@dip/querybuilder";
import { Space } from "@smartbuilding/adt-v2-types";
import { takeEvery } from "@redux-saga/core/effects";

export class DeviceConfigSaga {
    public constructor(private logger: ILogger, private spaceRepo: ISpaceRepo) {
        this.watcher = this.watcher.bind(this);
        this.handleDeviceConfigData = this.handleDeviceConfigData.bind(this);
    }

    public *watcher(): Generator {
        yield all([takeEvery(DeviceConfigSelectedActions.LOAD_CONFIG_DATA, this.handleDeviceConfigData)]);
    }

    private *handleDeviceConfigData(): Generator {
        try {
            const electronInfo = (yield call([
                electronService,
                electronService.getDeviceInfoAsync
            ])) as IElectronDeviceInfo;
            const configData: IDeviceConfigStore = {
                spaceId: electronInfo.spaceId,
                buildingId: electronInfo.buildingId,
                floorId: electronInfo.floorId,
                location: electronInfo.location
                    ? { latitude: electronInfo.location.lat, longitude: electronInfo.location.lng }
                    : undefined,
                hardwareId: electronInfo.hardwareId,
                bearing: electronInfo.bearing
            };
            this.logger.logEvent("[DeviceConfigSaga] Electron device info", {
                spaceId: configData.spaceId,
                buildingId: configData.buildingId,
                floorId: configData.floorId,
                location: configData.location,
                bearing: configData.bearing
            });
            yield put(storeDeviceConfigData(configData));

            if (!electronInfo.floorId && electronInfo.spaceId) {
                const spaceId = electronInfo.spaceId;
                const query = QueryBuilder.from(Space).where((q) => q.compare(Space, (s) => s.$dtId.equals(spaceId)));
                const response = (yield dipSagas.get(getTwins(query))) as IDipResponse<Space[]>;
                if (response.data.length > 0) {
                    const space = AdtConverters.formatIntoSmartSpace(
                        response.data[0] as IAzureDigitalTwinV3SpaceRetrieve
                    );
                    if (space.floorId) {
                        yield put(
                            storeDeviceConfigData({
                                ...configData,
                                buildingId: space.buildingId,
                                floorId: space.floorId
                            })
                        );
                    }
                }
            }

            if (electronInfo.spaceId) {
                yield put(setRoom(electronInfo.spaceId));
            } else if (electronInfo.buildingId) {
                yield put(setBuilding(electronInfo.buildingId));
            } else {
                yield put(setBuildingToDefaultId(undefined));
            }
            return;
        } catch (error) {
            this.logger.logError(new Error("Error from DeviceConfigSaga"), {
                Message: "Failed to set deviceConfig data from electron service"
            });
        }
    }
}
