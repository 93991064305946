/**
 * Helper function to run timing loops for animations. It takes in an array of timing configurations that contain the duration and
 * and a callback function to invoke with the current time progress.
 * @param timingConfigs @type { Array<{ duration: number; callback: (timeProgress: number) => void}>} Configurations for loops to run
 * @param useRequestAnimationFrame @default {false} Flag to denote if we want to use request animation frame to run the loop
 * @returns A promise to when the loop has ended for all timing configurations.
 */
export function animationTimingHelper(timingConfigs, useRequestAnimationFrame) {
    if (useRequestAnimationFrame === void 0) { useRequestAnimationFrame = false; }
    if (useRequestAnimationFrame) {
        return requestAnimationFrameTimingHelper(timingConfigs);
    }
    else {
        return setIntervalTimingHelper(timingConfigs);
    }
}
function requestAnimationFrameTimingHelper(timingConfigs) {
    var index = 0;
    var nextIndex = null;
    var finalIndex = timingConfigs.length - 1;
    var lastTime = performance.now();
    var minFR = 33;
    function getTimeProgress(currentTime, startTime, duration) {
        var timeProgress = (currentTime - startTime) / duration;
        return timeProgress < 0 ? 0 : timeProgress;
    }
    function getLastTime() {
        return lastTime;
    }
    function setLastTime(currTime) {
        lastTime = currTime;
    }
    return new Promise(function (resolve) {
        var startTimes = { current: performance.now(), next: performance.now() };
        requestAnimationFrame(loop);
        function loop(time) {
            var timeProgress = getTimeProgress(time, startTimes.current, timingConfigs[index].duration);
            var status = getTimeProgressStatus(timeProgress);
            timingConfigs[index].timeProgressCallback(timeProgress);
            if (nextIndex !== null) {
                timingConfigs[nextIndex].timeProgressCallback(getTimeProgress(time, startTimes.next, timingConfigs[index].duration));
            }
            if (status.isFinished && index >= finalIndex) {
                resolve();
            }
            else {
                var t = performance.now();
                if (t - getLastTime() >= minFR) {
                    if (status.isFinished) {
                        if (nextIndex === null) {
                            startTimes.current = t;
                            index++;
                        }
                        else {
                            startTimes.current = startTimes.next;
                            index = nextIndex;
                        }
                    }
                    else if (nextIndex === null && status.shouldStartNextAnimation && index < finalIndex) {
                        startTimes.next = t;
                        nextIndex = index + 1;
                    }
                    setLastTime(t);
                }
                requestAnimationFrame(loop);
            }
        }
    });
}
function setIntervalTimingHelper(timingConfigs) {
    var index = 0;
    var finalIndex = timingConfigs.length - 1;
    var intervalDuration = 10;
    function getTimeProgress(step, intervalDuration, duration) {
        var timeProgress = (intervalDuration * step++) / duration;
        return timeProgress < 0 ? 0 : timeProgress;
    }
    return new Promise(function (resolve) {
        var stepIndex = 0;
        var id = setInterval(function () {
            var timeProgress = getTimeProgress(stepIndex++, intervalDuration, timingConfigs[index].duration);
            var status = getTimeProgressStatus(timeProgress);
            timingConfigs[index].timeProgressCallback(timeProgress);
            if (status.isFinished && index >= finalIndex) {
                clearInterval(id);
                resolve();
            }
            else if (status.isFinished) {
                stepIndex = 0;
                index++;
            }
        }, intervalDuration);
    });
}
function getTimeProgressStatus(timeProgress) {
    return { isFinished: timeProgress > 1, shouldStartNextAnimation: timeProgress >= 0.8 };
}
