export const configurationBaseApiUrl = "/Configuration/ConfigurationData";

export interface IWebClientConfiguration {
    AADAppId: string;
    AADAuthority: string;
    AADInstance: string;
    AADTenant: string;
    AzureMapsClientId: string;
    AzureMapsSubscriptionKey: string;
    AzureExperimentationEndpoint: string;
    AadDomain: string;
    AADTenantId: string;
    AdalResourceMapping: string;
    ApiRouteMapping: string;
    APPINSIGHTS_INSTRUMENTATIONKEY: string;
    ApplicationName: string;
    ComponentId: string;
    DTDLAadResourceId: string;
    DTDLUrl: string;
    DTDLv3Url: string;
    DtdlApiAddress: string;
    DIPSubscriptionKey: string;
    PointRAuthUsername: string;
    PointRAuthPassword: string;
    DefaultUserBuildingId: string;
    Environment: string;
    EnablePointR: boolean;
    GraphApiAddress: string;
    GraphApiGetPhotoRelativeUrl: string;
    GraphApiGetPhotoOfSizeRelativeUrl: string;
    KioskAppId: string;
    KioskBlueDotLocationConfig: string;
    KioskComfyServiceAccount: string;
    KioskUserContextResources: string;
    KioskUserContextServiceAccount: string;
    KioskUserContextServiceAccountPassword: string;
    MSIT_Logging_Capability_L4: string;
    MSIT_Logging_Program_L3: string;
    MSIT_Logging_ServiceLine_L2: string;
    MSIT_Logging_ServiceOffering_L1: string;
    MyHubBaseQRCodeURL: string;
    MyHubUserURL: string;
    NotificationApiAAD: string;
    NotificationApiUrlV2: string;
    NotificationApiV2TokenUrl: string;
    NotificationAPIResourceId: string;
    OCVAppId: number;
    PPIStateResetInterval: string;
    PushNotificationsPublicKey: string;
    PushNotificationsWorker: string;
    PointRAPIUrl: string;
    PointRMSFTClientId: string;
    RoomCapabilitiesQueryUrl: string;
    SignalRConnectionLoggingEnabled: boolean;
    SignalRRefreshTimeout: number;
    SignalRServerTimeout: number;
    SmartBuildingDataApiAddress: string;
    SmartBuildingDataAPIMUrl: string;
    SpaceQueryCacheTimeout: number;
    SpaceStateSignalREndpointV2: string;
    SpaceStateSignalRHubV2: string;
    SmartBuildingApiUrl: string;
    WebClientAadAppId: string;
    WayfindingAPIDirectUrl: string;
    WayfindingAPIMUrl: string;
    ValidAudience: string;
}
