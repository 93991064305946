export var AreaSubTypes;
(function (AreaSubTypes) {
    AreaSubTypes["Atrium"] = "Atrium";
    AreaSubTypes["AtriumVoid"] = "AtriumVoid";
    AreaSubTypes["BathRoom"] = "BathRoom";
    AreaSubTypes["BreakoutWithRegistration"] = "BreakoutWithRegistration";
    AreaSubTypes["BuildingPBX"] = "BuildingPBX";
    AreaSubTypes["CableRoom"] = "CableRoom";
    AreaSubTypes["CopyRoom"] = "CopyRoom";
    AreaSubTypes["CorporateSupport"] = "CorporateSupport";
    AreaSubTypes["Desk"] = "Desk";
    AreaSubTypes["Elevator"] = "Elevator";
    AreaSubTypes["ElevatorFreight"] = "ElevatorFreight";
    AreaSubTypes["EnvisioningCenter"] = "EnvisioningCenter";
    AreaSubTypes["GymFitnessCenter"] = "GymFitnessCenter";
    AreaSubTypes["Hallway"] = "Hallway";
    AreaSubTypes["Hub"] = "Hub";
    AreaSubTypes["InnovationCenter"] = "InnovationCenter";
    AreaSubTypes["InteractiveCenter"] = "InteractiveCenter";
    AreaSubTypes["ITPAC"] = "ITPAC";
    AreaSubTypes["ITSupport"] = "ITSupport";
    AreaSubTypes["JanitorCloset"] = "JanitorCloset";
    AreaSubTypes["Kitchen"] = "Kitchen";
    AreaSubTypes["KitchenetteVending"] = "KitchenetteVending";
    AreaSubTypes["LoadingReceiving"] = "LoadingReceiving";
    AreaSubTypes["LobbyReception"] = "LobbyReception";
    AreaSubTypes["Lounge"] = "Lounge";
    AreaSubTypes["MailRoom"] = "MailRoom";
    AreaSubTypes["MechanicalElectricalRoom"] = "MechanicalElectricalRoom";
    AreaSubTypes["MicrosoftStore"] = "MicrosoftStore";
    AreaSubTypes["MPRSupport"] = "MPRSupport";
    AreaSubTypes["Neighborhood"] = "Neighborhood";
    AreaSubTypes["OpenMeetingArea"] = "OpenMeetingArea";
    AreaSubTypes["OtherSpace"] = "OtherSpace";
    AreaSubTypes["POI"] = "POI";
    AreaSubTypes["PropertyManagement"] = "PropertyManagement";
    AreaSubTypes["Reception"] = "Reception";
    AreaSubTypes["RecreationGaming"] = "RecreationGaming";
    AreaSubTypes["RetailShop"] = "RetailShop";
    AreaSubTypes["Room"] = "Room";
    AreaSubTypes["Sauna"] = "Sauna";
    AreaSubTypes["ServerDisplay"] = "ServerDisplay";
    AreaSubTypes["ServerRoom"] = "ServerRoom";
    AreaSubTypes["ShowcaseDisplay"] = "ShowcaseDisplay";
    AreaSubTypes["ShowerLockerRoom"] = "ShowerLockerRoom";
    AreaSubTypes["SprinklerRoom"] = "SprinklerRoom";
    AreaSubTypes["Stairwell"] = "Stairwell";
    AreaSubTypes["Storage"] = "Storage";
    AreaSubTypes["Techlink"] = "Techlink";
    AreaSubTypes["TechnicalBriefingCenter"] = "TechnicalBriefingCenter";
    AreaSubTypes["Touchdown"] = "Touchdown";
    AreaSubTypes["UnderConstruction"] = "UnderConstruction";
    AreaSubTypes["VerticalPenetration"] = "VerticalPenetration";
    AreaSubTypes["VerticalShaft"] = "VerticalShaft";
    AreaSubTypes["VisitorArea"] = "VisitorArea";
    AreaSubTypes["Warehouse"] = "Warehouse";
    AreaSubTypes["WaterCoolerPoint"] = "WaterCoolerPoint";
    AreaSubTypes["WellnessRelaxationRoom"] = "WellnessRelaxationRoom";
    AreaSubTypes["Zone"] = "Zone";
})(AreaSubTypes || (AreaSubTypes = {}));
