var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { PoiLayers } from "./CustomMapLayer.Types";
import { data, layer, source } from "azure-maps-control";
import { POIOverlayMapLayer } from "./POILayer/POIOverlayMapLayer";
var CustomMapLayer = /** @class */ (function () {
    function CustomMapLayer(map, mapLayerOptions, boundingCoordinates, iconSvgCreator) {
        this.map = map;
        this.mapLayerOptions = mapLayerOptions;
        this.boundingCoordinates = boundingCoordinates;
        this.iconSvgCreator = iconSvgCreator;
        this.overlayMapRecord = {};
        this.isGrayOut = false;
        this.datasourceId = "grayout";
        this.layerId = "grayOutOverlay";
        // bind layers together
        this.poiLayer = new POIOverlayMapLayer(this.map, this.mapLayerOptions.overlayDataSources, this.mapLayerOptions.overlayStyles);
    }
    CustomMapLayer.prototype.addGrayOut = function () {
        var _this = this;
        var datasource = new source.DataSource(this.datasourceId);
        this.map.events.addOnce("ready", function () {
            datasource.add(new data.Feature(new data.Polygon(_this.boundingCoordinates)));
            _this.map.sources.add(datasource);
            var options = {
                fillColor: "#3F454D",
                fillOpacity: 1,
                minZoom: 0,
                maxZoom: 24,
                visible: true
            };
            var grayOutOverlay = new layer.PolygonLayer(datasource, _this.layerId);
            grayOutOverlay.setOptions(options);
            _this.map.layers.add(grayOutOverlay, "vertical_penetration");
        });
        this.isGrayOut = true;
    };
    CustomMapLayer.prototype.removeGrayOut = function () {
        var _this = this;
        this.map.events.addOnce("ready", function () {
            _this.map.layers.remove(_this.layerId);
            _this.map.sources.remove(_this.datasourceId);
        });
        this.isGrayOut = false;
    };
    CustomMapLayer.prototype.createOverlayMapLayers = function (mapLayers) {
        var _this = this;
        this.overlayMapRecord = __assign(__assign({}, this.overlayMapRecord), mapLayers);
        var sortedOverlayLayerMap = Object.keys(this.overlayMapRecord)
            .sort(function (a, b) {
            return _this.overlayMapRecord[a] === _this.overlayMapRecord[b]
                ? 0
                : _this.overlayMapRecord[a]
                    ? 1
                    : -1;
        })
            .reduce(function (a, key) {
            a[key] = _this.overlayMapRecord[key];
            return a;
        }, {});
        var addedLayers = [];
        for (var mapLayer in sortedOverlayLayerMap) {
            if (mapLayer !== PoiLayers.KioskBlueDot) {
                if (mapLayers[mapLayer]) {
                    if (!this.isGrayOut)
                        this.addGrayOut();
                    addedLayers.push(this.addMapLayer(mapLayer));
                }
                else {
                    this.removeSpecificMapLayer(mapLayer);
                }
            }
            else {
                this.map.events.addOnce("ready", function () {
                    _this.createMapLayer(PoiLayers.KioskBlueDot);
                });
            }
        }
        return Promise.all(addedLayers).then();
    };
    CustomMapLayer.prototype.addMapLayer = function (mapLayer) {
        var _this = this;
        return new Promise(function (resolve) {
            if (!_this.map.layers.getLayerById(mapLayer)) {
                _this.map.events.addOnce("ready", function () {
                    _this.createMapLayer(mapLayer).then(resolve);
                });
            }
            else {
                resolve();
            }
        });
    };
    CustomMapLayer.prototype.removeSpecificMapLayer = function (mapLayer) {
        if (this.map.layers.getLayerById(mapLayer)) {
            this.map.layers.remove(mapLayer);
            this.removeGrayOut();
        }
    };
    CustomMapLayer.prototype.flushMapLayers = function () {
        for (var layer_1 in PoiLayers) {
            if (this.map.layers.getLayerById(layer_1)) {
                this.map.layers.remove(layer_1);
            }
        }
    };
    CustomMapLayer.prototype.clearKioskMarker = function () {
        this.poiLayer.clearKioskMarker();
    };
    CustomMapLayer.prototype.createMapLayer = function (currentMapLayer) {
        return this.poiLayer.CreatePoiOverlayLayer(currentMapLayer, this.iconSvgCreator);
    };
    return CustomMapLayer;
}());
export { CustomMapLayer };
