import "../Styles/BluedotMarker.css";
import { HtmlMarker, data } from "azure-maps-control";
var DynamicBlueDotMarker = /** @class */ (function () {
    function DynamicBlueDotMarker(map) {
        this.map = map;
        this.bluedotMarker = {};
        this.bluedotPosition = {
            latitude: 0,
            longitude: 0,
            elevation: 0,
            rotation: 0,
            floorId: "",
            tStamp: 0
        };
        this.indoorZoomLevel = 8;
        this.map = map;
    }
    DynamicBlueDotMarker.prototype.createDynamicBluedot = function (position) {
        this.bluedotPosition = position;
        var bluedotPosition = new data.Position(position.longitude, position.latitude);
        var htmlOptions = {
            color: "DodgerBlue",
            htmlContent: this.getHtmlContent(position.rotation),
            position: bluedotPosition
        };
        this.bluedotMarker = new HtmlMarker(htmlOptions);
        this.map.markers.add(this.bluedotMarker);
        this.updateMapCenterAndOrientation(position);
        return this.bluedotMarker;
    };
    DynamicBlueDotMarker.prototype.updateDynamicBlueDot = function (position, bluedotHtmlMarker, isVisible) {
        if (bluedotHtmlMarker && position) {
            this.bluedotPosition = position;
            bluedotHtmlMarker.setOptions({
                position: new data.Position(position.longitude, position.latitude),
                htmlContent: this.getHtmlContent(position.rotation),
                visible: isVisible,
                anchor: "center",
                pixelOffset: [0, 0]
            });
            if (isVisible) {
                this.updateMapCenterAndOrientation(position);
            }
        }
    };
    DynamicBlueDotMarker.prototype.updateMapCenterAndOrientation = function (position) {
        if (position && position.rotation !== undefined) {
            this.map.setCamera({
                center: this.bluedotMarker.getOptions().position,
                bearing: position.rotation,
                type: "ease",
                duration: 250
            });
        }
    };
    DynamicBlueDotMarker.prototype.resetToDynamicBluedot = function () {
        if (this.bluedotMarker) {
            var position = [this.bluedotMarker.getOptions().position];
            if (position) {
                this.map.setCamera({
                    bounds: data.BoundingBox.fromPositions(position),
                    bearing: this.bluedotPosition.rotation,
                    zoom: this.indoorZoomLevel
                });
            }
        }
    };
    DynamicBlueDotMarker.prototype.getHtmlContent = function (rotation) {
        if (rotation === -999999) {
            return "<div id=\"dynamicBluedotContainer\">\n                        <div id=\"dynamicBluedotIcon\"></div>\n                    </div>";
        }
        else {
            return "<div id=\"dynamicBluedotContainer\">\n            <div id=\"dynamicBluedotDirection\">\n                <div id=\"dynamicBluedotIcon\"></div>\n            </div>\n        </div>";
        }
    };
    DynamicBlueDotMarker.prototype.dispose = function () {
        this.map.markers.remove(this.bluedotMarker);
    };
    return DynamicBlueDotMarker;
}());
export { DynamicBlueDotMarker };
