import { LocalRoomImageService } from "./LocalRoomImageService";
var MrcdpRoomImageService = /** @class */ (function () {
    function MrcdpRoomImageService(httpService) {
        var _this = this;
        this.httpService = httpService;
        this.localRoomImageService = new LocalRoomImageService();
        this.getRoomImageUrl = function (dtSpaceId, roomKey) {
            dtSpaceId = dtSpaceId.toLocaleLowerCase();
            return _this.getRoomImages(roomKey)
                .then(function (rawRoomImageData) { return _this.parseRoomImages(rawRoomImageData); })
                .then(function (parsedData) { return _this.frontOrNoPicture(parsedData); })
                .catch(function () { return _this.localRoomImageService.getRoomImageUrl(dtSpaceId); });
        };
        this.getRoomImages = function (roomKey) {
            var encodedRoomKey = encodeURIComponent(roomKey);
            return _this.httpService
                .get("https://mrcdpapi.azurewebsites.net/api/Room/Pictures?roomKey=" + encodedRoomKey, {})
                .then(function (r) { return r.data; });
        };
        this.parseRoomImages = function (rawRoomImageData) {
            return rawRoomImageData.map(function (rawData) {
                try {
                    var parsedData = JSON.parse(rawData);
                    if (_this.isMrcdpPictureData(parsedData)) {
                        return parsedData;
                    }
                    else {
                        throw new Error();
                    }
                }
                catch (_a) {
                    return {
                        PictureName: "",
                        PictureUri: ""
                    };
                }
            });
        };
        this.frontOrNoPicture = function (pictureData) {
            var _a, _b;
            return (_b = (_a = pictureData.find(function (picData) { return picData.PictureName.toLowerCase().includes("front"); })) === null || _a === void 0 ? void 0 : _a.PictureUri) !== null && _b !== void 0 ? _b : "";
        };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.isMrcdpPictureData = function (data) {
            return (data &&
                typeof data === "object" &&
                "PictureName" in data &&
                "PictureUri" in data &&
                typeof data.PictureName === "string" &&
                typeof data.PictureUri === "string");
        };
    }
    return MrcdpRoomImageService;
}());
export { MrcdpRoomImageService };
