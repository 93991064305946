var SensorValueCache = /** @class */ (function () {
    function SensorValueCache() {
        // Map of space id to a map of sensorDataType to current sensor data type value
        this.valueCache = {};
    }
    SensorValueCache.prototype.getSensorValue = function (spaceId, sensorDataType) {
        spaceId = spaceId.toLowerCase();
        if (Object.prototype.hasOwnProperty.call(this.valueCache, spaceId)) {
            var spaceValueMap = this.valueCache[spaceId];
            return spaceValueMap[sensorDataType.toLowerCase()].value;
        }
        throw new Error("SensorValueCache has no value for space " + spaceId + " with data type: " + sensorDataType);
    };
    SensorValueCache.prototype.setSensorValue = function (spaceId, sensorDataType, value, timeStamp) {
        spaceId = spaceId.toLowerCase();
        var spaceValueMap = this.valueCache[spaceId];
        if (!spaceValueMap) {
            spaceValueMap = {};
            this.valueCache[spaceId] = spaceValueMap;
        }
        var cachedValue = spaceValueMap[sensorDataType.toLowerCase()];
        if (cachedValue) {
            if (cachedValue.timeStamp < timeStamp) {
                spaceValueMap[sensorDataType.toLowerCase()] = { value: value, timeStamp: timeStamp };
                return true;
            }
            return false;
        }
        spaceValueMap[sensorDataType.toLowerCase()] = { value: value, timeStamp: timeStamp };
        return true;
    };
    SensorValueCache.prototype.hasSensorValue = function (spaceId, sensorDataType) {
        spaceId = spaceId.toLowerCase();
        if (Object.prototype.hasOwnProperty.call(this.valueCache, spaceId)) {
            var spaceValueMap = this.valueCache[spaceId];
            return Object.prototype.hasOwnProperty.call(spaceValueMap, sensorDataType.toLowerCase());
        }
        return false;
    };
    SensorValueCache.prototype.getSensorTimeStamp = function (spaceId, sensorDataType) {
        spaceId = spaceId.toLowerCase();
        if (Object.prototype.hasOwnProperty.call(this.valueCache, spaceId)) {
            var spaceValueMap = this.valueCache[spaceId];
            return spaceValueMap[sensorDataType.toLowerCase()].timeStamp;
        }
        throw new Error("SensorValueCache has no value for space " + spaceId + " with data type: " + sensorDataType);
    };
    SensorValueCache.prototype.clearSensorValue = function (spaceId, sensorDataType) {
        if (this.hasSensorValue(spaceId, sensorDataType)) {
            delete this.valueCache[spaceId.toLowerCase()][sensorDataType.toLowerCase()];
        }
    };
    SensorValueCache.prototype.clearSpaceValues = function (spaceId) {
        delete this.valueCache[spaceId.toLowerCase()];
    };
    SensorValueCache.prototype.clearCache = function () {
        this.valueCache = {};
    };
    return SensorValueCache;
}());
export { SensorValueCache };
