import {
    MapViewActions,
    PeopleRetrieveActions,
    SpaceClickedAction,
    SpaceRetrieveActions,
    SpaceSelectedActions,
    clearHotDeskLayer,
    clearPeopleDensityLayer,
    clearPeopleLayer,
    renderHotDeskLayer,
    renderPeopleDensityLayer,
    renderPeopleLayer,
    setRoom
} from "../Actions";
import { all, put } from "redux-saga/effects";
import { getDiningRooms, getHotDeskingRooms, getPeopleInBuilding, getRooms } from "../Selectors";
import { select, takeEvery } from "@redux-saga/core/effects";
import { IPerson } from "@smartbuilding/people-service";
import { IRoomInfo } from "../Types";

export class MapSaga {
    public constructor() {
        this.watcher = this.watcher.bind(this);
    }

    public *watcher(): Generator {
        yield all([
            takeEvery(MapViewActions.SPACE_CLICKED, this.handleSpaceClicked),
            takeEvery(PeopleRetrieveActions.PEOPLE_IN_BUILDING_RETRIEVED, this.handlePeopleListUpdate),
            takeEvery(SpaceSelectedActions.BUILDING_SELECTED, this.handlePeopleListUpdate),
            takeEvery(SpaceRetrieveActions.ROOMS_RETRIEVED, this.handleDiningRoomsUpdate),
            takeEvery(SpaceRetrieveActions.ROOMS_RETRIEVED, this.handleHotDeskingRoomsUpdate)
        ]);
    }

    private *handleSpaceClicked(action: SpaceClickedAction): Generator {
        const rooms = (yield select(getRooms)) as IRoomInfo[];
        let roomId: string | undefined = undefined;
        for (const room of rooms) {
            if (room.name === action.payload.name || room.id === action.payload.id) {
                roomId = room.id;
                break;
            }
        }
        yield put(setRoom(roomId));
    }

    private *handlePeopleListUpdate(): Generator {
        yield put(clearPeopleLayer());
        const peopleInBuilding = (yield select(getPeopleInBuilding)) as IPerson[];
        if (peopleInBuilding.length > 0) yield put(renderPeopleLayer());
    }

    private *handleHotDeskingRoomsUpdate(): Generator {
        yield put(clearHotDeskLayer());
        const hotdeskingRooms = (yield select(getHotDeskingRooms)) as IRoomInfo[];
        if (hotdeskingRooms.length > 0) yield put(renderHotDeskLayer());
    }

    private *handleDiningRoomsUpdate(): Generator {
        yield put(clearPeopleDensityLayer());
        const diningRooms = (yield select(getDiningRooms)) as IRoomInfo[];
        if (diningRooms.length > 0) yield put(renderPeopleDensityLayer());
    }
}
