import { makeStyles } from "@fluentui/react-make-styles";

export const useDetailsPanelStyles = makeStyles({
    panel: () => ({
        position: "absolute",
        right: "0px",
        top: "0px",
        width: "fit-content",
        height: "100%",
        zIndex: 1,
        [`@media screen and (width: 320px) and (height: 256px)`]: {
            position: "unset",
            right: "0px",
            top: "0px",
            width: "fit-content",
            height: "100%",
            zIndex: 1
        },
        [`@media screen and (max-width: 670px) and (max-height: 490px)`]: {
            position: "unset",
            right: "0px",
            top: "0px",
            width: "fit-content",
            height: "100%",
            zIndex: 1
        }
    })
});
