import {
    ClearHotDesKLayerAction,
    ClearPeopleDensityLayerAction,
    ClearPeopleLayerAction,
    ClearPoiLayerAction,
    ClearRouteLayerAction,
    ClearSpacePinsLayerAction,
    MapViewActions,
    RenderHotDeskLayerAction,
    RenderPeopleDensityLayerAction,
    RenderPeopleLayerAction,
    RenderPoiLayerAction,
    RenderRouteLayerAction,
    RenderSpacePinsLayerAction
} from "./MapActions";
import { PoiType } from "@smartbuilding/poi-service";
import { SpaceClickedAction } from ".";

export function spaceClickedAction(space: { id?: string; name: string }): SpaceClickedAction {
    return { type: MapViewActions.SPACE_CLICKED, payload: space };
}

export function renderPoiLayer(poiLayer: PoiType): RenderPoiLayerAction {
    return {
        type: MapViewActions.RENDER_POI_LAYER,
        payload: poiLayer
    };
}

export function clearPoiLayer(): ClearPoiLayerAction {
    return {
        type: MapViewActions.CLEAR_POI_LAYER
    };
}

export function renderPeopleLayer(): RenderPeopleLayerAction {
    return {
        type: MapViewActions.RENDER_PEOPLE_LAYER
    };
}

export function clearPeopleLayer(): ClearPeopleLayerAction {
    return {
        type: MapViewActions.CLEAR_PEOPLE_LAYER
    };
}

export function renderRouteLayer(): RenderRouteLayerAction {
    return {
        type: MapViewActions.RENDER_ROUTE_LAYER
    };
}

export function clearRouteLayer(): ClearRouteLayerAction {
    return {
        type: MapViewActions.CLEAR_ROUTE_LAYER
    };
}

export function renderPeopleDensityLayer(): RenderPeopleDensityLayerAction {
    return {
        type: MapViewActions.RENDER_PEOPLEDENSITY_LAYER
    };
}

export function clearPeopleDensityLayer(): ClearPeopleDensityLayerAction {
    return {
        type: MapViewActions.CLEAR_PEOPLEDENSITY_LAYER
    };
}
export function renderHotDeskLayer(): RenderHotDeskLayerAction {
    return {
        type: MapViewActions.RENDER_HOTDESK_LAYER
    };
}

export function clearHotDeskLayer(): ClearHotDesKLayerAction {
    return {
        type: MapViewActions.CLEAR_HOTDESK_LAYER
    };
}

export function renderSpacePinsLayer(): RenderSpacePinsLayerAction {
    return {
        type: MapViewActions.RENDER_SPACEPINS_LAYER
    };
}

export function clearSpacePinsLayer(): ClearSpacePinsLayerAction {
    return {
        type: MapViewActions.CLEAR_SPACEPINS_LAYER
    };
}
