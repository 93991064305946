import { createCleanUserQuery } from "../CreateCleanUserQuery";
import { isString } from "util";
import { mergePaginatedResponseObject } from "../../commonUtils/CommonADTFunctions";
var UserRepo = /** @class */ (function () {
    function UserRepo(logger, userHttpService) {
        this.logger = logger;
        this.userHttpService = userHttpService;
        this.serviceTag = "[User.Repo.Service]";
        this.responseByFilter = {};
    }
    UserRepo.prototype.getUserById = function (userId, includes) {
        var _this = this;
        if (!userId || !isString(userId)) {
            var error = new Error(this.serviceTag + ": invalid argument specified: userId=" + userId);
            return Promise.reject(error);
        }
        var userQuery = createCleanUserQuery({
            includes: includes
        });
        return this.userHttpService.getUserById(userId, userQuery).catch(function (error) {
            new Error(_this.serviceTag + ": unable to get user: userId=" + userId);
            return Promise.reject(error);
        });
    };
    UserRepo.prototype.getUsers = function (filter, includes, type, skiptoken) {
        var _this = this;
        if (!filter || !isString(filter)) {
            var error = new Error(this.serviceTag + ": invalid argument specified: filter=" + filter);
            return Promise.reject(error);
        }
        var usersPerPage = [];
        return this.getUsersPerPage(filter, includes, type, skiptoken)
            .then(function (response) {
            var _a;
            (_a = response.items) === null || _a === void 0 ? void 0 : _a.forEach(function (user) {
                usersPerPage.push(user);
            });
            if (filter in _this.responseByFilter) {
                var lastUserIndex = _this.responseByFilter[filter].length;
                var firstUser = usersPerPage[0];
                var lastUser = _this.responseByFilter[filter][lastUserIndex - 1];
                if (lastUser.dtId === firstUser.dtId) {
                    var combinedUser = mergePaginatedResponseObject(lastUser, firstUser);
                    _this.responseByFilter[filter].pop();
                    _this.responseByFilter[filter].push(combinedUser);
                    usersPerPage.shift();
                }
            }
            if (filter in _this.responseByFilter) {
                _this.responseByFilter[filter] = _this.responseByFilter[filter].concat(usersPerPage);
            }
            else {
                _this.responseByFilter[filter] = usersPerPage;
            }
            var newSkipToken = _this.getSkipToken(response);
            if (newSkipToken) {
                return _this.getUsers(filter, includes, type, newSkipToken);
            }
            else {
                // Clearing out the response stored in member variable
                // for any subsequent calls with same filter
                var tempResponseByFilter = JSON.parse(JSON.stringify(_this.responseByFilter));
                delete _this.responseByFilter[filter];
                return Promise.resolve(tempResponseByFilter[filter]);
            }
        })
            .catch(function (error) {
            new Error(_this.serviceTag + ": unable to get users for current page");
            return Promise.reject(error);
        });
    };
    UserRepo.prototype.getUsersPerPage = function (filter, includes, type, skiptoken) {
        var _this = this;
        var userQuery = createCleanUserQuery({
            includes: includes,
            filter: filter,
            type: type,
            skiptoken: skiptoken
        });
        return this.userHttpService.getUsers(userQuery).catch(function (error) {
            new Error(_this.serviceTag + ": unable to get users for current page");
            return Promise.reject(error);
        });
    };
    UserRepo.prototype.getSkipToken = function (response) {
        var urlParam;
        var nextPageLink = response.nextPageLink;
        if (nextPageLink) {
            urlParam = new URL(nextPageLink);
        }
        return urlParam != null ? new URLSearchParams(urlParam.search).get("skiptoken") : "";
    };
    return UserRepo;
}());
export { UserRepo };
