import { IConfigLocation, IDeviceConfigStore } from "../Types";
import { RootState } from "../Reducers/RootReducer";

export const getDeviceConfigFloorId = (store: RootState): string | undefined => store.deviceConfig.floorId;

export const getDeviceConfigSpaceId = (store: RootState): string | undefined => store.deviceConfig.spaceId;

export const getDeviceConfiglocation = (store: RootState): IConfigLocation | undefined => store.deviceConfig.location;

export const getDeviceConfigBuildingId = (store: RootState): string | undefined => store.deviceConfig.buildingId;

export const getDeviceConfigBearing = (store: RootState): number | undefined => store.deviceConfig.bearing;

export const getDeviceConfigData = (store: RootState): IDeviceConfigStore => store.deviceConfig;
