import { LayoutActions, LayoutActionsTypes } from "../Actions/LayoutActions";
import { isToggleViewport } from "../../utilities/viewportUtilities";

export interface ILayoutStore {
    isZoomed: boolean;
    isMenuPanelOpen: boolean;
    isDetailsPanelOpen: boolean;
}

export const initialState: ILayoutStore = {
    isZoomed: false,
    isMenuPanelOpen: true,
    isDetailsPanelOpen: false
};

export function layoutReducer(state = initialState, action: LayoutActions): ILayoutStore {
    switch (action.type) {
        case LayoutActionsTypes.TOGGLE_MENU_PANEL:
            return toggleMenuPanel(state);
        case LayoutActionsTypes.TOGGLE_DETAILS_PANEL:
            return toggleDetailsPanel(state);
        case LayoutActionsTypes.SET_ZOOM_STATE:
            return { ...state, isZoomed: action.payload };
        case LayoutActionsTypes.SET_DETAILS_PANEL_STATE:
            return { ...state, isDetailsPanelOpen: action.payload };
        case LayoutActionsTypes.SET_MENU_PANEL_STATE:
            return { ...state, isMenuPanelOpen: action.payload };
        default:
            return state;
    }
}

const toggleMenuPanel = (state: ILayoutStore): ILayoutStore => {
    const closeDetailsPanel = isToggleViewport();

    return {
        ...state,
        isMenuPanelOpen: !state.isMenuPanelOpen,
        isDetailsPanelOpen: closeDetailsPanel ? false : state.isDetailsPanelOpen
    };
};

const toggleDetailsPanel = (state: ILayoutStore): ILayoutStore => {
    const closeMenuPanel = isToggleViewport();

    return {
        ...state,
        isDetailsPanelOpen: !state.isDetailsPanelOpen,
        isMenuPanelOpen: closeMenuPanel ? false : state.isMenuPanelOpen
    };
};
