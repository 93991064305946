import { Area, Floor } from "@smartbuilding/adt-v2-types";
import { UrlBuilder } from "@smartbuilding/web-request-api";
import { AdtConverters } from "@smartbuilding/utilities";
import { CustomProperties } from "../../commonUtils/CustomProperties";
import { QueryBuilder } from "@dip/querybuilder";
import { extractTypeFromSpace } from "../../commonUtils/CommonADTFunctions";
import { isString } from "lodash";
var SpaceHttpService = /** @class */ (function () {
    function SpaceHttpService(dtdlApiUrl, logger, httpService) {
        var _this = this;
        this.dtdlApiUrl = dtdlApiUrl;
        this.logger = logger;
        this.httpService = httpService;
        this.serviceTag = "[Space.Http.Service]";
        this.resourceBaseUrl = "v2.0/spaces";
        this.resourceBaseUrlv3 = "v3.0/api/query?api-version=2022-09-01-preview";
        this.urlBuilder = new UrlBuilder();
        // Any error is expected
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.logAndReject = function (error, url) {
            var _a;
            var errorMessage = isString(error) ? error : "error encountered during http request";
            var errorProperties = (_a = {},
                _a[CustomProperties.AppErrorMessage] = errorMessage,
                _a);
            if (url) {
                errorProperties[CustomProperties.RequestUrl] = url;
            }
            _this.logger.logError(new Error(_this.serviceTag + ": " + errorMessage), errorProperties);
            return Promise.reject(error);
        };
        this.requestBaseUrl = "" + this.dtdlApiUrl + this.resourceBaseUrl;
        this.requestBaseUrlv3 = "" + this.dtdlApiUrl + this.resourceBaseUrlv3;
        this.httpService = httpService;
    }
    SpaceHttpService.prototype.getSpaceByIdForPathfinding = function (spaceId) {
        var _this = this;
        var query = QueryBuilder.from(Area)
            .where(function (q) { return q.compare(Area, function (s) { return s.$dtId.equals(spaceId); }); })
            .join(Area, Floor, function (s) { return s.hasParent; })
            .addSelect(Floor);
        var postBody = {
            query: query.buildAdtQuery()
        };
        return this.httpService
            .post(this.requestBaseUrlv3, postBody)
            .then(function (res) {
            if (res.data.items.length === 0) {
                throw new Error("No space found with id " + spaceId);
            }
            return AdtConverters.formatIntoSmartSpace(res.data.items[0]);
        })
            .catch(function (error) { return _this.logAndReject(error); });
    };
    SpaceHttpService.prototype.getSpaceById = function (spaceId, includes, type) {
        var _this = this;
        if (!spaceId) {
            return this.logAndReject("you must specify a valid space id");
        }
        var params = {
            includes: includes,
            type: type
        };
        var requestUrl = this.urlBuilder.BuildUrl(this.requestBaseUrl + "/" + spaceId, params);
        return this.httpService
            .get(requestUrl)
            .then(function (res) {
            return _this.transformToSmartSpace(res.data);
        })
            .catch(function (error) { return _this.logAndReject(error, requestUrl); });
    };
    SpaceHttpService.prototype.transformToSmartSpace = function (space) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
        if (Object.keys(space).length) {
            var resultSmartSpace = {
                dtId: (_a = space.$dtId) !== null && _a !== void 0 ? _a : "",
                name: space.name,
                type: extractTypeFromSpace(space.$metadata.$model),
                featureId: space.featureId,
                buildingId: space.buildingId ? space.buildingId : space.$dtId,
                floorId: space.floorId,
                squareFootArea: space.squareFootArea,
                status: space.status,
                regionId: space.regionId,
                roomKey: space.roomKey,
                seatCount: space.seatCount,
                friendlyName: space.friendlyName,
                roomCapacity: space.roomCapacity,
                capabilities: space.capabilities,
                calendar: (_b = space.hasCalendar) === null || _b === void 0 ? void 0 : _b.targets[0],
                values: (_c = space.hasValues) === null || _c === void 0 ? void 0 : _c.targets,
                mapData: [(_d = space.hasMapData) === null || _d === void 0 ? void 0 : _d.targets[0]],
                children: space.hasChildren ? this.convertFloorToSmartSpace((_e = space.hasChildren) === null || _e === void 0 ? void 0 : _e.targets) : undefined,
                location: [(_f = space.hasLocation) === null || _f === void 0 ? void 0 : _f.targets[0]],
                people: (_g = space.hasPeople) === null || _g === void 0 ? void 0 : _g.targets,
                parent: space.hasParent
                    ? {
                        dtId: (_k = (_j = (_h = space.hasParent) === null || _h === void 0 ? void 0 : _h.targets[0]) === null || _j === void 0 ? void 0 : _j.$dtId) !== null && _k !== void 0 ? _k : "",
                        name: (_o = (_m = (_l = space.hasParent) === null || _l === void 0 ? void 0 : _l.targets[0]) === null || _m === void 0 ? void 0 : _m.name) !== null && _o !== void 0 ? _o : "",
                        type: extractTypeFromSpace((_q = (_p = space.hasParent) === null || _p === void 0 ? void 0 : _p.targets[0]) === null || _q === void 0 ? void 0 : _q.$metadata.$model)
                    }
                    : undefined
            };
            return resultSmartSpace;
        }
        return {};
    };
    SpaceHttpService.prototype.convertFloorToSmartSpace = function (floors) {
        var retrievedSpaces = [];
        for (var _i = 0, floors_1 = floors; _i < floors_1.length; _i++) {
            var floor = floors_1[_i];
            retrievedSpaces.push({
                dtId: floor.$dtId,
                logicalOrder: floor.logicalOrder,
                name: floor.name,
                roomKey: floor.roomKey,
                id: floor.$dtId,
                type: extractTypeFromSpace(floor.$metadata.$model)
            });
        }
        return retrievedSpaces;
    };
    SpaceHttpService.prototype.getSpaces = function (spaceQuery) {
        var _this = this;
        if (!spaceQuery) {
            return this.logAndReject("'spaceQuery' cannot be null or undefined");
        }
        var requestUrl = this.urlBuilder.BuildUrl(this.requestBaseUrl, spaceQuery);
        return this.httpService
            .get(requestUrl)
            .then(function (res) {
            var resultSmartSpaces = {
                items: res.data.items.map(function (item) { return _this.transformToSmartSpace(item); }),
                nextPageLink: res.data.nextPageLink,
                count: res.data.count
            };
            return resultSmartSpaces;
        })
            .catch(function (error) { return _this.logAndReject(error, requestUrl); });
    };
    return SpaceHttpService;
}());
export { SpaceHttpService };
