var PeopleHttpProvider = /** @class */ (function () {
    function PeopleHttpProvider(httpService, baseApiUrl) {
        this.httpService = httpService;
        this.regex = /^[A-Za-z]*$/;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.logAndReject = function (error, url) {
            var errorMessage = error ? error : "error encountered during http request";
            if (url) {
                errorMessage = "error encountered during http request from following url" + { url: url };
            }
            return Promise.reject(errorMessage);
        };
        this.endpointUrl = baseApiUrl + "/api/v1.0/people";
    }
    PeopleHttpProvider.prototype.getSuggestionsFromPrefix = function (key, maxLength, isElectron) {
        var _this = this;
        if (!key) {
            return this.logAndReject("Search key cannot be undefined or empty");
        }
        if (maxLength <= 0) {
            return this.logAndReject("Maximum length cannot be smaller than 1");
        }
        if (!this.regex.test(key)) {
            return this.logAndReject("Search key cannot contain special character or numbers");
        }
        return this.httpService
            .get(this.endpointUrl + "/" + key + "/" + maxLength + "/" + isElectron)
            .then(function (res) {
            var _a;
            var response = (_a = {}, _a[key] = res.data, _a);
            return response;
        })
            .catch(function (error) { return _this.logAndReject(error, _this.endpointUrl + "/" + key + "/" + maxLength + "/" + isElectron); });
    };
    return PeopleHttpProvider;
}());
export { PeopleHttpProvider };
